import React from 'react';
import SwitchButton from './SwitchButton.jsx';

const BadgeSwitchButton = ({
    children,
    badgeClassName,
    ...props
}) => {
    return (<span className={'badge py-0 ' + badgeClassName} style={{
        lineHeight: 2,
    }}>
        {children}
        <SwitchButton className="ms-2" {...props} />
    </span>);
};

export default BadgeSwitchButton;
