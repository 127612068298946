import React from 'react';
import {Field, Form, Formik} from 'formik';
import AutoPersistToken from '../elements/Formik/AutoPersistToken';
import PropTypes from 'prop-types';
import {Button, Col, FormControl, FormLabel, Row} from 'react-bootstrap';
import useFormPersistedValues from '../../hooks/useFormPersistedValues';

const ProfileSettingsWidget = ({
    formName,
    onSubmit,
}) => {
    const initialFormValues = useFormPersistedValues(formName);

    return (
        <Formik
            initialValues={initialFormValues || {
                firstName: '',
                middleName: '',
                lastName: '',
                avatar: '',
                password: '',
                passwordRepeat: '',
            }}
            onSubmit={onSubmit}
        >
            {({isSubmitting}) => (
                <Form>
                    <Row>
                        <Col md>
                            <Row className="mb-3">
                                <Col as={FormLabel} sm={2} htmlFor="firstName" className="col-form-label">Име</Col>
                                <Col sm={10}>
                                    <FormControl as={Field} type="text" name="firstName" id="firstName" required
                                                 disabled={isSubmitting}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col md>
                            <Row className="mb-3">
                                <Col as={FormLabel} sm={2} htmlFor="middleName" className="col-form-label">Презиме</Col>
                                <Col sm={10}>
                                    <FormControl as={Field} type="text" name="middleName" id="middleName" required
                                                 disabled={isSubmitting}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Row className="mb-3">
                                <Col as={FormLabel} sm={2} htmlFor="lastName" className="col-form-label">Фамилия</Col>
                                <Col sm={10}>
                                    <FormControl as={Field} type="text" name="lastName" id="lastName" required
                                                 disabled={isSubmitting}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col md>
                            <Row className="mb-3">
                                <Col as={FormLabel} sm={2} htmlFor="avatar" className="col-form-label">Аватар</Col>
                                <Col sm={10}>
                                    <FormControl as={Field} type="text" name="avatar" id="avatar" required
                                                 disabled={isSubmitting}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Row className="mb-3">
                                <Col as={FormLabel} sm={2} htmlFor="password" className="col-form-label">Парола</Col>
                                <Col sm={10}>
                                    <FormControl as={Field} type="text" name="password" id="password" required
                                                 disabled={isSubmitting}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col md>
                            <Row className="mb-3">
                                <Col as={FormLabel} sm={2} htmlFor="passwordRepeat" className="col-form-label">
                                    Повтори парола
                                </Col>
                                <Col sm={10}>
                                    <FormControl as={Field} type="text" name="passwordRepeat" id="passwordRepeat"
                                                 required disabled={isSubmitting}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-grid gap-2">
                            <Button disabled={isSubmitting}>Запази промените</Button>
                        </Col>
                    </Row>
                    <AutoPersistToken formName={formName} />
                </Form>
            )}
        </Formik>
    );
};

ProfileSettingsWidget.propTypes = {
    formName: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ProfileSettingsWidget;
