import {formatValuationCertificateNumber} from '../../../utils';
import PropTypes from 'prop-types';

const ValuationCertificateNumber = ({
    number,
}) => formatValuationCertificateNumber(number);

ValuationCertificateNumber.propTypes = {
    number: PropTypes.number.isRequired,
};

export default ValuationCertificateNumber;
