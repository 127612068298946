import React from 'react';
import {Image} from 'react-bootstrap';
import PropTypes from 'prop-types';

const UserAvatar = ({
    user,
    size,

    ...props
}) => {
    return (
        <Image roundedCircle src={user.avatar + '?d=mp&s=' + size} alt="User avatar" {...props} />
    );
};

UserAvatar.propTypes = {
    user: PropTypes.shape({
        avatar: PropTypes.string.isRequired,
    }).isRequired,
    size: PropTypes.number.isRequired,
};

export default UserAvatar;
