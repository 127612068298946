import {Table} from 'react-bootstrap';
import PropTypes from 'prop-types';
import GreenCardInfoButton from './NavigationButtons/GreenCardInfoButton.jsx';
import BsoStatusWidget from "./BsoStatusWidget.jsx";

const GreenCardsListWidget = ({
    greenCards,
    actionButton,
}) => {
    return (<div className="table-responsive">
        <Table striped className="mb-0">
            <thead>
                <tr>
                    {actionButton && <th width={72} />}
                    <th>Номер</th>
                    <th>ЗК</th>
                    <th>Статус</th>
                    <th>Консултант</th>
                </tr>
            </thead>
            <tbody>
                {greenCards.map(greenCard => <tr key={greenCard.id}>
                    {actionButton && <td>{actionButton(greenCard)}</td>}
                    <td><GreenCardInfoButton greenCard={greenCard} /></td>
                    <td>{greenCard.insuranceCompany?.shortName}</td>
                    <td><BsoStatusWidget {...greenCard} /></td>
                    <td>{greenCard.user?.shortName}</td>
                </tr>)}
            </tbody>
        </Table>
    </div>);
};

GreenCardsListWidget.propTypes = {
    greenCards: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        series: PropTypes.string.isRequired,
        number: PropTypes.number.isRequired,
        user: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }),
        insuranceBroker: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }),
        insuranceCompany: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }),
    }).isRequired),
    actionButton: PropTypes.func,
};

export default GreenCardsListWidget;
