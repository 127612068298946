import React from 'react';
import {FormControl} from 'react-bootstrap';
import {Field, useFormikContext} from 'formik';
import PropTypes from 'prop-types';
import {formatWeekCode} from '../../../utils';

const WeekCodeSelector = ({
    name,
    required,
    max,
}) => {
    const { isSubmitting } = useFormikContext();

    return (<FormControl as={Field} type="week" name={name} id={name} max={max} required={required}
        disabled={isSubmitting} />);
};

WeekCodeSelector.defaultProps = {
    name: 'weekCode',
    required: false,
    max: formatWeekCode(new Date()),
};

WeekCodeSelector.propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    max: PropTypes.string,
};

export default WeekCodeSelector;
