import {formatStickerNumber} from '../../../utils';
import PropTypes from 'prop-types';

const StickerNumber = ({
    number,
}) => formatStickerNumber(number);

StickerNumber.propTypes = {
    number: PropTypes.number.isRequired,
};

export default StickerNumber;
