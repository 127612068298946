import React from 'react';
import {Row} from 'react-bootstrap';
import ReportBox from '../elements/ReportBox';
import RequireNonProdEnv from '../elements/AccessControl/RequireNonProdEnv';
import RequireRole from '../elements/AccessControl/RequireRole';
import Content from '../partials/Content.jsx';

const Reports = () => {
    return (<Content title="Изготвяне на справки и отчети">
        <Row className="row-cols-1 row-cols-sm-2 row-cols-xl-4">
            <ReportBox variant="info" type="Справка" description="Издадени полици и сметки за период" icon="tasks"
                       url="/reports/sales" />
            <RequireRole name="ROLE_SHOW_COMMISSION">
                <ReportBox variant="success" type="Отчет" description="Застрахователни компании" icon="clipboard-list"
                           url="/reports/insurance-company" />
            </RequireRole>
            <RequireNonProdEnv>
                <ReportBox variant="danger" type="Отчет" description="Комисион към консултантите" icon="hand-holding-usd"
                           url="/reports/agent/commission" />
            </RequireNonProdEnv>
        </Row>
    </Content>);
};

export default Reports;
