import React from 'react';
import {Button, Col, Container, FormControl, FormGroup, FormLabel, Row} from 'react-bootstrap';
import {Field, Form, Formik} from 'formik';
import useFormPersistedValues from '../../../hooks/useFormPersistedValues';
import AutoPersistToken from '../../elements/Formik/AutoPersistToken';
import InsuranceCompanySelector from '../../elements/Form/InsuranceCompanySelector';
import BsoSaveWidget from './BsoSaveWidget';
import {useCreateStickersMutation} from '../../../features/apiSlice';
import ErrorWidget from '../ErrorWidget';
import Alert from '../../elements/ReactBootstrap/Alert';
import Step from "../../elements/BsStepper/Step.jsx";

const StickerCreateWidget = () => {
    const [create, {
        data: stickers,
        error,
        isError,
    }] = useCreateStickersMutation();

    const formName = 'stickerCreateForm';
    const formInitialValues = useFormPersistedValues(formName);
    const currentYear = (new Date).getFullYear();

    return (<Formik
        initialValues={formInitialValues || {
            insuranceCompany: '',
            startNumber: '',
            endNumber: '',
            lastAvailableYear: '',
        }}
        onSubmit={values => {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm('Потвърдете регистрацията на стикери')) {
                return Promise.reject(new Error('Регистрацията беше отказана'));
            }

            return create({
                ...values,
                lastAvailableYear: parseInt(values.lastAvailableYear, 10),
            });
        }}
    >
        {({isSubmitting}) => (<Container fluid as={Form} className="gx-0">
            <Row className="row-cols-1 g-3">
                <Col>
                    <Step number="2" label="Въведете информация"/>
                </Col>
                <Col lg={6}>
                    <InsuranceCompanySelector required={true} mtplOnly={true} />
                </Col>
                <div className="w-100 gy-0" />
                <Col lg={6}>
                    <FormLabel htmlFor="startNumber">Първи стикер</FormLabel>
                    <FormControl as={Field} type="text" name="startNumber" autoComplete="off"
                                 id="startNumber" required pattern="\d{8,9}"
                                 disabled={isSubmitting} maxLength={9} inputMode="decimal"/>
                </Col>
                <div className="w-100 gy-0" />
                <Col lg={6}>
                    <FormLabel htmlFor="endNumber">Последен стикер</FormLabel>
                    <FormControl as={Field} type="text" name="endNumber" autoComplete="off"
                                 id="endNumber" required pattern="\d{8,9}"
                                 disabled={isSubmitting} maxLength={9} inputMode="decimal"/>
                </Col>
                <div className="w-100 gy-0" />
                <Col lg={6}>
                    <FormLabel>Последна налична година за перфорация</FormLabel>
                    <FormGroup>
                        {[...Array(4).keys()].map(index => {
                            const year = currentYear + index;
                            const htmlFor = 'btn-check-' + index;

                            return (<React.Fragment key={index}>
                                <Field type="radio" name="lastAvailableYear" value={year.toString(10)} required
                                    disabled={isSubmitting} className="btn-check" id={htmlFor} />
                                <Button as={FormLabel} variant="outline-success" htmlFor={htmlFor} className="me-2">
                                    {year}
                                </Button>
                            </React.Fragment>);
                        })}
                    </FormGroup>
                </Col>
                <BsoSaveWidget type="стикери" />
                <div className="w-100 gy-0" />
                {stickers && <Col lg={6}>
                    <Alert variant="success" heading={<>Успешно записахте {stickers.length} стикера!</>} />
                </Col>}
                {isError && <Col lg={6}>
                    <ErrorWidget error={error} />
                </Col>}
            </Row>
            <AutoPersistToken formName={formName}/>
        </Container>)}
    </Formik>);
};

export default StickerCreateWidget;
