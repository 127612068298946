import React, {useState} from 'react';
import Card from '../elements/ReactBootstrap/Card';
import {Button, Col, Row} from 'react-bootstrap';
import {
    useDeleteBsoProtocolGreenCardsMutation,
    useDeleteBsoProtocolStickersMutation,
    useDeleteBsoProtocolUabCardsMutation,
    useGetBsoProtocolQuery
} from '../../features/apiSlice';
import {useParams} from 'react-router-dom';
import ErrorWidget from '../widgets/ErrorWidget';
import Content from '../partials/Content.jsx';
import StickersListWidget from '../widgets/StickersListWidget.jsx';
import GreenCardsListWidget from '../widgets/GreenCardsListWidget.jsx';
import BsoProtocolInfoWidget from '../widgets/Bso/Protocol/BsoProtocolInfoWidget.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';
import UabCardsListWidget from '../widgets/UabCardsListWidget.jsx';
import BsoAddWidget from '../widgets/Bso/Protocol/BsoAddWidget';

const BsoProtocol = () => {
    const [disabled, setDisabled] = useState(false);

    const {bsoProtocolId} = useParams();

    const {
        data: bsoProtocol,
        error,
        isLoading,
        isFetching,
        isSuccess,
        isError,
    } = useGetBsoProtocolQuery(bsoProtocolId);

    const [deleteStickers, {
        isLoading: deleteStickersIsLoading,
        isError: deleteStickersIsError,
        error: deleteStickersError,
    }] = useDeleteBsoProtocolStickersMutation();

    const [deleteGreenCards, {
        isLoading: deleteGreenCardsIsLoading,
        isError: deleteGreenCardsIsError,
        error: deleteGreenCardsError,
    }] = useDeleteBsoProtocolGreenCardsMutation();

    const [deleteUabCards, {
        isLoading: deleteUabCardsIsLoading,
        isError: deleteUabCardsIsError,
        error: deleteUabCardsError,
    }] = useDeleteBsoProtocolUabCardsMutation();

    const disableActions = isFetching || disabled || deleteStickersIsLoading || deleteGreenCardsIsLoading ||
        deleteUabCardsIsLoading;

    // const injectRowType = (list, type) => list.map(item => ({ ...item, rowType: type }));
    //
    // const bsoProtocolItems = useMemo(() => isSuccess ? [
    //     ...injectRowType(bsoProtocol.stickers, 'sticker'),
    //     ...injectRowType(bsoProtocol.greenCards, 'greenCard'),
    //     ...injectRowType(bsoProtocol.uabCards, 'uabCard'),
    // ] : [], [
    //     isSuccess,
    //     bsoProtocol,
    // ]);

    return (<Content title="Приемо-предавателен протокол за бланки под строга отчетност">
        <Row className="row-cols-1 flex-column flex-grow-1">
            <Col>
                {isLoading && <Card>Зареждане на протокола... <SmallSpinner /></Card>}
                {isError && <ErrorWidget error={error} />}
                {isSuccess && <BsoProtocolInfoWidget bsoProtocol={bsoProtocol} disabled={disableActions}
                    setDisabled={setDisabled} />}
            </Col>
            {isSuccess && <>
                {!bsoProtocol.completed && <Col>
                    <BsoAddWidget bsoProtocol={bsoProtocol} disabled={disableActions} setDisabled={setDisabled} />
                </Col>}
                {/*<Col className="d-flex flex-grow-1">*/}
                {/*    <Card noPadding baseClassName="w-100">*/}
                {/*        <BsoProtocolItemsGridWidget rowData={bsoProtocolItems} />*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                <Col>
                    <Card noPadding header={<>
                        Стикери за Гражданска отговорност ({bsoProtocol.stickers.length})
                        {isFetching && <SmallSpinner className="ms-2" />}
                    </>}>
                        <StickersListWidget stickers={bsoProtocol.stickers} actionButton={bsoProtocol.completed ? null :
                            sticker => <Button variant="danger" onClick={() => deleteStickers([
                                bsoProtocol.id,
                                [sticker.id],
                            ])} disabled={disableActions}>
                                <i className="fa-solid fa-trash-can" />
                            </Button>} />
                    </Card>
                    {deleteStickersIsError && <ErrorWidget error={deleteStickersError} />}
                </Col>
                <Col>
                    <Card noPadding header={<>
                        Сертификат "Зелена карта" ({bsoProtocol.greenCards.length})
                        {isFetching && <SmallSpinner className="ms-2" />}
                    </>}>
                        <GreenCardsListWidget greenCards={bsoProtocol.greenCards} actionButton={bsoProtocol.completed ?
                            null : greenCard => <Button variant="danger" onClick={() => deleteGreenCards([
                                bsoProtocol.id, [greenCard.id],
                            ])} disabled={disableActions}>
                                <i className="fa-solid fa-trash-can" />
                            </Button>} />
                    </Card>
                    {deleteGreenCardsIsError && <ErrorWidget error={deleteGreenCardsError} />}
                </Col>
                <Col>
                    <Card noPadding header={<>
                        Карти СБА ({bsoProtocol.uabCards.length})
                        {isFetching && <SmallSpinner className="ms-2" />}
                    </>}>
                        <UabCardsListWidget uabCards={bsoProtocol.uabCards} actionButton={bsoProtocol.completed ? null :
                            uabCard => <Button variant="danger" onClick={() => deleteUabCards([
                                bsoProtocol.id,
                                [uabCard.id],
                            ])} disabled={disableActions}>
                                <i className="fa-solid fa-trash-can" />
                            </Button>
                        } />
                    </Card>
                    {deleteUabCardsIsError && <ErrorWidget error={deleteUabCardsError} />}
                </Col>
            </>}
        </Row>
    </Content>);
};

export default BsoProtocol;
