import {Button, Form, InputGroup} from "react-bootstrap";
import React from "react";
import {Field, Form as FormikForm, Formik} from "formik";
import {useNavigate} from "react-router-dom";

const SearchFormWidget = () => {
    const navigate = useNavigate();

    return (<div className="sidebar-search">
        <Formik initialValues={{
            query: '',
        }} onSubmit={({
            query,
        }) => {
            navigate('/search/?query=' + encodeURIComponent(query.trim()))
        }}>
            <Form as={FormikForm} className="form-inline mb-2">
                <InputGroup>
                    <Form.Control as={Field} type="search" name="query" className="form-control-sidebar"
                        required placeholder="Търсене..." aria-label="Търсене" />
                    <Button variant="sidebar" type="submit">
                        <i className="fas fa-search fa-fw" />
                    </Button>
                </InputGroup>
            </Form>
        </Formik>
    </div>);
};

export default SearchFormWidget;
