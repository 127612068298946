import React from 'react';
import {Button, Col, Container, FormLabel, Row} from 'react-bootstrap';
import {Form, Formik} from 'formik';
import {useCreateBsoProtocolMutation} from '../../../../features/apiSlice.js';
import useFormPersistedValues from '../../../../hooks/useFormPersistedValues.js';
import AutoPersistToken from '../../../elements/Formik/AutoPersistToken.jsx';
import ErrorWidget from '../../ErrorWidget.jsx';
import OfficeSelector from '../../../elements/Form/OfficeSelector.jsx';
import {useNavigate} from 'react-router-dom';

const BsoProtocolCreateWidget = () => {
    const navigate = useNavigate();

    const [create, {
        error,
        isError,
    }] = useCreateBsoProtocolMutation();

    const formName = 'bsoProtocolCreateForm';
    const formInitialValues = useFormPersistedValues(formName);

    return (<Formik
        initialValues={formInitialValues || {
            sendOffice: '',
            receiveOffice: '',
        }}
        onSubmit={async values => {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm('Потвърдете създаването на протокола')) {
                return Promise.reject(new Error('Създаването беше отказано'));
            }

            const bsoProtocol = await create({
                ...values,
            }).unwrap();

            if (bsoProtocol) {
                navigate('/bso-protocol/' + bsoProtocol.id);
            }
        }}
    >
        {({isSubmitting}) => (<Container fluid as={Form} className="gx-0">
            <Row className="row-cols-1 g-3">
                <Col lg={6}>
                    <FormLabel>Предал</FormLabel>
                    <div className="w-100 gy-0" />
                    <OfficeSelector name="sendOffice" required />
                </Col>
                <Col lg={6}>
                    <FormLabel>Приел</FormLabel>
                    <div className="w-100 gy-0" />
                    <OfficeSelector name="receiveOffice" required />
                </Col>
                <div className="w-100 gy-0" />
                <Col lg={6}>
                    <Button variant="success" type="submit" disabled={isSubmitting} className="w-100">
                        <i className="fa-solid fa-file-circle-plus"/> Създай протокола
                    </Button>
                </Col>
                <div className="w-100 gy-0" />
                {isError && <Col>
                    <ErrorWidget error={error} />
                </Col>}
            </Row>
            <AutoPersistToken formName={formName}/>
        </Container>)}
    </Formik>);
};

export default BsoProtocolCreateWidget;
