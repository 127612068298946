import React from 'react';
import {useGetCurrentUserQuery} from '../../../features/apiSlice';
import ErrorWidget from '../ErrorWidget';
import InsuranceBrokerInfoWidget from '../InsuranceBrokerInfoWidget';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';

const BrokerSettingsWidget = () => {
    const {
        data: user,
        error: userError,
        isSuccess: userSuccess,
        isLoading: userLoading,
        isError: userIsError,
    } = useGetCurrentUserQuery();

    return (<>
        {userLoading && <>Зареждане на информация за брокера... <SmallSpinner /></>}
        {userIsError && <ErrorWidget error={userError} />}
        {userSuccess && <InsuranceBrokerInfoWidget broker={user.insuranceBroker} />}
    </>);
};

export default BrokerSettingsWidget;
