import {Col, Row} from "react-bootstrap";
import React from "react";
import Card from "../../elements/ReactBootstrap/Card.jsx";
import PropTypes from "prop-types";
import StickersListWidget from "../StickersListWidget.jsx";

const StickerSearchResult = ({
    results,
}) => {
    const length = results.length;

    if (length < 1) {
        return null;
    }

    return (<Row>
        <Col>
            <Card noPadding header={'Стикери (' + length + ')'}>
                <StickersListWidget stickers={results} />
            </Card>
        </Col>
    </Row>);
};

StickerSearchResult.propTypes = {
    results: PropTypes.array,
};

export default StickerSearchResult;
