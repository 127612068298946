import PropTypes from 'prop-types';

const PremiumAmount = ({
    amount,
    withCurrency,
}) => (new Intl.NumberFormat('bg-BG', withCurrency ? {
    currency: 'BGN',
    style: 'currency',
} : {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
})).format(amount);

PremiumAmount.defaultProps = {
    withCurrency: true,
};

PremiumAmount.propTypes = {
    amount: PropTypes.number.isRequired,
    withCurrency: PropTypes.bool,
};

export default PremiumAmount;
