import React from 'react';
import {Button} from 'react-bootstrap';
import {useFormikContext} from 'formik';

const SubmitButton = ({
    children,
}) => {
    const { isSubmitting } = useFormikContext();

    return (
        <Button variant="success" type="submit" className="w-100" disabled={isSubmitting}>
            {children}
        </Button>
    );
};

export default SubmitButton;
