import React from 'react';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Nav} from 'react-bootstrap';

const NavItem = ({
    to,
    menuIcon,
    children,
}) => {
    return (
        <Nav.Item as="li">
            <Nav.Link as={NavLink} to={to}>
                {menuIcon && <i className={'nav-icon fa-fw ' + menuIcon}/>}
                {children}
            </Nav.Link>
        </Nav.Item>
    );
};

NavItem.propTypes = {
    to: PropTypes.string.isRequired,
    menuIcon: PropTypes.string,
};

export default NavItem;
