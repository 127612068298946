import PremiumAmount from './PremiumAmount';
import PropTypes from 'prop-types';
import PaymentsCount from "./PaymentsCount";

const SalesReportValue = ({
    totalAmount,
    paymentsCount,
}) => {
    if (paymentsCount === 0) {
        return 'няма';
    }

    return (<>
        <PremiumAmount amount={totalAmount} /> - <PaymentsCount count={paymentsCount} />
    </>);
};

SalesReportValue.propTypes = {
    totalAmount: PropTypes.number.isRequired,
    paymentsCount: PropTypes.number.isRequired,
};

export default SalesReportValue;
