import React from 'react';
import {Col} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';

const ReportBox = ({
    variant,
    type,
    description,
    icon,
    url,
}) => {
    return (
        <Col className="d-flex">
            <div className={'d-flex flex-column w-100 small-box bg-' + variant}>
                <div className="inner flex-grow-1">
                    <h3>{type}</h3>
                    <p>{description}</p>
                </div>
                <div className="icon">
                    <i className={'fas fa-' + icon}/>
                </div>
                <NavLink to={url} className="small-box-footer">
                    Изготвяне <i className="fas fa-arrow-circle-right"/>
                </NavLink>
            </div>
        </Col>
    );
};

ReportBox.propTypes = {
    variant: PropTypes.string.isRequired,
    type: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
    icon: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default ReportBox;
