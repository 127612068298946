import React from 'react';
import {Col, ListGroup, Row} from 'react-bootstrap';
import MtplPremiumDescription from './MtplPremiumDescription';
import MtplPremium from './MtplPremium';
import {installmentCounts} from '../../../config';

const InsuranceCompanyMtplOffers = ({
    insuranceCompany,
}) => {
    return (<ListGroup.Item key={insuranceCompany.id}>
        <Row>
            <Col className="overflow-hidden">
                <span className="name">{insuranceCompany.shortName}</span>
            </Col>
            {installmentCounts.map(installmentsCount =>
                <Col key={installmentsCount} className={'installments text-end installments-' + installmentsCount}>
                    <MtplPremium insuranceCompany={insuranceCompany} installmentsCount={installmentsCount} />
                </Col>)
            }
        </Row>
        <Row>
            <Col md={{ span: 3, offset: 6 }} lg={{ span: 12, offset: 0 }} xl={{ span: 3, offset: 6 }}
                 className="installments text-end installments-2">
                <MtplPremiumDescription insuranceCompany={insuranceCompany} installmentsCount={2} />
            </Col>
            <Col md={3} lg={12} xl={3} className="installments text-end installments-4">
                <MtplPremiumDescription insuranceCompany={insuranceCompany} installmentsCount={4} />
            </Col>
        </Row>
    </ListGroup.Item>);
};

export default InsuranceCompanyMtplOffers;
