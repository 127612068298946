import React from 'react';
import BSB from 'bootstrap-switch-button-react';

const BootstrapSwitchButton = BSB.default || BSB;

const SwitchButton = ({
    invertColors,

    ...props
}) => {
    if (invertColors) {
        props.styleOn = SwitchButton.defaultProps.styleOff;
        props.styleOff = SwitchButton.defaultProps.styleOn;
    }

    return (<BootstrapSwitchButton {...props} />);
};

SwitchButton.defaultProps = {
    invertColors: false,
    size: 'xs',
    styleOn: 'success',
    styleOff: 'danger',
    labelOn: 'Да',
    labelOff: 'Не',
};

export default SwitchButton;
