import {
    addMonths,
    differenceInCalendarDays,
    format,
    getMonth,
    parseISO,
    startOfISOWeek,
    startOfMonth,
    startOfYear,
    subDays,
    subMonths,
    subWeeks,
    subYears
} from 'date-fns';
import {store} from './app/store';
import {anonymousApiSlice, authenticatedApiSlice} from './features/apiSlice';
import {clearForms} from './features/formSlice';
import {clearTokens} from './authStorage.js';

export const sum = arr => arr.reduce((prev, curr) => prev + curr, 0);
export const zeroPad = (str, length) => str.toString().padStart(length, '0');
export const formatStickerNumber = number => zeroPad(number.toString(10), 8);
export const formatGreenCardNumber = number => zeroPad(number.toString(10), 6);
export const formatValuationCertificateNumber = number => zeroPad(number.toString(10), 6);
export const formatUabCardNumber = number => zeroPad(number.toString(10), 6);
export const formatInsuranceCompanyNumber = number => zeroPad(number.toString(10), 2);
export const formatWeekCode = date => format(date, "yyyy-'W'II");
//export const allowValuationCertificates = insuranceCompany => insuranceCompany.number === 30;
export const allowValuationCertificates = () => false;

export const matchPolicyNumbers = policyNumbers => arrayUnique(policyNumbers.match(/[\dA-Z/-]{11,23}/gi) || []);
export const uabCardTypes = {
    SILVER: {
        name: 'Сребърна',
        totalAmount: 45,
    },
    SILVER_PLUS: {
        name: 'Сребърна плюс',
        totalAmount: 70,
    },
    GOLD: {
        name: 'Златна',
        totalAmount: 125,
    },
};
export const paymentMethods = {
    CASH: 'В брой',
    POS_TERMINAL: 'ПОС терминал',
    BANK_ACCOUNT_BROKER: 'Банкова сметка брокер',
    BANK_ACCOUNT_INSURANCE_COMPANY: 'Банкова сметка застраховател',
};
export const paymentMethodsShort = {
    CASH: 'Брой',
    POS_TERMINAL: 'ПОС',
    BANK_ACCOUNT_BROKER: 'СКС',
    BANK_ACCOUNT_INSURANCE_COMPANY: 'ЗК',
};

// export const objectForEach = (obj, fn) => Object.entries(obj).forEach(
//     ([k, v], i) => fn(v, k, i)
// );
// export const objectMap = (obj, fn) => Object.fromEntries(Object.entries(obj).map(
//     ([k, v], i) => [k, fn(v, k, i)]
// ));
export const copyText = async text => {
    if (!navigator.clipboard) {
        alert('Вашият браузър не поддържа копиране на текст чрез бутон');
        return;
    }

    try {
        await navigator.clipboard.writeText(text);
    }
    catch (e) {
        alert('Грешка при копиране на текст: ' + e.message);
    }
};

export const clone = value => {
    try {
        // noinspection JSUnresolvedFunction
        return structuredClone(value);
    }
    catch {
        return JSON.parse(JSON.stringify(value));
    }
};

export const logout = () => {
    clearTokens();
    store.dispatch(clearForms());
    store.dispatch(anonymousApiSlice.util.resetApiState());
    store.dispatch(authenticatedApiSlice.util.resetApiState());
};

export const flattenUsers = insuranceBroker => [].concat(...insuranceBroker.offices.filter(
    office => office.active
).map(
    office => office.users.filter(
        user => user.active
    ).map(
        user => ({
            value: user.id,
            label: user.fullName + ' [' + user.pin + '] (офис ' + office.name + ')',
        })
    )
)).sort((a, b) => a.label.localeCompare(b.label));

export const upcChecksum = num => {
    const digits = num.toString(10).split('').map(char => parseInt(char));
    const odd = digits.filter((_, idx) => idx % 2 === 0);
    const even = digits.filter((_, idx) => idx % 2 === 1);
    const checkSum = sum(odd) * 3 + sum(even);
    const checkDigit = checkSum % 10;
    return checkDigit > 0 ? 10 - checkDigit : 0;
};

export const getErrorMessage = error => {
    if (!error) {
        return null;
    }

    const errorData = error.data;

    if (errorData) {
        // RFC 7807 error
        if (errorData.type && errorData.title) {
            return (
                errorData.title === 'An error occurred' ? '' : (errorData.title + ':')
            ) + (
                errorData.detail || ''
            ) + (
                errorData.class ? (' (' + errorData.class + ')') : ''
            );
        }
    }

    // RTK Query error
    if (error.error && error.status) {
        return error.error + ' (' + error.status + ')';
    }

    // TODO add more error conditions
    return null;
};

export const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

export const filterObject = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate));

export const formatDayDiff = date => {
    if (!date) {
        return null;
    }

    const diffDays = differenceInCalendarDays(parseISO(date), new Date());

    if (diffDays === 0) {
        return null;
    }

    const numDays = Math.abs(diffDays);
    const prefix = diffDays < 0 ? 'преди' : 'след';
    const suffix = numDays === 1 ? 'ден' : 'дни';

    return prefix + ' ' + numDays + ' ' + suffix;
};

export const arrayUnique = arr => [...new Set(arr)];

export const getPeriods = () => {
    const currentDate = new Date();
    const currentMonday = startOfISOWeek(currentDate);
    const currentStartOfMonth = startOfMonth(currentDate);
    const currentStartOfYear = startOfYear(currentDate);
    const currentMonth = getMonth(currentDate);
    const currentStartOfHalfYear = currentMonth < 7 ? currentStartOfYear : addMonths(currentStartOfYear, 6);

    const currentWeekPeriod = {
        label: 'текуща седмица',
        value: JSON.stringify({
            startDateTime: currentMonday,
            endDateTime: null,
        })
    };
    const lastWeekPeriod = {
        label: 'предишна седмица',
        value: JSON.stringify({
            startDateTime: subWeeks(currentMonday, 1),
            endDateTime: subDays(currentMonday, 1),
        })
    };
    const currentMonthPeriod = {
        label: 'текущ месец',
        value: JSON.stringify({
            startDateTime: currentStartOfMonth,
            endDateTime: null,
        })
    };
    const lastMonthPeriod = {
        label: 'предишен месец',
        value: JSON.stringify({
            startDateTime: subMonths(currentStartOfMonth, 1),
            endDateTime: subDays(currentStartOfMonth, 1),
        })
    };
    const currentHalfYearPeriod = {
        label: 'текущо полугодие',
        value: JSON.stringify({
            startDateTime: currentStartOfHalfYear,
            endDateTime: null,
        })
    };
    const lastHalfYearPeriod = {
        label: 'предишно полугодие',
        value: JSON.stringify({
            startDateTime: subMonths(currentStartOfHalfYear, 6),
            endDateTime: subDays(currentStartOfHalfYear, 1),
        })
    };
    const currentYearPeriod = {
        label: 'текуща година',
        value: JSON.stringify({
            startDateTime: currentStartOfYear,
            endDateTime: null,
        })
    };
    const lastYearPeriod = {
        label: 'предишна година',
        value: JSON.stringify({
            startDateTime: subYears(currentStartOfYear, 1),
            endDateTime: subDays(currentStartOfYear, 1),
        })
    };

    return {
        currentWeekPeriod,
        lastWeekPeriod,
        currentMonthPeriod,
        lastMonthPeriod,
        currentHalfYearPeriod,
        lastHalfYearPeriod,
        currentYearPeriod,
        lastYearPeriod,
    };
};

export const bsoTypes = [{
    id: 'stickers',
    title: 'Стикер ГО',
    iconClassName: 'fas fa-sticky-note',
}, {
    id: 'greenCards',
    title: 'Сертификат Зелена карта',
    iconClassName: 'fas fa-money-check',
}, {
    id: 'uabCards',
    title: 'Карта СБА',
    iconClassName: 'fas fa-address-card',
}];

export const agSort = (event, state) => {
    event.columnApi.applyColumnState({
        state,
        defaultState: {
            // important to say 'null' as undefined means 'do nothing'
            sort: null,
        },
    });
};

export const agMultiFilter = {
    filter: 'agMultiColumnFilter',
    filterParams: {
        filters: [
            {
                filter: 'agTextColumnFilter',
            },
            {
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset'],
                },
            },
        ],
    },
};

export const agParseDate = (date, resetTime) => {
    if (!date) {
        return null;
    }

    const dt = new Date(date);

    if (resetTime) {
        dt.setHours(0);
        dt.setMinutes(0);
        dt.setSeconds(0);
        dt.setMilliseconds(0);
    }

    return dt;
};

export const getBsoProtocolStatus = bsoProtocol => !!bsoProtocol.receivedAt ? 'Приет' : (
    !!bsoProtocol.sentAt ? 'Предаден' : (
        bsoProtocol.completed ? 'Финализиран' : 'Чернова'
    )
);

export const toTitleCase = str => str.substring(0, 1).toUpperCase() + str.substring(1);

export const kwToHp = kw => kw * 1000 / 735.49875;

export const getInstallmentsCountLabel = count => count.toString() + ' вноск' + (count === 1 ? 'а' : 'и');
