import {useReportSalesSummaryQuery} from '../../../../features/apiSlice';
import SalesReportValue from '../../../elements/Values/SalesReportValue';
import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {format} from 'date-fns';
import ErrorIcon from '../../../elements/Icons/ErrorIcon';
import SmallSpinner from '../../../elements/Spinner/SmallSpinner.jsx';

const DailySalesSummaryWidget = ({
    date,
    pollingInterval,
}) => {
    const dateFormatted = useMemo(() => format(date, "yyyy-MM-dd'T'"), [date]);

    const {
        data,
        error,
        isLoading,
        isSuccess,
        isError,
    } = useReportSalesSummaryQuery({
        startDateTime: dateFormatted + '00:00:00',
        endDateTime: dateFormatted + '23:59:59',
    }, {
        pollingInterval,
    });

    return (<>
        {isLoading && <SmallSpinner />}
        {isSuccess && <SalesReportValue {...data} />}
        {isError && <ErrorIcon error={error} />}
    </>);
};

DailySalesSummaryWidget.defaultProps = {
    pollingInterval: 0,
};

DailySalesSummaryWidget.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    pollingInterval: PropTypes.number,
};

export default DailySalesSummaryWidget;
