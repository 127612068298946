import React from 'react';

const LoadingView = () => {
    return (
        <div className="login-page">
            Зареждане на приложението, моля изчакайте...
        </div>
    );
};

export default LoadingView;
