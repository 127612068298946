import React from 'react';
import PropTypes from 'prop-types';
import CopyButton from './CopyButton.jsx';

const TextWithCopyButton = ({
    text,
    small,
}) => {
    if (!text || !text?.length) {
        return null
    }

    return (<span className="text-nowrap">
        {text}
        <CopyButton text={text} small={small} />
    </span>);
};

TextWithCopyButton.defaultProps = {
    small: false,
};

TextWithCopyButton.propTypes = {
    text: PropTypes.string,
    small: PropTypes.bool,
};

export default TextWithCopyButton;
