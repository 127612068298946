import React from 'react';
import PropTypes from 'prop-types';
import NavigationButton from './NavigationButton';

const VehicleInfoButton = ({
    vehicle,
    textField,
}) => {
    if (!vehicle) {
        return null;
    }

    const text = textField && !!vehicle[textField] ? vehicle[textField] : vehicle.vin;

    return (<NavigationButton link={'/vehicle/' + vehicle.id} copyText={text}>
        {text}
    </NavigationButton>);
};

VehicleInfoButton.propTypes = {
    vehicle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        vin: PropTypes.string.isRequired,
    }),
    textField: PropTypes.string,
};

export default VehicleInfoButton;
