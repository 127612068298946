import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../elements/ReactBootstrap/Alert';
import Card from '../elements/ReactBootstrap/Card';
import {Col, Row} from 'react-bootstrap';
import MtplOfferButton from './OfferButtons/MtplOfferButton';
import CascoOfferButton from './OfferButtons/CascoOfferButton';

const NoPendingInstallmentsWidget = ({
    offerVehicle,
    offerClient,
}) => {
    return (<>
        <Alert variant="secondary" heading="Всички вноски по тази полица вече са платени." />
        {offerVehicle && <Card outline header="Вижте най-добрите оферти за този автомобил">
            <Row className="row-cols-1 row-cols-sm-2 g-3">
                <Col>
                    <MtplOfferButton client={offerClient} vehicle={offerVehicle} />
                </Col>
                <Col className="d-none">
                    <CascoOfferButton client={offerClient} vehicle={offerVehicle} />
                </Col>
            </Row>
        </Card>}
    </>);
};

NoPendingInstallmentsWidget.propTypes = {
    offerVehicle: PropTypes.shape({
        plateNumber: PropTypes.string.isRequired,
        registrationCertificateNumber: PropTypes.string,
    }),
    offerClient: PropTypes.shape({
        pin: PropTypes.string.isRequired,
    }),
};

export default NoPendingInstallmentsWidget;
