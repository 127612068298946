import {Col, Container, Row} from 'react-bootstrap';
import React from 'react';
import Step from '../../elements/BsStepper/Step';
import ButtonLink from '../../elements/ButtonLink';
import {Outlet} from 'react-router-dom';

const BsoCreateWidget = () => {
    return (
        <Container fluid className="gx-0">
            <Row className="row-cols-1 g-3">
                <Col>
                    <Step number="1" label="Изберете вид бланки"/>
                </Col>
                <Col>
                    <ButtonLink to="sticker" icon="fas fa-sticky-note">Стикер ГО</ButtonLink>
                    <ButtonLink to="green-card" icon="fas fa-money-check text-success">СЗК</ButtonLink>
                    {/*<ButtonLink to="valuation-certificate" icon="fas fa-file-invoice-dollar">УЗС</ButtonLink>*/}
                    <ButtonLink to="uab-card" icon="fas fa-address-card">Карта СБА</ButtonLink>
                </Col>
                <Col>
                    <Outlet />
                </Col>
            </Row>
        </Container>
    );
};

export default BsoCreateWidget;
