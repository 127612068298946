import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import CopyButton from './CopyButton';
import ValuationCertificateNumber from '../elements/Values/ValuationCertificateNumber';
import {formatValuationCertificateNumber} from '../../utils.js';
import BsoStatusWidget from './BsoStatusWidget.jsx';

const ValuationCertificateInfoWidget = ({
    valuationCertificate,
}) => {
    return (<>
        <Row as="dl" className="mb-0">
            <Col sm={4} as="dt">Номер</Col>
            <Col sm={8} as="dd">
                <ValuationCertificateNumber {...valuationCertificate} />
                <CopyButton text={formatValuationCertificateNumber(valuationCertificate.number)} />
            </Col>
            <Col sm={4} as="dt">Статус</Col>
            <Col sm={8} as="dd"><BsoStatusWidget type="valuationCertificate"
                updateRole="ROLE_UPDATE_VALUATION_CERTIFICATE" {...valuationCertificate} /></Col>
            {valuationCertificate.insuranceCompany && <>
                <Col sm={4} as="dt">Застрахователна компания</Col>
                <Col sm={8} as="dd">{valuationCertificate.insuranceCompany.shortName}</Col>
            </>}
            {valuationCertificate.insuranceBroker && <>
                <Col sm={4} as="dt">Брокер</Col>
                <Col sm={8} as="dd">{valuationCertificate.insuranceBroker.shortName}</Col>
            </>}
            {valuationCertificate.office && <>
                <Col sm={4} as="dt">Офис</Col>
                <Col sm={8} as="dd">{valuationCertificate.office.name}</Col>
            </>}
            {valuationCertificate.user && <>
                <Col sm={4} as="dt">Отговорно лице</Col>
                <Col sm={8} as="dd">{valuationCertificate.user.shortName}</Col>
            </>}
        </Row>
    </>);
};

ValuationCertificateInfoWidget.propTypes = {
    valuationCertificate: PropTypes.shape({
        number: PropTypes.number.isRequired,
    }).isRequired,
};

export default ValuationCertificateInfoWidget;
