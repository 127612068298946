import BsoProtocolGridWidget from './BsoProtocolGridWidget';
import {useListBsoProtocolsQuery} from '../../../../features/apiSlice.js';
import ErrorWidget from '../../ErrorWidget.jsx';
import SmallSpinner from '../../../elements/Spinner/SmallSpinner.jsx';

const BsoProtocolsListWidget = () => {
    const {
        data: bsoProtocols,
        isSuccess,
        isLoading,
        isError,
        error,
    } = useListBsoProtocolsQuery();

    return (<>
        {isLoading && <div className="p-3"><SmallSpinner /></div>}
        {isSuccess && <BsoProtocolGridWidget rowData={bsoProtocols} />}
        {isError && <ErrorWidget error={error} />}
    </>);
};

export default BsoProtocolsListWidget;
