import {Table} from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import VehicleInfoButton from './NavigationButtons/VehicleInfoButton.jsx';
import TextWithCopyButton from './TextWithCopyButton.jsx';

const VehiclesListWidget = ({
    vehicles,
}) => {
    return (<div className="table-responsive">
        <Table striped className="mb-0">
            <thead>
                <tr>
                    <th>VIN</th>
                    <th>ДКН</th>
                    <th>Марка/модел</th>
                </tr>
            </thead>
            <tbody>
                {vehicles.map(vehicle => <tr key={vehicle.id}>
                    <td>
                        <VehicleInfoButton vehicle={vehicle} />
                    </td>
                    <td>
                        <VehicleInfoButton vehicle={vehicle} textField="plateNumber" />
                    </td>
                    <td>
                        <TextWithCopyButton text={vehicle.brandName} />{' '}
                        <TextWithCopyButton text={vehicle.modelName} />
                    </td>
                </tr>)}
            </tbody>
        </Table>
    </div>);
};

VehiclesListWidget.propTypes = {
    vehicles: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        vin: PropTypes.string.isRequired,
        plateNumber: PropTypes.string,
        registrationCertificateNumber: PropTypes.string,
        brandName: PropTypes.string,
        modelName: PropTypes.string,
    }).isRequired),
};

export default VehiclesListWidget;
