import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Card from '../elements/ReactBootstrap/Card';
import {useGetCurrentInsuranceBrokerQuery} from '../../features/apiSlice';
import ErrorWidget from '../widgets/ErrorWidget';
import Content from '../partials/Content.jsx';
import UsersGridWidget from '../widgets/UsersGridWidget.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';
import PropTypes from 'prop-types';

const Users = ({
    treeData,
}) => {
    const {
        data: insuranceBroker,
        error,
        isLoading,
        isSuccess,
        isError,
    } = useGetCurrentInsuranceBrokerQuery();

    const rowData = isSuccess ? insuranceBroker.offices.map(({ users, ...office }) => users.map(user => ({
        ...user,
        office,
    }))).flat() : [];

    return (<Content title="Управление на потребители">
        <Row className="flex-grow-1">
            <Col>
                {isError && <ErrorWidget error={error} />}
                {isLoading && <Card><SmallSpinner /></Card>}
                {isSuccess && <UsersGridWidget rowData={rowData} showExtraControls={true} treeData={treeData} />}
            </Col>
        </Row>
    </Content>);
};

UsersGridWidget.defaultProps = {
    treeData: false,
};

UsersGridWidget.propTypes = {
    treeData: PropTypes.bool,
};

export default Users;
