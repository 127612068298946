import React, {useMemo} from 'react';
import Card from '../elements/ReactBootstrap/Card';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {Field, Form, Formik} from 'formik';
import IconSelectField from '../elements/ReactSelect/IconSelectField';
import {createFilter} from 'react-select';
import {formatUabCardNumber} from '../../utils';
import {useGetUabCardInventoryQuery, useSellUabCardMutation} from '../../features/apiSlice';
import UabCardNumber from '../elements/Values/UabCardNumber';
import Alert from '../elements/ReactBootstrap/Alert';
import ErrorWidget from '../widgets/ErrorWidget';
import RequireRole from '../elements/AccessControl/RequireRole';
import PremiumAmount from '../elements/Values/PremiumAmount';
import IconField from '../elements/Formik/IconField';
import Content from '../partials/Content.jsx';

const UabCardIssue = () => {
    const {
        data: uabCards,
        isSuccess: uabCardsIsSuccess,
    } = useGetUabCardInventoryQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const [sell, {
        isSuccess: saleIsSuccess,
        isError: saleIsError,
        error: saleError,
    }] = useSellUabCardMutation();

    const uabCardsList = useMemo(() => uabCardsIsSuccess ? uabCards.map(uabCard => ({
        value: uabCard.id,
        label: <UabCardNumber {...uabCard} />,
    })) : [], [uabCardsIsSuccess, uabCards]);

    return (<Content title="Издаване на карта СБА">
        <Row className="row-cols row-cols-lg-2">
            <Col>
                <Card>
                    <Formik initialValues={{
                        uabCard: '',
                        pin: '',
                    }} onSubmit={async values => {
                        const card = uabCards.filter(card => card.id === values.uabCard).shift();
                        const msg = 'Продажба на карта СБА ' + card.number + ' на стойност ' +
                            PremiumAmount({
                                amount: card.totalAmount,
                                withCurrency: PremiumAmount.defaultProps.withCurrency, // manually calling function
                            }) + '?';

                        // TODO change with react-bootstrap modal
                        if (!window.confirm(msg)) {
                            throw new Error('Плащането беше отказано');
                        }

                        return sell(values);
                    }}>
                        {({isSubmitting, values}) => {
                            const selectedUabCard = uabCardsIsSuccess ? uabCards.filter(uabCard =>
                                uabCard.id === values.uabCard
                            ).shift() || null : null;

                            return (<Container as={Form} className="gx-0">
                                <Row className="row-cols-1 align-items-end g-3">
                                    <Col>
                                        <Field name="uabCard" component={IconSelectField} options={uabCardsList}
                                            label="Номер на картата" icon="address-card" iconWidth={43}
                                            disabled={isSubmitting} placeholder="Изберете карта СБА"
                                            isClearable={true} noOptionsMessage={() => 'Нямате налични карти СБА!'}
                                            filterOption={createFilter({
                                                stringify: option => {
                                                    const number = option.label.props.number;
                                                    return number + '\0' + formatUabCardNumber(number);
                                                },
                                            })} />
                                    </Col>
                                    {selectedUabCard && !saleIsSuccess && <RequireRole name="ROLE_PAY">
                                        <Col lg={6}>
                                            <IconField type="text" name="pin" label="Парола" prependIcon="key" required
                                                pattern="\d{10}" inputMode="decimal" className="password"
                                                disabled={isSubmitting} maxLength={10} autoComplete="off" />
                                        </Col>
                                        <Col lg={6}>
                                            <Button variant="success" type="submit" disabled={isSubmitting}
                                                    className="w-100">
                                                <i className="fas fa-hand-holding-usd"/> Плащане
                                                на <PremiumAmount amount={selectedUabCard.totalAmount} />
                                            </Button>
                                        </Col>
                                    </RequireRole>}
                                </Row>
                            </Container>);
                        }}
                    </Formik>
                </Card>
                {saleIsSuccess && <Alert variant="success" heading={<>Успешно записахте продажбата!</>} />}
                {saleIsError && <ErrorWidget error={saleError} />}
            </Col>
        </Row>
    </Content>);
};

export default UabCardIssue;
