import {Col, Row} from 'react-bootstrap';
import React from 'react';
import Card from '../../elements/ReactBootstrap/Card.jsx';
import PropTypes from 'prop-types';
import VehiclesListWidget from '../VehiclesListWidget.jsx';

const VehicleSearchResult = ({
    results,
}) => {
    const length = results.length;

    if (length < 1) {
        return null;
    }

    return (<Row>
        <Col>
            <Card noPadding header={'МПС (' + length + ')'}>
                <VehiclesListWidget vehicles={results} />
            </Card>
        </Col>
    </Row>);
};

VehicleSearchResult.propTypes = {
    results: PropTypes.array,
};

export default VehicleSearchResult;
