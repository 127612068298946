import React from 'react';
import Content from '../../partials/Content.jsx';

const AgentCommission = () => {
    return (<Content title="Комисион към консултантите">
        <div>Очаквайте скоро!</div>
    </Content>);
};

export default AgentCommission;
