import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import {ErrorMessage} from 'formik';
import FieldError from './FieldError';

const SelectField = ({
    field,
    form,

    disabled,
    options,

    innerRef,

    ...props
}) => {
    return <>
        <Select
            {...props}
            name={field.name}
            isDisabled={disabled}
            options={options}
            value={options ? options.find(option => option.value === field.value) : ''}
            onChange={option => form.setFieldValue(field.name, option ? option.value : null)}
            onBlur={field.onBlur}
            ref={innerRef}
        />
        <ErrorMessage name={field.name} component={FieldError} />
    </>;
};

SelectField.defaultProps = {
    innerRef: null,
};

SelectField.propTypes = {
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.node.isRequired,
    })).isRequired,
};

export default SelectField;
