import {Col, Container, Row} from "react-bootstrap";
import {Outlet} from "react-router-dom";
import React from "react";

const BsoProtocolWidget = () => {
    return (
        <Container fluid className="gx-0">
            <Row className="row-cols-1 g-3">
                <Col>
                    <Outlet />
                </Col>
            </Row>
        </Container>
    );
};

export default BsoProtocolWidget;
