import React from 'react';
import {Field, Form, Formik} from 'formik';
import AutoPersistToken from '../elements/Formik/AutoPersistToken';
import PropTypes from 'prop-types';

// TODO: react-bootstrap

const ClientSearchWidget = ({
    onSubmit,
    formName,
}) => {
    return (
        <Formik
            initialValues={{
                searchString: '',
            }}
            onSubmit={onSubmit}
        >
            {({isSubmitting}) => (
                <div className="container-fluid">
                    <Form>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Търсене:</label>
                                    <div className="input-group input-group-lg">
                                        <Field type="search" className="form-control form-control-lg"
                                               name="searchString" id="searchString"
                                               placeholder="Type your keywords here"/>
                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-lg btn-default">
                                                <i className="fa fa-search"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3">
                                <div className="form-group">
                                    <label>Ред на сортиране:</label>
                                    <div className="input-group input-group-lg">
                                        <Field as="select" name="sortOrder" id="sortOrder"
                                               className="form-control form-control-lg"
                                               required disabled={isSubmitting}>
                                            <option>Възходящ</option>
                                            <option>Низходящ</option>
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3">
                                <div className="form-group">
                                    <label>Сортиране по:</label>
                                    <div className="input-group input-group-lg">
                                        <Field as="select" name="sortBy" id="sortBy"
                                               className="form-control form-control-lg"
                                               required disabled={isSubmitting}>
                                            <option>Име</option>
                                            <option>Дата</option>
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                    <AutoPersistToken formName={formName} />
                </div>
            )}
        </Formik>
    );
};

ClientSearchWidget.propTypes = {
    formName: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ClientSearchWidget;
