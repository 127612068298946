import React from 'react';
import PropTypes from 'prop-types';
import OfferButton from './OfferButton';

const CascoOfferButton = ({
    client,
    vehicle,
}) => {
    return (<OfferButton client={client} vehicle={vehicle} link="/casco/new/find" formName="cascoOfferForm" icon="car-crash" product="Каско" />);
};

CascoOfferButton.propTypes = {
    client: PropTypes.shape({
        personalNumber: PropTypes.string,
    }),
    vehicle: PropTypes.shape({
        plateNumber: PropTypes.string,
        registrationCertificateNumber: PropTypes.string,
    }),
};

export default CascoOfferButton;
