import {Table} from 'react-bootstrap';
import PropTypes from 'prop-types';
import StickerInfoButton from './NavigationButtons/StickerInfoButton.jsx';
import BsoStatusWidget from "./BsoStatusWidget.jsx";

const StickersListWidget = ({
    stickers,
    actionButton,
}) => {
    return (<div className="table-responsive">
        <Table striped className="mb-0">
            <thead>
                <tr>
                    {actionButton && <th width={72} />}
                    <th>Номер</th>
                    <th>ЗК</th>
                    <th>Статус</th>
                    <th>Консултант</th>
                </tr>
            </thead>
            <tbody>
                {stickers.map(sticker => <tr key={sticker.id}>
                    {actionButton && <td>{actionButton(sticker)}</td>}
                    <td><StickerInfoButton sticker={sticker} /></td>
                    <td>{sticker.insuranceCompany?.shortName}</td>
                    <td><BsoStatusWidget {...sticker} /></td>
                    <td>{sticker.user?.shortName}</td>
                </tr>)}
            </tbody>
        </Table>
    </div>);
};

StickersListWidget.propTypes = {
    stickers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.number.isRequired,
        user: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }),
        insuranceBroker: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }),
        insuranceCompany: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }),
    }).isRequired),
    actionButton: PropTypes.func,
};

export default StickersListWidget;
