import React, {useMemo} from 'react';
import {createFilter} from 'react-select';
import {useGetCurrentInsuranceBrokerQuery} from '../../../features/apiSlice';
import PropTypes from 'prop-types';
import SelectField from '../ReactSelect/SelectField';
import {Field, useFormikContext} from 'formik';
import ErrorIcon from '../Icons/ErrorIcon';
import SmallSpinner from '../Spinner/SmallSpinner.jsx';

const OfficeSelector = ({
    defaultValueFilter,

    ...props
}) => {
    const {
        data: insuranceBroker,
        error: insuranceBrokerError,
        isLoading: insuranceBrokerIsLoading,
        isSuccess: insuranceBrokerIsSuccess,
        isError: insuranceBrokerIsError,
    } = useGetCurrentInsuranceBrokerQuery();

    const offices = useMemo(
        () => insuranceBrokerIsSuccess ? insuranceBroker.offices.filter(
            office => office.active
        ).map(office => ({
            value: office.id,
            label: 'Oфис ' + office.name,
        })).sort((a, b) => a.label.localeCompare(b.label)) : [],
        [insuranceBrokerIsSuccess, insuranceBroker]
    );

    const { isSubmitting } = useFormikContext();

    return (<>
        {insuranceBrokerIsLoading && <SmallSpinner />}
        {insuranceBrokerIsSuccess && <Field component={SelectField} options={offices}
            disabled={isSubmitting} placeholder={props.required ? 'Изберете офис' : 'Всички офиси'}
            defaultValue={defaultValueFilter ? defaultValueFilter(offices) : []}
            filterOption={createFilter({
                stringify: option => option.label,
            })} {...props} />}
        {insuranceBrokerIsError && <ErrorIcon error={insuranceBrokerError} />}
    </>);
};

OfficeSelector.defaultProps = {
    name: 'office',
    required: false,
    isClearable: false,
};

OfficeSelector.propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    isClearable: PropTypes.bool,
    defaultValueFilter: PropTypes.func,
};

export default OfficeSelector;
