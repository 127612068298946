import {useEffect} from 'react';
import {useFormikContext} from 'formik';

const AutoSubmitToken = () => {
    const {submitForm} = useFormikContext();

    useEffect(() => {
        (async () => {
            await submitForm();
        })();
    }, [submitForm]);

    return null;
};

export default AutoSubmitToken;
