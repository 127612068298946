import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Row} from 'react-bootstrap';
import PremiumAmount from '../elements/Values/PremiumAmount';
import DateTimeLong from '../elements/DateTime/DateTimeLong';
import PolicyPrintButton from '../elements/PrintButtons/PolicyPrintButton';
import {Link} from 'react-router-dom';
import MtplOfferButton from './OfferButtons/MtplOfferButton';
import ClientInfoButton from './NavigationButtons/ClientInfoButton';
import VehicleInfoButton from './NavigationButtons/VehicleInfoButton';
import PolicyInfoButton from './NavigationButtons/PolicyInfoButton';
import Card from '../elements/ReactBootstrap/Card';
import {useCreatePolicyFromInsuranceCompanyByPolicyNumberMutation} from '../../features/apiSlice';
import ErrorWidget from './ErrorWidget';
import Alert from '../elements/ReactBootstrap/Alert';
import Percent from '../elements/Values/Percent';
import RequireRole from '../elements/AccessControl/RequireRole';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';
import TextWithCopyButton from './TextWithCopyButton.jsx';

const PolicyInfoWidget = ({
    policy,
    header,
    noInfoButton,
    detailed,
    printing,
    setPrinting,
}) => {
    const [create, {
        isSuccess: createSuccess,
        error: createError,
        isError: createIsError,
        isLoading: createLoading,
    }] = useCreatePolicyFromInsuranceCompanyByPolicyNumberMutation();

    const filterInsuredClients = useCallback((insuredClients, isPrevious) => insuredClients.filter(insuredClient => insuredClient.isPrevious === isPrevious).map(insuredClient => insuredClient.insuredClient), []);

    const hasUnpaidInstallment = useMemo(() => policy.installments.filter(installment => !installment.paidPremiumFromClient).length > 0, [policy.installments]);
    const previousInsuredClients = useMemo(() => filterInsuredClients(policy.insuredClients, true), [filterInsuredClients, policy.insuredClients]);
    const currentInsuredClients = useMemo(() => filterInsuredClients(policy.insuredClients, false), [filterInsuredClients, policy.insuredClients]);

    const hasTax = policy.product.insuranceType !== 'life';
    const hasMtplCover = policy.product.hasMtplCover;
    const hasGf = hasMtplCover || policy.product.hasPassengersAccidentCover;

    return (<>
        <Card header={header}>
            <Row as="dl">
                <Col sm={4} as="dt">Номер</Col>
                <Col sm={8} as="dd">{noInfoButton ?
                    <TextWithCopyButton text={policy.number} /> :
                    <PolicyInfoButton policy={policy} />
                }</Col>
                <Col sm={4} as="dt">Продукт</Col>
                <Col sm={8} as="dd">{policy.productName}</Col>
                <Col sm={4} as="dt">Брой вноски</Col>
                <Col sm={8} as="dd">{policy.installmentsCount}</Col>
                {policy.insuranceCompany?.shortName && <>
                    <Col sm={4} as="dt">Застрахователна компания</Col>
                    <Col sm={8} as="dd">{policy.insuranceCompany.shortName}</Col>
                </>}
                {detailed && <>
                    {policy.issueInsuranceBroker?.shortName && <>
                        <Col sm={4} as="dt">Издадена от брокер</Col>
                        <Col sm={8} as="dd">{policy.issueInsuranceBroker?.shortName}</Col>
                    </>}
                    {policy.issueOffice?.name && <>
                        <Col sm={4} as="dt">Офис</Col>
                        <Col sm={8} as="dd">{policy.issueOffice?.name}</Col>
                    </>}
                    {policy.issueUser?.shortName && <>
                        <Col sm={4} as="dt">Консултант</Col>
                        <Col sm={8} as="dd">{policy.issueUser?.shortName}</Col>
                    </>}
                </>}
                {policy.policyHolder && <>
                    <Col sm={4} as="dt">Застраховащо лице</Col>
                    <Col sm={8} as="dd"><ClientInfoButton client={policy.policyHolder} textField="name" /></Col>
                    {policy.policyHolder?.name && <Col sm={{ span: 8, offset: 4 }} as="dd">
                        <ClientInfoButton client={policy.policyHolder} />
                    </Col>}
                </>}
                {currentInsuredClients.map(insuredClient =>
                    <React.Fragment key={insuredClient.id}>
                        <Col sm={4} as="dt">Застраховано лице</Col>
                        <Col sm={8} as="dd"><ClientInfoButton client={insuredClient} textField="name" /></Col>
                        {insuredClient.name && <Col sm={{ span: 8, offset: 4 }} as="dd">
                            <ClientInfoButton client={insuredClient} />
                        </Col>}
                    </React.Fragment>
                )}
                {previousInsuredClients.map(previousInsuredClient =>
                    <React.Fragment key={previousInsuredClient.id}>
                        <Col sm={4} as="dt">Предишно застраховано лице</Col>
                        <Col sm={8} as="dd"><ClientInfoButton client={previousInsuredClient} textField="name" /></Col>
                        {previousInsuredClient.name && <Col sm={{ span: 8, offset: 4 }} as="dd">
                            <ClientInfoButton client={previousInsuredClient} />
                        </Col>}
                    </React.Fragment>
                )}
                {policy.insuredVehicle && <>
                    <Col sm={4} as="dt">МПС</Col>
                    <Col sm={8} as="dd"><VehicleInfoButton vehicle={policy.insuredVehicle} textField="plateNumber" /></Col>
                    {policy.insuredVehicle.modelName && <Col sm={{ span: 8, offset: 4 }} as="dd">
                        <VehicleInfoButton vehicle={policy.insuredVehicle} textField="modelName" />
                    </Col>}
                </>}
                {policy.issueDate && <>
                    <Col sm={4} as="dt">Дата на сключване</Col>
                    <Col sm={8} as="dd">
                        <DateTimeLong date={policy.issueDate} />
                    </Col>
                </>}
                {policy.beginDate && <>
                    <Col sm={4} as="dt">Начална дата</Col>
                    <Col sm={8} as="dd">
                        <DateTimeLong date={policy.beginDate} />
                    </Col>
                </>}
                {policy.endDate && <>
                    <Col sm={4} as="dt">Крайна дата</Col>
                    <Col sm={8} as="dd">
                        <DateTimeLong date={policy.endDate} />
                    </Col>
                </>}
                {detailed && <>
                    <Col sm={4} as="dt">Базова премия</Col>
                    <Col sm={8} as="dd">
                        <PremiumAmount amount={policy.premiumAmount} />
                    </Col>
                    {hasTax && <>
                        <Col sm={4} as="dt">Данък по чл. 9 от ЗДЗП (2%)</Col>
                        <Col sm={8} as="dd">
                            <PremiumAmount amount={policy.taxAmount} />
                        </Col>
                    </>}
                    {hasMtplCover && <>
                        <Col sm={4} as="dt">Такса стикер</Col>
                        <Col sm={8} as="dd">
                            <PremiumAmount amount={policy.stickerAmount} />
                        </Col>
                    </>}
                    {hasGf && <>
                        <Col sm={4} as="dt">Такса ГФ и ОФ</Col>
                        <Col sm={8} as="dd">
                            <PremiumAmount amount={policy.guaranteeFundAmount} />
                        </Col>
                    </>}
                </>}
                <Col sm={4} as="dt">Обща дължима премия</Col>
                <Col sm={8} as="dd">
                    <PremiumAmount amount={policy.totalAmount} />
                </Col>
                {detailed && <RequireRole name="ROLE_SHOW_COMMISSION">
                    {policy.policyCommissionCoefficient !== undefined && <>
                        <Col sm={4} as="dt">Преференциален комисион (полица)</Col>
                        <Col sm={8} as="dd">
                            <Percent coefficient={policy.policyCommissionCoefficient} />
                        </Col>
                    </>}
                    {policy.voucherCommissionCoefficient !== undefined && <>
                        <Col sm={4} as="dt">Преференциален комисион (квитанция)</Col>
                        <Col sm={8} as="dd">
                            <Percent coefficient={policy.voucherCommissionCoefficient} />
                        </Col>
                    </>}
                </RequireRole>}
            </Row>
            <Row className="g-3">
                {detailed && <>
                    <Col sm={6} xl={3}><PolicyPrintButton policy={policy} printing={false} disabled={printing}
                                                   setDisabled={setPrinting} /></Col>
                    {(hasUnpaidInstallment || policy.insuredVehicle) && <Col sm={6} xl={3}>
                        {hasUnpaidInstallment ?
                            <Link to={'/installment-payment/policy/' + policy.id}
                              className="btn btn-outline-secondary w-100">
                                <i className="fas fa-dollar-sign" /> Плащане на вноска
                            </Link> : (policy.insuredVehicle &&
                                <MtplOfferButton
                                    client={policy.policyHolder || policy.insuredClients?.[0]?.insuredClient}
                                    vehicle={policy.insuredVehicle} />)
                        }
                    </Col>}
                </>}
                <Col sm={detailed ? 6 : 12} xl={detailed ? 3 : 6}>
                    <Button variant="outline-secondary" className="w-100" onClick={() => create({
                        policyNumber: policy.number,
                    })}>
                        <i className="fas fa-retweet" /> Обновяване от ЗК
                    </Button>
                </Col>
            </Row>
        </Card>
        {(createLoading || createSuccess || createIsError) && <Row>
            <Col>
                {createLoading && <Card>
                    <SmallSpinner />
                </Card>}
                {createSuccess && <Alert variant="success" heading="Успешно обновяване от ЗК" />}
                {createIsError && <ErrorWidget error={createError} />}
            </Col>
        </Row>}
    </>);
};

PolicyInfoWidget.defaultProps = {
    noInfoButton: false,
    detailed: false,
};

PolicyInfoWidget.propTypes = {
    policy: PropTypes.shape({
        productName: PropTypes.string,
        insuranceCompany: PropTypes.shape({
            shortName: PropTypes.string.isRequired,
        }),
        installmentsCount: PropTypes.number,
        insuredVehicle: PropTypes.shape({
            plateNumber: PropTypes.string,
            modelName: PropTypes.string,
        }),
        issueDate: PropTypes.string,
        beginDate: PropTypes.string,
        endDate: PropTypes.string,
        totalAmount: PropTypes.number,
    }).isRequired,
    header: PropTypes.node,
    noInfoButton: PropTypes.bool,
    detailed: PropTypes.bool,
};

export default PolicyInfoWidget;
