import React, {useMemo} from 'react';
import {Col} from 'react-bootstrap';
import {Field} from 'formik';
import IconSelectField from '../elements/ReactSelect/IconSelectField';
import {useGetGreenCardInventoryQuery, useGetStickerInventoryQuery} from '../../features/apiSlice';
import PropTypes from 'prop-types';
import GreenCardNumber from '../elements/Values/GreenCardNumber';
import StickerNumber from '../elements/Values/StickerNumber';
import {createFilter} from 'react-select';
import {formatGreenCardNumber, formatStickerNumber} from '../../utils';

const InstallmentPaymentBsoWidget = ({
    insuranceCompanyId,
    disabled,
    stickerRef,
    greenCardRef,
}) => {
    const {
        data: stickers,
        isSuccess: stickersIsSuccess,
    } = useGetStickerInventoryQuery(insuranceCompanyId, {
        refetchOnMountOrArgChange: true,
    });

    const {
        data: greenCards,
        isSuccess: greenCardsIsSuccess,
    } = useGetGreenCardInventoryQuery(insuranceCompanyId, {
        refetchOnMountOrArgChange: true,
    });

    const stickersList = useMemo(() => stickersIsSuccess ? stickers.map(sticker => ({
        value: sticker.id,
        label: <StickerNumber {...sticker} />,
    })) : [], [stickersIsSuccess, stickers]);

    const greenCardsList = useMemo(() => greenCardsIsSuccess ? greenCards.map(greenCard => ({
        value: greenCard.id,
        label: <GreenCardNumber {...greenCard} />,
    })) : [], [greenCardsIsSuccess, greenCards]);

    return (<>
        <Col>
            <Field name="sticker" component={IconSelectField} options={stickersList}
                   label="Стикер" icon="sticky-note" iconWidth={39} disabled={disabled}
                   placeholder="Изберете стикер" isClearable={true}
                   noOptionsMessage={() => 'Нямате налични стикери!'} filterOption={createFilter({
                stringify: option => {
                    const number = option.label.props.number;
                    return number + '\0' + formatStickerNumber(number);
                },
            })} innerRef={stickerRef} />
        </Col>
        <Col>
            <Field name="greenCard" component={IconSelectField} options={greenCardsList}
                   label="Зелена карта" icon="money-check text-success" iconWidth={45}
                   disabled={disabled} placeholder="Изберете СЗК" isClearable={true}
                   noOptionsMessage={() => 'Нямате налични СЗК!'} filterOption={createFilter({
                stringify: option => {
                    const props = option.label.props;
                    const series = props.series;
                    const number = props.number;
                    const numberFormatted = formatGreenCardNumber(number);
                    return series + ' ' + numberFormatted + '\0' + series + numberFormatted;
                },
            })} innerRef={greenCardRef} />
        </Col>
    </>);
};

InstallmentPaymentBsoWidget.defaultProps = {
    stickerRef: null,
    greenCardRef: null,
};

InstallmentPaymentBsoWidget.propTypes = {
    insuranceCompanyId: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default InstallmentPaymentBsoWidget;
