import React from 'react';
import {Button} from 'react-bootstrap';

const DevWarning = () => {
    return (<Button variant="warning" className="border-secondary w-100 mx-2" disabled>
        <span className="d-none d-sm-inline">Работите на </span>ДЕМО версия
        <span className="d-none d-xl-inline">, плащанията по полици и сметки са недействителни</span>!
    </Button>);
};

export default DevWarning;
