import React from 'react';
import {Button} from 'react-bootstrap';

const InfoIcon = ({
    children,

    ...props
}) => {
    return (
        <Button variant="link" className="p-0" {...props}>
            <i className="fa fa-info-circle text-secondary" /> {children}
        </Button>
    );
};

export default InfoIcon;
