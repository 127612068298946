/* eslint-disable no-template-curly-in-string */
const AG_GRID_LOCALE_BG = {
    // Set Filter
    selectAll: '(Избери всичко)',
    selectAllSearchResults: '(Избери всички търсени резултати)',
    searchOoo: 'Търсене...',
    blanks: '(Празни)',
    noMatches: 'Няма съвпадения',

    // Number Filter & Text Filter
    filterOoo: 'Филтър...',
    equals: 'Се равнява',
    notEqual: 'Не се равнява',
    blank: 'Е празнен',
    notBlank: 'Не е празен',
    empty: 'Изберете един',

    // Number Filter
    lessThan: 'По-малко от',
    greaterThan: 'По-голямо от',
    lessThanOrEqual: 'По-малко или равно',
    greaterThanOrEqual: 'По-голямо или равно',
    inRange: 'В диапазон',
    inRangeStart: 'от',
    inRangeEnd: 'до',

    // Text Filter
    contains: 'Съдържа',
    notContains: 'Не съдържа',
    startsWith: 'Започва с',
    endsWith: 'Завършва с',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

    // Filter Conditions
    andCondition: 'И',
    orCondition: 'ИЛИ',

    // Filter Buttons
    applyFilter: 'Прилагане',
    resetFilter: 'Нулиране',
    clearFilter: 'Изчистване',
    cancelFilter: 'Отмяна',

    // Filter Titles
    textFilter: 'Тектов филтър',
    numberFilter: 'Цифров филтър',
    dateFilter: 'Филтър за дата',
    setFilter: 'Задаване на филтър',

    // Side Bar
    columns: 'Колони',
    filters: 'Филтри',

    // columns tool panel
    pivotMode: 'Pivot Mode',
    groups: 'Row Groups',
    rowGroupColumnsEmptyMessage: 'Плъзнете тук, за да зададете групи редове',
    values: 'Values',
    valueColumnsEmptyMessage: 'Плъзнете тук, за да съберете',
    pivots: 'Column Labels',
    pivotColumnsEmptyMessage: 'Плъзнете тук, за да зададете етикети на колони',

    // Header of the Default Group Column
    group: 'Група',

    // Row Drag
    rowDragRows:'редове',

    // Other
    loadingOoo: 'Зареждане...',
    noRowsToShow: 'Няма редове за показване',
    enabled: 'Enabled',

    // Menu
    pinColumn: 'Закачане колона',
    pinLeft: 'Закачане ляво',
    pinRight: 'Закачане дясно',
    noPin: 'Без закачане',
    valueAggregation: 'Value Aggregation',
    autosizeThiscolumn: 'Автоматично оразмеряване на тази колона',
    autosizeAllColumns: 'Автоматично оразмеряване на всички колони',
    groupBy: 'Групиране по',
    ungroupBy: 'Дегрупиране по',
    addToValues: 'Добавяне на ${variable} към скойностите',
    removeFromValues: 'Премахване на ${variable} от скойностите',
    addToLabels: 'Добавяне на ${variable} към етикетите',
    removeFromLabels: 'Премахване на ${variable} от етикетите',
    resetColumns: 'Нулиране на колоните',
    expandAll: 'Разгръщане на всички',
    collapseAll: 'Затваряне на всички',
    copy: 'Копиране',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Копиране със заглавия',
    copyWithGroupHeaders: 'Копиране със заглавия на групи',
    paste: 'Поставяне',
    ctrlV: 'Ctrl+V',
    export: 'Експортиране',
    csvExport: 'CSV Експорт',
    excelExport: 'Excel Експорт',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Sum',
    min: 'Min',
    max: 'Max',
    none: 'None',
    count: 'Count',
    avg: 'Average',
    filteredRows: 'Филтрирани',
    selectedRows: 'Избрани',
    totalRows: 'Общо редове',
    totalAndFilteredRows: 'Редове',
    more: 'Още',
    to: 'от',
    of: 'of',
    page: 'Страница',
    nextPage: 'Следваща страница',
    lastPage: 'Последна страница',
    firstPage: 'Първа страница',
    previousPage: 'Предишна страница',

    // Pivoting
    pivotColumnGroupTotals: 'Общо',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
    pivotChart: 'Pivot Chart',
    chartRange: 'Chart Range',

    columnChart: 'Колона',
    groupedColumn: 'Групирани',
    stackedColumn: 'Stacked',
    normalizedColumn: '100% Stacked',

    barChart: 'Bar',
    groupedBar: 'Групирани',
    stackedBar: 'Stacked',
    normalizedBar: '100% Stacked',

    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Doughnut',

    line: 'Линия',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bubble',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',

    histogramChart: 'Histogram',

    combinationChart: 'Combination',
    columnLineCombo: 'Column & Line',
    AreaColumnCombo: 'Area & Column',

    // Charts
    pivotChartTitle: 'Pivot Chart',
    rangeChartTitle: 'Range Chart',
    settings: 'Настройки',
    data: 'Данни',
    format: 'Формат',
    categories: 'Категории',
    defaultCategory: '(без)',
    series: 'Series',
    xyValues: 'X Y Values',
    paired: 'Paired Mode',
    axis: 'Axis',
    navigator: 'Navigator',
    color: 'Color',
    thickness: 'Дебелина',
    xType: 'X Type',
    automatic: 'Автоматично',
    category: 'Категория',
    number: 'Номер',
    time: 'Час',
    autoRotate: 'Автоматично завъртане',
    xRotation: 'X Rotation',
    yRotation: 'Y Rotation',
    ticks: 'Ticks',
    width: 'Ширина',
    height: 'Височина',
    length: 'Дължина',
    padding: 'Padding',
    spacing: 'Spacing',
    chart: 'Chart',
    title: 'Заглавие',
    titlePlaceholder: 'Chart title - double click to edit',
    background: 'Фон',
    font: 'Шрифт',
    top: 'Отгоре',
    right: 'Отдясно',
    bottom: 'Отдолу',
    left: 'Отляво',
    labels: 'Етикети',
    size: 'Размер',
    minSize: 'Минимален размер',
    maxSize: 'Максимален размер',
    legend: 'Легенда',
    position: 'Позиция',
    markerSize: 'Marker Size',
    markerStroke: 'Marker Stroke',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Item Spacing',
    itemPaddingX: 'Item Padding X',
    itemPaddingY: 'Item Padding Y',
    layoutHorizontalSpacing: 'Horizontal Spacing',
    layoutVerticalSpacing: 'Vertical Spacing',
    strokeWidth: 'Stroke Width',
    lineDash: 'Line Dash',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markers',
    shadow: 'Shadow',
    blur: 'Blur',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Line Width',
    normal: 'Нормален',
    bold: 'Удебелен',
    italic: 'Курсив',
    boldItalic: 'Удебелен и курсив',
    predefined: 'Предефиниран',
    fillOpacity: 'Fill Opacity',
    strokeOpacity: 'Line Opacity',
    histogramBinCount: 'Bin count',
    columnGroup: 'Колона',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Line',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Area',
    histogramGroup: 'Histogram',
    combinationGroup: 'Combination',
    groupedColumnTooltip: 'Grouped',
    stackedColumnTooltip: 'Stacked',
    normalizedColumnTooltip: '100% Stacked',
    groupedBarTooltip: 'Grouped',
    stackedBarTooltip: 'Stacked',
    normalizedBarTooltip: '100% Stacked',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Line',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Stacked',
    normalizedAreaTooltip: '100% Stacked',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bubble',
    histogramTooltip: 'Histogram',
    columnLineComboTooltip: 'Column & Line',
    areaColumnComboTooltip: 'Area & Column',
    customComboTooltip: 'Custom Combination',
    noDataToChart: 'No data available to be charted.',
    pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Linked to Grid',
    chartUnlinkToolbarTooltip: 'Unlinked from Grid',
    chartDownloadToolbarTooltip: 'Download Chart',
    seriesChartType: 'Series Chart Type',
    seriesType: 'Series Type',
    secondaryAxis: 'Secondary Axis',

    // ARIA
    ariaChecked: 'checked',
    ariaColumn: 'Колона',
    ariaColumnGroup: 'Групи на колони',
    ariaColumnList: 'Списък с колони',
    ariaColumnSelectAll: 'Toggle Select All Columns',
    ariaDateFilterInput: 'Date Filter Input',
    ariaDefaultListName: 'Списък',
    ariaFilterColumnsInput: 'Filter Columns Input',
    ariaFilterFromValue: 'Филтриране от стойност',
    ariaFilterInput: 'Filter Input',
    ariaFilterList: 'Списък с филтри',
    ariaFilterToValue: 'Филтриране до стойност',
    ariaFilterValue: 'Стойност на филтъра',
    ariaFilteringOperator: 'Оператор за филтриране',
    ariaHidden: 'скрит',
    ariaIndeterminate:'неопределен',
    ariaInputEditor: 'Редактор на въвеждане',
    ariaMenuColumn: 'Натиснете CTRL ENTER, за да отворите менюто за колони.',
    ariaRowDeselect: 'Натиснете SPACE, за да премахнете отметката от този ред',
    ariaRowSelectAll: 'Натиснете SPACE, за да превключите избора на всички редове',
    ariaRowToggleSelection: 'Натиснете SPACE, за да превключите избора на ред',
    ariaRowSelect: 'Натиснете SPACE за да изберете този ред',
    ariaSearch: 'Търсене',
    ariaSortableColumn: 'Натиснете ENTER за да сортирате',
    ariaToggleVisibility: 'Натиснете SPACE, за да превключите видимостта',
    ariaUnchecked: 'без отметка',
    ariaVisible: 'видими',
    ariaSearchFilterValues: 'Стойности на филтъра за търсене',

    // ARIA Labels for Drop Zones

    ariaRowGroupDropZonePanelLabel: 'Групи на редове',
    ariaValuesDropZonePanelLabel: 'Стойности',
    ariaPivotDropZonePanelLabel: 'Етикети на колони',
    ariaDropZoneColumnComponentDescription: 'Натиснете DELETE за да премахнете',
    ariaDropZoneColumnValueItemDescription: 'Натиснете ENTER, за да промените типа агрегиране',
    ariaDropZoneColumnGroupItemDescription: 'Натиснете ENTER за да сортирате',
    // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
    ariaDropZoneColumnComponentAggFuncSeperator: ' of ',
    ariaDropZoneColumnComponentSortAscending: 'възходящ',
    ariaDropZoneColumnComponentSortDescending: 'низходящ',

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: 'Меню на колона',
    ariaLabelCellEditor: 'Редактор на клетка',
    ariaLabelDialog: 'Диалог',
    ariaLabelSelectField: 'Select Field',
    ariaLabelTooltip: 'Tooltip',
    ariaLabelContextMenu: 'Context Menu',
    ariaLabelSubMenu: 'Подменю',
    ariaLabelAggregationFunction: 'Агрегатни функции',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: ',',
    decimalSeparator: '.',
};

export default AG_GRID_LOCALE_BG;
