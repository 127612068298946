import React from 'react';
import Card from '../elements/ReactBootstrap/Card';
import {Col, Row} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import ErrorWidget from '../widgets/ErrorWidget';
import {useGetUserQuery} from '../../features/apiSlice';
import UserInfoWidget from '../widgets/UserInfoWidget';
import UserSessionsInfoWidget from '../widgets/UserSessionsInfoWidget';
import Content from '../partials/Content.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';

const User = () => {
    const {userId} = useParams();

    const {
        data: user,
        error,
        isLoading,
        isSuccess,
        isError,
    } = useGetUserQuery(userId);

    return (<Content title="Информация за служител">
        <Row className="row-cols-1">
            <Col>
                {isLoading && <Card>Зареждане на информация за служителя... <SmallSpinner /></Card>}
                {isError && <ErrorWidget error={error} />}
                {isSuccess && <>
                    <Card>
                        <UserInfoWidget user={user} />
                    </Card>
                    {user.sessions.length > 0 && <Card noPadding header={'Успешни вписвания в системата (' +
                        user.sessions.length + ')'}>
                        <UserSessionsInfoWidget sessions={user.sessions} />
                    </Card>}
                </>}
            </Col>
        </Row>
    </Content>);
};

export default User;
