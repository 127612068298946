import {format} from 'date-fns';

const DateShort = ({
    date,
    html,
}) => {
    if (date === undefined || date === null) {
        return '';
    }

    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    const content = format(date, 'dd.MM.yyyy');

    return html ? <span title={format(date, 'dd.MM.yyyy HH:mm:ss')}>{content}</span> : content;
};

DateShort.defaultProps = {
    html: false,
};

export default DateShort;
