import React from 'react';
import Card from '../../elements/ReactBootstrap/Card';
import MtplOfferWidget from '../../widgets/Mtpl/MtplOfferWidget';
import {Col, Row} from 'react-bootstrap';
import {useCreateMtplApplicationMutation} from '../../../features/apiSlice';
import ErrorWidget from '../../widgets/ErrorWidget';
import {useNavigate, useParams} from 'react-router-dom';
import MtplOfferWidgetAdditionalInformation from '../../widgets/Mtpl/MtplOfferWidgetAdditionalInformation';
import Content from '../../partials/Content.jsx';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';

const MtplOffer = () => {
    const [createApplication, {
        error,
        isLoading,
        isError,
    }] = useCreateMtplApplicationMutation();

    const navigate = useNavigate();
    const {action} = useParams();

    return (<Content title="Оферта за нова полица ГО">
        <Row>
            <Col>
                <Card outline header="Бърза оферта ГО">
                    <MtplOfferWidget autoFocus={true} autoSubmit={action === 'find'} onSubmit={async values => {
                        navigate('/mtpl/new', {
                            replace: true,
                        });

                        const application = await createApplication(values).unwrap();
                        navigate('/mtpl/new/application/' + application.id);
                    }}>
                        <MtplOfferWidgetAdditionalInformation />
                    </MtplOfferWidget>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col>
                {isLoading && <Card>
                    Проверка на данните за тарифиране... <SmallSpinner />
                </Card>}
                {isError && <ErrorWidget error={error} />}
            </Col>
        </Row>
    </Content>);
};

export default MtplOffer;
