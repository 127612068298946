import React, {useMemo} from 'react';
import {Accordion, Col, Container, Row} from 'react-bootstrap';
import {
    useGetGreenCardInventoryQuery,
    useGetStickerInventoryQuery,
    useGetValuationCertificateInventoryQuery
} from '../../../features/apiSlice';
import StickerInfoButton from '../NavigationButtons/StickerInfoButton';
import GreenCardInfoButton from '../NavigationButtons/GreenCardInfoButton';
import ErrorIcon from '../../elements/Icons/ErrorIcon';
import PropTypes from 'prop-types';
import ValuationCertificateInfoButton from '../NavigationButtons/ValuationCertificateInfoButton';
import {allowValuationCertificates} from '../../../utils';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';

const InsuranceCompanyBsoInventoryWidget = ({
    insuranceCompany,
    office,
}) => {
    const hasValuationCertificates = useMemo(
        () => allowValuationCertificates(insuranceCompany),
        [insuranceCompany],
    );

    const {
        data: stickers,
        error: stickersError,
        isSuccess: stickersIsSuccess,
        isError: stickersIsError,
        isLoading: stickersIsLoading,
        isFetching: stickersIsFetching,
    } = useGetStickerInventoryQuery([insuranceCompany.id, office]);

    const {
        data: greenCards,
        error: greenCardsError,
        isSuccess: greenCardsIsSuccess,
        isError: greenCardsIsError,
        isLoading: greenCardsIsLoading,
        isFetching: greenCardsIsFetching,
    } = useGetGreenCardInventoryQuery([insuranceCompany.id, office]);

    const {
        data: valuationCertificates,
        error: valuationCertificatesError,
        isSuccess: valuationCertificatesIsSuccess,
        isError: valuationCertificatesIsError,
        isLoading: valuationCertificatesIsLoading,
        isFetching: valuationCertificatesIsFetching,
    } = useGetValuationCertificateInventoryQuery([insuranceCompany.id, office], {
        skip: !hasValuationCertificates,
    });

    const stickersLoading = stickersIsLoading || stickersIsFetching;
    const stickersSuccess = stickersIsSuccess && !stickersIsFetching;

    const greenCardsLoading = greenCardsIsLoading || greenCardsIsFetching;
    const greenCardsSuccess = greenCardsIsSuccess && !greenCardsIsFetching;

    const valuationCertificatesLoading = valuationCertificatesIsLoading || valuationCertificatesIsFetching;
    const valuationCertificatesSuccess = valuationCertificatesIsSuccess && !valuationCertificatesIsFetching;

    return (<Accordion.Item eventKey={insuranceCompany.id}>
        <Accordion.Header>
            <Container fluid className="gx-0">
                <Row>
                    <Col>
                        <strong>{insuranceCompany.shortName}</strong>
                    </Col>
                    <Col>
                        Стикери:{' '}
                        {stickersLoading && <SmallSpinner />}
                        {stickersIsError && <ErrorIcon error={stickersError} />}
                        {stickersSuccess && stickers.length}
                        {' / '}СЗК:{' '}
                        {greenCardsLoading && <SmallSpinner />}
                        {greenCardsIsError && <ErrorIcon error={greenCardsError} />}
                        {greenCardsSuccess && greenCards.length}
                        {hasValuationCertificates && <>
                            {' / '}УЗС:{' '}
                            {valuationCertificatesLoading && <SmallSpinner />}
                            {valuationCertificatesIsError && <ErrorIcon error={valuationCertificatesError} />}
                            {valuationCertificatesSuccess && valuationCertificates.length}
                        </>}
                    </Col>
                </Row>
            </Container>
        </Accordion.Header>
        <Accordion.Body>
            {stickersSuccess && stickers.length === 0 &&
                greenCardsSuccess && greenCards.length === 0 && ((
                    hasValuationCertificates && valuationCertificatesSuccess &&
                    valuationCertificates.length === 0
                ) || !hasValuationCertificates) &&
                'Няма налични бланки на ' + insuranceCompany.shortName
            }
            {stickersSuccess && stickers.length > 0 && <div>
                Стикери:{' '}
                {stickersLoading && <SmallSpinner />}
                {stickersSuccess && stickers.map(sticker => <span className="me-2 mb-3" key={sticker.id}>
                    <StickerInfoButton key={sticker.id} sticker={sticker} />
                </span>)}
            </div>}
            {greenCardsSuccess && greenCards.length > 0 && <div className="mt-3">
                СЗК:{' '}
                {greenCardsLoading && <SmallSpinner />}
                {greenCardsSuccess && greenCards.map(greenCard => <span className="me-2 mb-3"
                    key={greenCard.id}>
                    <GreenCardInfoButton key={greenCard.id} greenCard={greenCard} />
                </span>)}
            </div>}
            {hasValuationCertificates && valuationCertificatesSuccess &&
                valuationCertificates.length > 0 && <div className="mt-3">
                    УЗС:{' '}
                    {valuationCertificatesLoading && <SmallSpinner />}
                {valuationCertificates.map(valuationCertificate =>
                    <span className="me-2 mb-3" key={valuationCertificate.id}>
                        <ValuationCertificateInfoButton key={valuationCertificate.id}
                                                        valuationCertificate={valuationCertificate} />
                    </span>
                )}
            </div>}
        </Accordion.Body>
    </Accordion.Item>);
};

InsuranceCompanyBsoInventoryWidget.propTypes = {
    insuranceCompany: PropTypes.shape({
        id: PropTypes.string.isRequired,
        shortName: PropTypes.string.isRequired,
    }).isRequired,
    office: PropTypes.string,
};

export default InsuranceCompanyBsoInventoryWidget;
