import React from 'react';
import {Card, Col, Nav, Row, Tab} from 'react-bootstrap';
import NavItem from '../elements/NavItem';
import {Outlet, useMatch} from 'react-router-dom';
import RequireNonProdEnv from '../elements/AccessControl/RequireNonProdEnv';
import RequireRoles from '../elements/AccessControl/RequireRoles';
import Content from '../partials/Content.jsx';

const Bso = () => {
    const isFluid = useMatch('/bso/protocols');

    return (<Content title="Управление на БСО">
        <Row className="row-cols-1 flex-column flex-grow-1">
            <Col className="d-flex flex-grow-1 flex-column">
                <Card className={"card-success card-tabs" + (isFluid ? ' flex-grow-1' : '')}>
                    <Card.Header className="p-0 pt-1">
                        <Nav as="ul" className="nav-tabs" style={isFluid ? {
                            border: 0,
                        } : null}>
                            <NavItem to="inventory">Наличност</NavItem>
                            <NavItem to="protocols">ППП</NavItem>
                            <RequireRoles names={['ROLE_SHOW_BROKER', 'ROLE_BSO_CREATE']}>
                                <NavItem to="create">Регистрация</NavItem>
                            </RequireRoles>
                            <RequireRoles names={['ROLE_SHOW_BROKER', 'ROLE_BSO_PROTOCOL_CREATE']}>
                                <NavItem to="protocol/create">Прехвърляне</NavItem>
                            </RequireRoles>
                            <RequireNonProdEnv>
                                <NavItem to="search">Търсене</NavItem>
                                <NavItem to="update">Промяна</NavItem>
                            </RequireNonProdEnv>
                        </Nav>
                    </Card.Header>
                    <Card.Body className={isFluid ? 'p-0 d-flex' : null}>
                        <Tab.Content className={isFluid ? 'd-flex flex-column flex-grow-1' : null}>
                            <Tab.Pane active className={isFluid ? 'd-flex flex-column flex-grow-1' : null}>
                                <Outlet />
                            </Tab.Pane>
                        </Tab.Content>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Content>);
};

export default Bso;
