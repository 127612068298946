import React from 'react';
import LinkCopyButton from './LinkCopyButton';
import PropTypes from 'prop-types';

const EmailButton = ({
    email,
    small,
}) => {
    return (<LinkCopyButton href={'mailto:' + email} copyText={email} small={small}>
        <i className="far fa-envelope"/> {email}
    </LinkCopyButton>);
};

EmailButton.defaultProps = {
    small: false,
};

EmailButton.propTypes = {
    email: PropTypes.string.isRequired,
    small: PropTypes.bool,
};

export default EmailButton;
