import {getToken} from './authStorage.js';

const getAuthenticatedUrl = (path, download) => import.meta.env.VITE_API_BASE_URL  + path +
    '?bearer=' + getToken() + (download ? '&download=1' : '');

export const getPolicyUrl = (id, download) =>
    getAuthenticatedUrl('print/policy/' + id, download);

export const getVoucherUrl = (id, download) =>
    getAuthenticatedUrl('print/voucher/' + id, download);

export const getGreenCardUrl = (id, download) =>
    getAuthenticatedUrl('print/green-card/' + id, download);

export const getInsuranceCompanyReportUrl = (insuranceCompanyId, weekCode, download) =>
    getAuthenticatedUrl('report/insurance-company/' + insuranceCompanyId + '/' + weekCode, download);
