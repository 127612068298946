import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonGroup, Dropdown} from 'react-bootstrap';

const PrintButton = ({
    icon,
    label,
    printing,
    disabled,
    setDisabled,
    printAction,
    viewUrl,
    downloadUrl,
    small,

    ...props
}) => {
    const [buttonPrinting, setButtonPrinting] = useState(false);
    const inProgress = printing || buttonPrinting;

    return (
        <Dropdown as={ButtonGroup} className="w-100" {...props}>
            <Button variant="outline-success" disabled={disabled} onClick={async () => {
                setButtonPrinting(true);
                setDisabled(true);
                try {
                    await printAction();
                }
                catch (e) {
                    alert(e.message);
                }
                setButtonPrinting(false);
                setDisabled(false);
            }} className={'w-100' + (small ? ' p-1' : '')}>
                <i className={'fas fa-' + (inProgress ? 'sync-alt fa-spin' : icon)}/>{' '}{label}
            </Button>
            <Dropdown.Toggle variant="outline-success" split disabled={disabled} className={small ? ' p-1' : ''} />
            {(viewUrl || downloadUrl) && <Dropdown.Menu role="menu" align="end">
                {viewUrl && <Dropdown.Item href={viewUrl} download target="_blank" rel="noreferrer">
                    <i className="fas fa-file-pdf" /> Преглед на файла
                </Dropdown.Item>}
                {downloadUrl && <Dropdown.Item href={downloadUrl} download target="_blank" rel="noreferrer">
                    <i className="fas fa-file-download" /> Сваляне на файла
                </Dropdown.Item>}
            </Dropdown.Menu>}
        </Dropdown>
    );
};

PrintButton.defaultProps = {
    small: false,
};

PrintButton.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    printing: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    setDisabled: PropTypes.func.isRequired,
    printAction: PropTypes.func.isRequired,
    viewUrl: PropTypes.string,
    downloadUrl: PropTypes.string,
    small: PropTypes.bool,
};

export default PrintButton;
