import PropTypes from 'prop-types';
import {isNumeric} from "../../../utils.js";

const Percent = ({
    coefficient,
    showPercentSign,
}) => isNumeric(coefficient) ? (coefficient * 100).toFixed(0) + (showPercentSign ? '%' : '') : null;

Percent.defaultProps = {
    showPercentSign: true,
};

Percent.propTypes = {
    coefficient: PropTypes.number,
    showPercentSign: PropTypes.bool,
};

export default Percent;
