import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import PremiumButton from './PremiumButton';
import {Col, Row} from 'react-bootstrap';
import Card from '../../elements/ReactBootstrap/Card';
import {installmentColors} from '../../../config';
import PremiumDescription from '../../elements/Values/PremiumDescription';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {bestOfferSelector, offersProgressSelector} from '../../../features/apiSlice';
import {getInstallmentsCountLabel} from "../../../utils.js";

const MtplBestOfferWidget = ({
    installmentsCount,
}) => {
    const {
        applicationId,
    } = useParams();

    const offer = useSelector(bestOfferSelector(applicationId, installmentsCount));
    const progress = useSelector(offersProgressSelector(applicationId));

    const header = useMemo(
        () => 'Най-добра оферта на ' + getInstallmentsCountLabel(installmentsCount),
        [installmentsCount],
    );

    const isLoading = progress < 1;

    return (
        <Card header={header} isLoading={isLoading}
              headerClassName={'bg-' + installmentColors[installmentsCount]} baseClassName="flex-grow-1">
            <Row>
                {offer ? <>
                    <Col>
                        <Row>
                            <Col>
                                <h3>{offer.insuranceCompany.shortName}</h3>
                            </Col>
                        </Row>
                        {offer.installmentsCount > 1 && <Row>
                            <Col>
                                <PremiumDescription offer={offer} />
                            </Col>
                        </Row>}
                    </Col>
                    <Col className="flex-grow-0">
                        <PremiumButton variant={installmentColors[installmentsCount]} size="lg" offer={offer}/>
                    </Col>
                </> : (progress === 1 && <Col>Не е намерена оферта</Col>)}
            </Row>
        </Card>
    );
};

MtplBestOfferWidget.propTypes = {
    installmentsCount: PropTypes.number.isRequired,
};

export default MtplBestOfferWidget;
