import React, {useMemo} from 'react';
import {Form, Formik} from 'formik';
import {Col, Container, Row} from 'react-bootstrap';
import AutoPersistToken from '../../../elements/Formik/AutoPersistToken';
import useFormPersistedValues from '../../../../hooks/useFormPersistedValues';
import PropTypes from 'prop-types';
import {formatWeekCode} from '../../../../utils';
import WeekSelector from '../../../elements/Form/WeekSelector';
import SubmitButton from '../../../elements/Form/SubmitButton';
import AutoSubmitToken from '../../../elements/Formik/AutoSubmitToken';

const WeekSelectionWidget = ({
    onSubmit,
    autoSubmit,
}) => {
    const formName = 'salesFormWeek';
    const initialFormValues = useFormPersistedValues(formName);
    const currentWeekFormatted = useMemo(() => formatWeekCode(new Date()), []);

    return (
        <Formik initialValues={initialFormValues || {
            weekCode: currentWeekFormatted,
        }} onSubmit={onSubmit}>
            <Container as={Form} className="gx-0">
                <Row className="row-cols-1 row-cols-xl-2 g-3 align-items-end">
                    <Col>
                        <WeekSelector />
                    </Col>
                    <Col>
                        <SubmitButton>Изготвяне</SubmitButton>
                    </Col>
                </Row>
                {autoSubmit && <AutoSubmitToken />}
                <AutoPersistToken formName={formName}/>
            </Container>
        </Formik>
    );
};

WeekSelectionWidget.defaultProps = {
    autoSubmit: false,
};

WeekSelectionWidget.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    autoSubmit: PropTypes.bool,
};

export default WeekSelectionWidget;
