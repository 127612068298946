import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import CopyButton from './CopyButton';
import StickerNumber from '../elements/Values/StickerNumber';
import PolicyInfoButton from './NavigationButtons/PolicyInfoButton';
import {formatStickerNumber} from '../../utils.js';
import BsoStatusWidget from './BsoStatusWidget.jsx';

const StickerInfoWidget = ({
    sticker,
}) => {
    return (<>
        <Row as="dl" className="mb-0">
            <Col sm={4} as="dt">Номер</Col>
            <Col sm={8} as="dd">
                <StickerNumber {...sticker} />
                <CopyButton text={formatStickerNumber(sticker.number)} />
            </Col>
            <Col sm={4} as="dt">Статус</Col>
            <Col sm={8} as="dd"><BsoStatusWidget type="sticker" updateRole="ROLE_UPDATE_STICKER" {...sticker} /></Col>
            {sticker.lastAvailableYear && <>
                <Col sm={4} as="dt">Налични години за перфорация</Col>
                <Col sm={8} as="dd">{[...Array(3).keys()].reverse().map(index =>
                    <span key={index} className="badge bg-secondary me-1">{sticker.lastAvailableYear - index}</span>
                )}</Col>
            </>}
            {sticker.insuranceCompany && <>
                <Col sm={4} as="dt">Застрахователна компания</Col>
                <Col sm={8} as="dd">{sticker.insuranceCompany.shortName}</Col>
            </>}
            {sticker.insuranceBroker && <>
                <Col sm={4} as="dt">Брокер</Col>
                <Col sm={8} as="dd">{sticker.insuranceBroker.shortName}</Col>
            </>}
            {sticker.office && <>
                <Col sm={4} as="dt">Офис</Col>
                <Col sm={8} as="dd">{sticker.office.name}</Col>
            </>}
            {sticker.user && <>
                <Col sm={4} as="dt">Отговорно лице</Col>
                <Col sm={8} as="dd">{sticker.user.shortName}</Col>
            </>}
            {sticker.installments && sticker.installments.length > 0 && <>
                <Col sm={4} as="dt">Използван по полица</Col>
                <Col sm={8} as="dd"><PolicyInfoButton policy={sticker.installments[0].policy} /></Col>
            </>}
        </Row>
    </>);
};

StickerInfoWidget.propTypes = {
    sticker: PropTypes.shape({
        number: PropTypes.number.isRequired,
    }).isRequired,
};

export default StickerInfoWidget;
