import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import TextWithCopyButton from './TextWithCopyButton.jsx';

const UserInfoWidget = ({
    user,
}) => {
    return (<>
        <Row as="dl" className="mb-0">
            {user.fullName && <>
                <Col sm={4} as="dt">Име</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={user.fullName} />
                </Col>
            </>}
            {user.firstName && <>
                <Col sm={4} as="dt">Собствено име</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={user.firstName} />
                </Col>
            </>}
            {user.middleName && <>
                <Col sm={4} as="dt">Презиме</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={user.middleName} />
                </Col>
            </>}
            {user.lastName && <>
                <Col sm={4} as="dt">Фамилно име</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={user.lastName} />
                </Col>
            </>}
            {user.pin && <>
                <Col sm={4} as="dt">ЕГН/ЕИК</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={user.pin} />
                </Col>
            </>}
            {user.insuranceBroker && <>
                <Col sm={4} as="dt">Брокер</Col>
                <Col sm={8} as="dd">{user.insuranceBroker.shortName}</Col>
            </>}
            {user.office && <>
                <Col sm={4} as="dt">Офис</Col>
                <Col sm={8} as="dd">{user.office.name}</Col>
            </>}
        </Row>
    </>);
};

UserInfoWidget.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        pin: PropTypes.string.isRequired,
        fullName: PropTypes.string.isRequired,
    }).isRequired,
};

export default UserInfoWidget;
