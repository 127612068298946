import {useEffect} from 'react';
import {useFormikContext} from 'formik';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {setForm} from '../../../features/formSlice';

const AutoPersistToken = ({
    formName,
}) => {
    const {values} = useFormikContext();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setForm({formName, values}));
    }, [dispatch, formName, values]);

    return null;
};

AutoPersistToken.propTypes = {
    formName: PropTypes.string.isRequired,
};

export default AutoPersistToken;
