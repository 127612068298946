import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import TextWithCopyButton from './TextWithCopyButton.jsx';

const ClientInfoWidget = ({
    client,
}) => {
    return (<>
        <Row as="dl" className="mb-0">
            {client.pin && <>
                <Col sm={4} as="dt">ЕГН/ЕИК</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={client.pin} />
                </Col>
            </>}
            {client.name && <>
                <Col sm={4} as="dt">Име</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={client.name} />
                </Col>
            </>}
            {client.firstName && <>
                <Col sm={4} as="dt">Собствено име</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={client.firstName} />
                </Col>
            </>}
            {client.middleName && <>
                <Col sm={4} as="dt">Презиме</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={client.middleName} />
                </Col>
            </>}
            {client.lastName && <>
                <Col sm={4} as="dt">Фамилно име</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={client.lastName} />
                </Col>
            </>}
            {client.driversLicenseNumber && <>
                <Col sm={4} as="dt">Номер на СУМПС</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={client.driversLicenseNumber} />
                </Col>
            </>}
        </Row>
    </>);
};

ClientInfoWidget.propTypes = {
    client: PropTypes.shape({
        pin: PropTypes.string.isRequired,
        name: PropTypes.string,
        firstName: PropTypes.string,
        middleName: PropTypes.string,
        lastName: PropTypes.string,
    }).isRequired,
};

export default ClientInfoWidget;
