import React from 'react';
import Card from '../elements/ReactBootstrap/Card';
import MtplOfferWidget from '../widgets/Mtpl/MtplOfferWidget';
import PolicySearchWidget from '../widgets/PolicySearchWidget';
import PendingPaymentsWidget from '../widgets/PendingPaymentsWidget.jsx';
import SalesSummaryWidget from '../widgets/Report/Summary/SalesSummaryWidget';
import {Link, useNavigate} from 'react-router-dom';
import {Button, Col, Row} from 'react-bootstrap';
import Content from '../partials/Content.jsx';
import RankingWidget from '../widgets/Report/Ranking/RankingWidget.jsx';

const Dashboard = () => {
    const navigate = useNavigate();

    return (<Content title="Начална страница">
        <Row className="row-cols-1">
            <Col>
                <Card outline header="Бърза оферта ГО">
                    <MtplOfferWidget autoFocus={true} onSubmit={() => {
                        navigate('/mtpl/new/find');
                    }}>
                        <Col>
                            <Button as={Link} variant="outline-secondary" to="/mtpl/new" className="w-100">
                                Допълнителна информация
                            </Button>
                        </Col>
                    </MtplOfferWidget>
                </Card>
            </Col>
            <Col>
                <Card outline header="Плащане на поредна вноска">
                    <PolicySearchWidget formName="policySearchInstallment" onSubmit={() => {
                        navigate('/installment-payment/find');
                    }} />
                </Card>
            </Col>
            <Col>
                <Card outline header="Продажби">
                    <SalesSummaryWidget />
                </Card>
            </Col>
            <Col>
                <PendingPaymentsWidget pollingInterval={60000} />
            </Col>
            <Col>
                <RankingWidget />
            </Col>
        </Row>
    </Content>);
};

export default Dashboard;
