import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'react-bootstrap';

const BackButton = ({
    children,

    ...props
}) => {
    return (<Button as={Link} {...props}>
        <i className="far fa-arrow-alt-circle-left" /> {children}
    </Button>);
};

BackButton.defaultProps = {
    children: 'Връщане в списъка',
    variant: 'outline-secondary',
    className: 'w-100',
};

export default BackButton;
