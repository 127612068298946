import PropTypes from "prop-types";
import PremiumAmount from "../Values/PremiumAmount";
import InfoIcon from "./InfoIcon";
import React from "react";

const DetailsIcon = ({
    details,
}) => {
    if (!details || details.length < 2) {
        return null;
    }

    return (
        <InfoIcon onClick={() => alert(details.map(detail =>
            detail.description + ': ' + PremiumAmount({
                amount: detail.totalAmount,
                withCurrency: PremiumAmount.defaultProps.withCurrency, // manually calling function
            })
        ).join('\n'))} />
    );
};

DetailsIcon.propTypes = {
    details: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string.isRequired,
        totalAmount: PropTypes.number.isRequired,
    })),
};

export default DetailsIcon;
