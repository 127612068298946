import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import WrappedCopyButton from '../WrappedCopyButton.jsx';

const NavigationButton = ({
    children,

    link,
    copyText,
    small,
}) => {
    return (<WrappedCopyButton text={copyText} small={small}>
        <Link to={link} className={'btn btn-outline-secondary' + (small ? ' p-1' : '')}>{children}</Link>
    </WrappedCopyButton>);
};

NavigationButton.defaultProps = {
    small: false,
};

NavigationButton.propTypes = {
    link: PropTypes.string.isRequired,
    copyText: PropTypes.string,
    small: PropTypes.bool,
};

export default NavigationButton;
