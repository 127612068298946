import {useFormikContext} from 'formik';
import {useEffect, useMemo, useState} from 'react';
import {uabCardTypes} from '../../../utils';

const AutoPriceSelect = () => {
    const prices = useMemo(() => Object.fromEntries(
        Object.entries(uabCardTypes).map(([type, card]) =>
            [type, card.totalAmount],
        )
    ), []);

    const {
        values,
        setFieldValue,
    } = useFormikContext();

    const [lastValues, setLastValues] = useState({});

    useEffect(() => {
        if (values.type !== lastValues.type) {
            setFieldValue('totalAmount', prices[values.type] ?? '');
        }

        setLastValues(values);
    }, [lastValues?.type, prices, setFieldValue, values]);

    return null;
};

export default AutoPriceSelect;
