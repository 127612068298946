import React from 'react';
import Content from '../partials/Content.jsx';

const TravelOffer = () => {
    return (<Content title="Оферта за нова полица Помощ при пътуване">
        <div>Очаквайте скоро!</div>
    </Content>);
};

export default TravelOffer;
