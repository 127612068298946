import Card from '../elements/ReactBootstrap/Card';
import {Col, Row} from 'react-bootstrap';
import PolicySearchWidget from '../widgets/PolicySearchWidget';
import React from 'react';
import {useCreatePolicyMutation, useLazyListPoliciesQuery} from '../../features/apiSlice';
import {useNavigate} from 'react-router-dom';
import ErrorWidget from '../widgets/ErrorWidget';
import CreatePolicyWidget from '../widgets/CreatePolicyWidget';
import Content from '../partials/Content.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';

const CreatePolicy = () => {
    const navigate = useNavigate();

    const [list, {
        data: listResult,
        error: listError,
        isLoading: listLoadingOriginal,
        isFetching: listFetchingOriginal,
        isSuccess: listSuccessOriginal,
        isError: listIsError,
    }] = useLazyListPoliciesQuery();

    const listLoading = listLoadingOriginal || listFetchingOriginal;
    const listSuccess = listSuccessOriginal && !listLoading;

    const [create, {
        reset: createReset,
        error: createError,
        isError: createIsError,
        isLoading: createLoading,
    }] = useCreatePolicyMutation();

    return (<Content title="Въвеждане на застрахователна полица">
        <Row className="row-cols row-cols-1 row-cols-xl-2">
            <Col>
                <Card outline>
                    <PolicySearchWidget formName="policyCreate" findButtonIcon="fas fa-plus" findButtonLabel="Въвеждане"
                        onSubmit={async values => {
                            createReset();

                            const result = await list({
                                query: values.policyNumber,
                            }).unwrap();

                            if (result[0]) {
                                navigate('/policy/' + result[0].id);
                                return;
                            }

                            const createdPolicy = await create({
                                number: values.policyNumber,
                                installmentsCount: parseInt(values.installmentsCount, 10),
                                paidInstallmentsCount: parseInt(values.paidInstallmentsCount, 10),
                            }).unwrap();
                            navigate('/policy/' + createdPolicy.id);
                        }} defaultFormInitialValues={{
                            installmentsCount: '',
                            paidInstallmentsCount: '0',
                    }}>
                        <CreatePolicyWidget />
                    </PolicySearchWidget>
                </Card>
                {listLoading && <Card>Търсене на полица... <SmallSpinner /></Card>}
                {listIsError && <ErrorWidget error={listError} />}
                {createLoading && <Card>Въвеждане на полица... <SmallSpinner /></Card>}
                {createIsError && <ErrorWidget error={createError} />}
                {listSuccess && listResult.length > 1 && 'списък с полици с един и същи номер при различни ЗК'}
            </Col>
        </Row>
    </Content>);
};

export default CreatePolicy;
