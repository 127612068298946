import PremiumAmount from './PremiumAmount';
import React from 'react';
import PropTypes from 'prop-types';

const PremiumDescription = ({
    offer,
}) => {
    return offer.installments.map((value, idx) => {
        const premiumAmount = <PremiumAmount amount={value.totalAmount} withCurrency={false} />;
        return idx === 0 ? <u key={value.id}>{premiumAmount}</u> : <span key={value.id}> + {premiumAmount}</span>;
    });
};

PremiumDescription.propTypes = {
    offer: PropTypes.shape({
        installments: PropTypes.arrayOf(PropTypes.shape({
            totalAmount: PropTypes.number.isRequired,
        }).isRequired).isRequired,
    }).isRequired,
};

export default PremiumDescription;
