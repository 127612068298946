import React from 'react';
import PropTypes from 'prop-types';
import CopyButton from './CopyButton.jsx';

const WrappedCopyButton = ({
    children,

    text,
    small,
}) => {
    return (<span className="text-nowrap">
        {children}
        <CopyButton text={text} small={small} />
    </span>);
};

WrappedCopyButton.defaultProps = {
    small: false,
};

WrappedCopyButton.propTypes = {
    text: PropTypes.string,
    small: PropTypes.bool,
};

export default WrappedCopyButton;
