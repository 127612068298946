import React from "react";
import PropTypes from "prop-types";

const Step = ({
    number,
    label,
    noLine,
}) => {
    if (!number && !label) {
        return null;
    }

    return (
        <div className="bs-stepper">
            <div className="bs-stepper-header">
                <div className="step">
                    <span className="step-trigger">
                        {number && <span className="bs-stepper-circle">{number}</span>}
                        {label && <span className="bs-stepper-label">{label}</span>}
                    </span>
                </div>
                {noLine || <div className="line" />}
            </div>
        </div>
    );
};

Step.defaultProps = {
    noLine: false,
}

Step.propTypes = {
    number: PropTypes.node,
    label: PropTypes.node,
    noLine: PropTypes.bool,
};

export default Step;
