import React from 'react';
import {Field, Form, Formik} from 'formik';
import AutoPersistToken from '../../elements/Formik/AutoPersistToken';
import useFormPersistedValues from '../../../hooks/useFormPersistedValues';

// TODO: react-bootstrap

const BsoSearchWidget = ({
    formName,
    onSubmit,
}) => {
    const initialFormValues = useFormPersistedValues(formName);

    return (
        <Formik
            initialValues={initialFormValues || {
                blankType: '',
                insuranceCompany: '',
                office: '',
                blankStatus: '',
                series: '',
                fromNumber: '',
                toNumber: '',
            }}
            onSubmit={onSubmit}
        >
            {({isSubmitting}) => (
                <Form>
                    <div className="row">
                        <div className="col-md">
                            <div className="form-group">
                                <label htmlFor="blankType">Тип на бланката</label>
                                <div className="input-group mb-3">
                                    <Field as="select" name="blankType" id="blankType"
                                           className="form-control" required disabled={isSubmitting}>
                                        <option>всички</option>
                                        <option>Стикер</option>
                                        <option>Зелена карта</option>
                                    </Field>
                                </div>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="form-group">
                                <label htmlFor="insuranceCompany">Застрахователна компания:</label>
                                <div className="input-group mb-3">
                                    <Field as="select" name="insuranceCompany" id="insuranceCompany"
                                           className="form-control" required disabled={isSubmitting}>
                                        <option>всички</option>
                                        <option>Лев Инс</option>
                                    </Field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <div className="form-group">
                                <label htmlFor="office">Офис:</label>
                                <div className="input-group mb-3">
                                    <Field as="select" name="office" id="office"
                                           className="form-control" required disabled={isSubmitting}>
                                        <option>всички</option>
                                        <option>Плевен</option>
                                    </Field>
                                </div>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="form-group">
                                <label htmlFor="blankStatus">Статус на бланката</label>
                                <div className="input-group mb-3">
                                    <Field as="select" name="blankStatus" id="blankStatus"
                                           className="form-control" required disabled={isSubmitting}>
                                        <option>всички</option>
                                        <option>нова</option>
                                        <option>използвана</option>
                                        <option>похабена/унищожена</option>
                                        <option>изгубена</option>
                                        <option>върната</option>
                                    </Field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <div className="form-group">
                                <label htmlFor="series">Серия</label>
                                <Field type="text" name="series" id="series" className="form-control"
                                       disabled={isSubmitting}
                                       maxLength={8}/>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="form-group">
                                <label htmlFor="fromNumber">От номер</label>
                                <Field type="text" name="fromNumber" id="fromNumber"
                                       className="form-control" required pattern="\d{9}" disabled={isSubmitting}
                                       maxLength={9}/>
                                <label htmlFor="toNumber">До номер</label>
                                <Field type="text" name="toNumber" id="toNumber"
                                       className="form-control" required pattern="\d{9}" disabled={isSubmitting}
                                       maxLength={9}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button type="submit" className="btn btn-success btn-block"
                                    disabled={isSubmitting}>Търси
                            </button>
                        </div>
                    </div>
                    <AutoPersistToken formName={formName} />
                </Form>
            )}
        </Formik>
    );
};

export default BsoSearchWidget;
