import React, {useMemo} from 'react';
import RankingTable from './RankingTable.jsx';
import Card from '../../../elements/ReactBootstrap/Card.jsx';
import PeriodSelector from '../../../elements/Form/PeriodSelector.jsx';
import {Formik} from 'formik';
import {getPeriods} from '../../../../utils.js';
import {formatInTimeZone} from 'date-fns-tz';

const RankingWidget = () => {
    const period = useMemo(() => getPeriods().currentHalfYearPeriod.value, []);

    return (<Formik initialValues={{
        period,
    }} onSubmit={() => {}}>
        {({values}) => {
            let period = null;

            try {
                period = JSON.parse(values.period);
            }
            catch {}

            if (period && period.startDateTime) {
                period.startDateTime = formatInTimeZone(period.startDateTime, 'Europe/Sofia',
                    'yyyy-MM-dd HH:mm:ssXXX');
            }

            if (period && period.endDateTime) {
                period.endDateTime = formatInTimeZone(period.endDateTime, 'Europe/Sofia',
                    'yyyy-MM-dd HH:mm:ssXXX');
            }

            return (<Card outline noPadding header="Класация по продажби">
                <div className="m-3">
                    <form>
                        <PeriodSelector />
                    </form>
                </div>
                {period && <RankingTable startDateTime={period.startDateTime} endDateTime={period.endDateTime}/>}
            </Card>);
        }}
    </Formik>);
};

export default RankingWidget;
