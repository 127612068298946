import React from 'react';
import {Button} from 'react-bootstrap';
import {copyText} from '../../utils';
import {getToken} from '../../authStorage.js';

const DebugMenu = () => {
    return (
        <li className="user-body">
            <Button variant="default" className="btn-flat"
                onClick={() => copyText(getToken())}>
                token <i className="far fa-copy" />
            </Button>
            <Button variant="default" className="btn-flat"
                onClick={() => copyText(JSON.stringify({
                    Authorization: 'Bearer ' + getToken()
                }))}>
                headers <i className="far fa-copy" />
            </Button>
        </li>
    );
};

export default DebugMenu;
