import React, {useMemo} from 'react';
import {Col, Container, FormControl, FormLabel, Row} from 'react-bootstrap';
import {Field, Form, Formik} from 'formik';
import useFormPersistedValues from '../../../hooks/useFormPersistedValues';
import AutoPersistToken from '../../elements/Formik/AutoPersistToken';
import BsoSaveWidget from './BsoSaveWidget';
import ErrorWidget from '../ErrorWidget';
import Alert from '../../elements/ReactBootstrap/Alert';
import {useCreateValuationCertificatesMutation, useListInsuranceCompaniesQuery} from '../../../features/apiSlice';
import {allowValuationCertificates} from '../../../utils';
import Step from "../../elements/BsStepper/Step.jsx";

const ValuationCertificateCreateWidget = () => {
    const {
        data: insuranceCompanies,
        isSuccess: insuranceCompaniesIsSuccess,
    } = useListInsuranceCompaniesQuery();

    const insuranceCompany = useMemo(() => insuranceCompaniesIsSuccess && insuranceCompanies.filter(allowValuationCertificates).shift()?.id, [insuranceCompaniesIsSuccess, insuranceCompanies]);

    const [create, {
        data: valuationCertificates,
        error: createError,
        isError: createIsError,
    }] = useCreateValuationCertificatesMutation();

    const formName = 'valuationCertificateCreateForm';
    const formInitialValues = useFormPersistedValues(formName);

    if (!insuranceCompany) {
        return null;
    }

    return (<Formik
        initialValues={formInitialValues || {
            totalAmount: 17,
            startNumber: '',
            endNumber: '',
        }}
        onSubmit={values => {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm('Потвърдете регистрацията на УЗС')) {
                return Promise.reject(new Error('Регистрацията беше отказана'));
            }

            return create({
                ...values,
                insuranceCompany,
            });
        }}
    >
        {({isSubmitting}) => (<Container fluid as={Form} className="gx-0">
            <Row className="row-cols-1 g-3">
                <Col>
                    <Step number="2" label="Въведете информация"/>
                </Col>
                <Col lg={6}>
                    Застрахователна компания: ДаллБогг
                </Col>
                <div className="w-100 gy-0" />
                <Col lg={6}>
                    <FormLabel htmlFor="totalAmount">Стойност в лв.</FormLabel>
                    <FormControl as={Field} type="number" name="totalAmount"
                                 id="totalAmount" required
                                 disabled={isSubmitting} min={1} max={100} step=".01" />
                </Col>
                <div className="w-100 gy-0" />
                <Col lg={6}>
                    <FormLabel htmlFor="startNumber">Първо УЗС</FormLabel>
                    <FormControl as={Field} type="text" name="startNumber" autoComplete="off"
                                 id="startNumber" required pattern="\d{6}"
                                 disabled={isSubmitting} maxLength={6} inputMode="decimal"/>
                </Col>
                <div className="w-100 gy-0" />
                <Col lg={6}>
                    <FormLabel htmlFor="endNumber">Последно УЗС</FormLabel>
                    <FormControl as={Field} type="text" name="endNumber" autoComplete="off"
                                 id="endNumber" required pattern="\d{6}"
                                 disabled={isSubmitting} maxLength={6} inputMode="decimal"/>
                </Col>
                <BsoSaveWidget type="УЗС" />
                <div className="w-100 gy-0" />
                {valuationCertificates && <Col lg={6}>
                    <Alert variant="success" heading={<>Успешно записахте {valuationCertificates.length} УЗС!</>} />
                </Col>}
                {createIsError && <Col lg={6}>
                    <ErrorWidget error={createError} />
                </Col>}
            </Row>
            <AutoPersistToken formName={formName}/>
        </Container>)}
    </Formik>);
};

export default ValuationCertificateCreateWidget;
