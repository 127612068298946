import React from 'react';
import PropTypes from 'prop-types';
import NavigationButton from './NavigationButton';
import ValuationCertificateNumber from '../../elements/Values/ValuationCertificateNumber';
import {formatValuationCertificateNumber} from '../../../utils';

const ValuationCertificateInfoButton = ({
    valuationCertificate,
    textField,
}) => {
    return (<NavigationButton link={'/valuation-certificate/' + valuationCertificate.id}
                              copyText={formatValuationCertificateNumber(valuationCertificate.number)}>
        {textField && !!valuationCertificate[textField] ? valuationCertificate[textField] : <ValuationCertificateNumber
            {...valuationCertificate} />}
    </NavigationButton>);
};

ValuationCertificateInfoButton.propTypes = {
    valuationCertificate: PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.number.isRequired,
    }),
    textField: PropTypes.string,
};

export default ValuationCertificateInfoButton;
