import PremiumDescription from '../../elements/Values/PremiumDescription';
import React from 'react';
import {useParams} from 'react-router-dom';
import {authenticatedApiSlice, offerSelector} from '../../../features/apiSlice';
import {useSelector} from 'react-redux';

const MtplPremiumDescription = ({
    insuranceCompany,
    installmentsCount,
}) => {
    const {
        applicationId,
    } = useParams();

    authenticatedApiSlice.endpoints.createOffers.useQuerySubscription({
        application: applicationId,
        insuranceCompany: insuranceCompany.id,
        installmentsCount,
    }, {
        skip: applicationId === undefined,
    });

    const offer = useSelector(offerSelector(applicationId, insuranceCompany.id, installmentsCount));

    if (!offer) {
        return null;
    }

    return (<small>
        <PremiumDescription offer={offer} />
    </small>);
};

export default MtplPremiumDescription;
