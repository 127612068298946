import React from 'react';
import LinkCopyButton from './LinkCopyButton';
import PropTypes from 'prop-types';

const PhoneButton = ({
    phone,
    small,
}) => {
    return (<LinkCopyButton href={'tel:' + phone} copyText={phone} small={small}>
        <i className="fas fa-phone"/> {phone}
    </LinkCopyButton>);
};

PhoneButton.defaultProps = {
    small: false,
};

PhoneButton.propTypes = {
    phone: PropTypes.string.isRequired,
    small: PropTypes.bool,
};

export default PhoneButton;
