import React from 'react';
import {useGetCurrentUserQuery} from '../../features/apiSlice';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';

const Help = () => {
    const {
        data: user,
        isSuccess,
        isLoading,
    } = useGetCurrentUserQuery();

    return (<>
        {isLoading && <SmallSpinner />}
        {isSuccess && user.insuranceBroker.supportUrl && <iframe src={user.insuranceBroker.supportUrl} title="help"
            className="w-100 h-100" />}
    </>);
};

export default Help;
