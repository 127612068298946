import React, {useState} from 'react';
import Card from '../elements/ReactBootstrap/Card';
import {Col, Row} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import ErrorWidget from '../widgets/ErrorWidget';
import {useGetStickerQuery} from '../../features/apiSlice';
import StickerInfoWidget from '../widgets/StickerInfoWidget';
import VouchersListWidget from '../widgets/VouchersListWidget';
import InstallmentsListWidget from '../widgets/InstallmentsListWidget';
import Content from '../partials/Content.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';

const Sticker = () => {
    const {stickerId} = useParams();
    const [printing, setPrinting] = useState(false);

    const {
        data: sticker,
        error,
        isLoading,
        isSuccess,
        isError,
    } = useGetStickerQuery(stickerId);

    return (<Content title="Информация за стикер">
        <Row className="row-cols-1">
            <Col>
                {isLoading && <Card>Зареждане на информация за стикера... <SmallSpinner /></Card>}
                {isError && <ErrorWidget error={error} />}
                {isSuccess && <Card>
                    <StickerInfoWidget sticker={sticker} />
                </Card>}
            </Col>
            {isSuccess && sticker.installments && sticker.installments.length > 0 && <Col>
                <Card noPadding header="Платени вноски с този стикер">
                    <InstallmentsListWidget product={{
                        hasMtplCover: true,
                        hasPassengersAccidentCover: false,
                    }} installments={sticker.installments} readOnly detailed />
                </Card>
            </Col>}
            {isSuccess && sticker.voucher && <Col>
                <Card noPadding header="Издадена квитанция с този стикер">
                    <VouchersListWidget policy={sticker.installments?.[0]?.policy} vouchers={[sticker.voucher]}
                        printing={printing} setPrinting={setPrinting} />
                </Card>
            </Col>}
        </Row>
    </Content>);
};

export default Sticker;
