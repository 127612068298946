import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Field, Form, Formik} from 'formik';
import IconField from '../elements/Formik/IconField';
import ChangeWidget from './ChangeWidget';
import {Button, Col, Container, FormControl, Row} from 'react-bootstrap';
import InstallmentPaymentBsoWidget from './InstallmentPaymentBsoWidget';
import PremiumAmount from '../elements/Values/PremiumAmount';
import {addDays, format} from 'date-fns';
import InfoIcon from '../elements/Icons/InfoIcon';
import RequireRole from '../elements/AccessControl/RequireRole';
import IconSelectField from '../elements/ReactSelect/IconSelectField.jsx';
import Alert from '../elements/ReactBootstrap/Alert.jsx';
import usePaymentMethods from '../../hooks/usePaymentMethods.js';
import BankAccountInfo from '../elements/BankAccountInfo.jsx';

const InstallmentPaymentWidget = ({
    totalAmount,
    bsoInsuranceCompanyId,
    policyNumber,
    onSubmit,
    showBeginDate,
    stickerRef,
    greenCardRef,
}) => {
    const [payAmount, setPayAmount] = useState('');
    const [timedPolicy, setTimedPolicy] = useState(false);

    const tomorrowFormatted = format(addDays(new Date(), 1), 'yyyy-MM-dd');

    const {
        paymentMethodOptions,
        paymentMethodIcons,
        hasNonCashPaymentMethods,
    } = usePaymentMethods();

    return (
        <Formik
            initialValues={{
                ...(showBeginDate ? {
                    beginDate: tomorrowFormatted,
                } : {}),
                ...(bsoInsuranceCompanyId ? {
                    sticker: '',
                    greenCard: '',
                } : {}),
                pin: '',
                paymentMethod: 'CASH',
                posTerminalReference: '',
            }}
            validate={values => {
                const errors = {};

                if (bsoInsuranceCompanyId) {
                    if (!values.sticker) {
                        errors.sticker = 'Моля изберете стикер!';
                    }

                    if (!values.greenCard) {
                        errors.greenCard = 'Моля изберете Зелена карта!';
                    }
                }

                return errors;
            }}
            onSubmit={values => onSubmit({
                ...values,
                ...(showBeginDate && timedPolicy ? {
                    beginDate: null,
                } : {}),
                ...(values.posTerminalReference === '' ? {
                    posTerminalReference: null,
                } : {}),
            })}>
            {({isSubmitting, values}) => (
                <Container as={Form} className="gx-0">
                    <Row className="row-cols-1 row-cols-md-2 g-3">
                        {showBeginDate && <>
                            <Col>
                                Начална дата
                            </Col>
                            <Col className="gy-0" />
                            <Col>
                                <label>
                                    <input type="radio" name="timedPolicy" checked={!timedPolicy} onChange={() =>
                                        setTimedPolicy(false)} disabled={isSubmitting} />
                                    <span className="ms-2">От 00:00 ч. на избрана дата</span>
                                </label>
                            </Col>
                            <Col className="gy-0" />
                            <Col hidden={timedPolicy}>
                                <IconField type="date" name="beginDate" required={!timedPolicy} min={tomorrowFormatted}
                                           disabled={isSubmitting} />
                            </Col>
                            <Col className="gy-0" hidden={timedPolicy} />
                            <Col>
                                <label>
                                    <input type="radio" name="timedPolicy" checked={timedPolicy} onChange={() =>
                                        setTimedPolicy(true)} disabled={isSubmitting} />
                                    <span className="ms-2">Възможно най-скоро днес</span>
                                </label>
                            </Col>
                            <Col className="gy-0" />
                            <Col className="gy-0" hidden={!timedPolicy}>
                                <InfoIcon onClick={() => alert(
                                    'Автоматично се избира начален час, според застрахователната компания:\n\n' +
                                    'Бул Инс - след 1 час\n' +
                                    'Булстрад - след 15 минути\n' +
                                    'Групама - след 1 час\n' + // TODO
                                    'ДаллБогг - след 2 часа\n' +
                                    'ДЗИ - след 1 час\n' + // TODO
                                    'Дженерали - след 1 час\n' +
                                    'Евроинс - след 15 минути\n' +
                                    'Лев-Инс - след 1 час\n' +
                                    'ОЗК - след 1 час\n' +
                                    'Уника - след 1 час\n'
                                )}>Повече информация</InfoIcon>
                            </Col>
                            <Col className="gy-0" />
                            <div className="w-100 gy-0" />
                        </>}
                        {bsoInsuranceCompanyId && <InstallmentPaymentBsoWidget insuranceCompanyId={bsoInsuranceCompanyId}
                            disabled={isSubmitting} stickerRef={stickerRef} greenCardRef={greenCardRef} />}
                        {hasNonCashPaymentMethods && <Col md={12}>
                            <Field name="paymentMethod" component={IconSelectField} options={paymentMethodOptions}
                                label="Начин на плащане" disabled={isSubmitting}
                                {...paymentMethodIcons[values.paymentMethod]} />
                        </Col>}
                        {values.paymentMethod === 'CASH' && <>
                            <Col>
                                <IconField label="Платено в брой" prependIcon="wallet" appendText="лв.">
                                    <FormControl type="text" value={payAmount} onChange={e => setPayAmount(e.target.value)}
                                           pattern="[\d.]*" inputMode="decimal" disabled={isSubmitting} />
                                </IconField>
                            </Col>
                            <Col className="align-self-end">
                                <ChangeWidget totalAmount={totalAmount} payAmount={payAmount} />
                            </Col>
                        </>}
                        {values.paymentMethod === 'POS_TERMINAL' && <>
                            <Col md={12}>
                                <IconField type="text" name="posTerminalReference" label="Номер на разписката"
                                    prependIcon="receipt" disabled={isSubmitting} maxLength="6" pattern="[a-zA-Z\d]{6}"
                                    autoComplete="off" />
                                <Alert variant="secondary" icon={null} className="mt-3 mb-0">
                                    <div>Тук въведете кода от 6 букви или цифри, който се намира под текста „не се
                                        изисква подпис“, между буквите AC и знака наклонена черта (/).</div>
                                    <div>Пример: AC <strong><u>2RG5F3</u></strong> / NO 341825 ...</div>
                                </Alert>
                            </Col>
                        </>}
                        {values.paymentMethod === 'BANK_ACCOUNT_BROKER' && <Col md={12}>
                            <BankAccountInfo totalAmount={totalAmount} reason={policyNumber} />
                        </Col>}
                        {values.paymentMethod === 'BANK_ACCOUNT_INSURANCE_COMPANY' && <Col md={12}>
                            <Alert variant="secondary" icon={null} className="mb-0">
                                Информация за банкова сметка и получател можете да намерите в кредитното известие от
                                застрахователната компания.
                            </Alert>
                        </Col>}
                        <Col>
                            <IconField type="text" name="pin" label="Парола" prependIcon="key" required pattern="\d{10}"
                                       inputMode="decimal" className="password" disabled={isSubmitting} maxLength={10}
                                       autoComplete="off" />
                        </Col>
                        <Col className="align-self-end">
                            <RequireRole name="ROLE_PAY">
                                <Button variant="success" type="submit" disabled={isSubmitting} className="w-100">
                                    <i className="fas fa-hand-holding-usd"/> Плащане
                                    на <PremiumAmount amount={totalAmount} />
                                </Button>
                            </RequireRole>
                        </Col>
                    </Row>
                </Container>
            )}
        </Formik>
    );
};

InstallmentPaymentWidget.defaultProps = {
    stickerRef: null,
    greenCardRef: null,
};

InstallmentPaymentWidget.propTypes = {
    totalAmount: PropTypes.number.isRequired,
    bsoInsuranceCompanyId: PropTypes.string,
    policyNumber: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
};

export default InstallmentPaymentWidget;
