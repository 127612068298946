import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../elements/ReactBootstrap/Alert';
import PolicyInfoButton from './NavigationButtons/PolicyInfoButton';

const SuccessfulIssueWidget = ({
    policy,
}) => {
    return (<Alert variant="success" heading={<>
        <span className="me-3">Успешно издадохте полица с номер</span>
        <PolicyInfoButton policy={policy} />
    </>} />);
};

SuccessfulIssueWidget.propTypes = {
    policy: PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
    }).isRequired,
};

export default SuccessfulIssueWidget;
