import React, {useState} from 'react';
import ClientSearchWidget from '../widgets/ClientSearchWidget';
import ClientResultWidget from '../widgets/ClientResultWidget';
import {Col, Row} from 'react-bootstrap';
import Content from '../partials/Content.jsx';

const Clients = () => {
    const [searchString, setSearchString] = useState('');

    return (<Content title="Управление на клиенти">
        <Row>
            <Col>
                <ClientSearchWidget formName="searchClient" onSubmit={({searchString}, {setSubmitting}) => {
                    setSearchString(searchString);
                    setSubmitting(false);
                }} />
                <div className="mb-3 w-100" />
                {searchString.length > 0 &&
                    <ClientResultWidget/>
                }
            </Col>
        </Row>
    </Content>);
};

export default Clients;
