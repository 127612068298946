import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './app/store.js';
import {BrowserRouter} from 'react-router-dom';

import App from './App';

import '@fortawesome/fontawesome-free/css/all.css';
import 'bs-stepper/dist/css/bs-stepper.css';
import './adminlte-override.scss';
import './main.css';

import './theme';

createRoot(document.getElementById('root')).render(<StrictMode>
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
</StrictMode>);
