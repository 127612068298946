import React from 'react';
import {Link} from 'react-router-dom';
import {setForm} from '../../../features/formSlice';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';

const OfferButton = ({
    client,
    vehicle,
    link,
    formName,
    icon,
    product,
}) => {
    const dispatch = useDispatch();

    return (
        <Link to={link} className="btn btn-success text-decoration-none text-nowrap w-100" onClick={() => {
            dispatch(setForm({
                formName: formName,
                values: {
                    plateNumber: vehicle?.plateNumber || '',
                    registrationCertificateNumber: vehicle?.registrationCertificateNumber || '',
                    personalNumber: client?.pin || '',
                },
            }));
        }}>
            <i className={'nav-icon fa-fw fas fa-' + icon} /> Нова оферта {product}
        </Link>
    );
};

OfferButton.propTypes = {
    client: PropTypes.shape({
        personalNumber: PropTypes.string,
    }),
    vehicle: PropTypes.shape({
        plateNumber: PropTypes.string,
        registrationCertificateNumber: PropTypes.string,
    }),
    link: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired,
};

export default OfferButton;
