import React from 'react';
import Alert from '../elements/ReactBootstrap/Alert';
import {getErrorMessage} from '../../utils';
import {useGetCurrentUserQuery} from '../../features/apiSlice';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';

const ErrorWidget = ({
    error,
}) => {
    const {
        data: user,
        isSuccess,
        isLoading,
    } = useGetCurrentUserQuery();

    const message = getErrorMessage(error);

    const incompleteInfoPrefix = 'Непълна информация:';
    if (message !== null && message.indexOf(incompleteInfoPrefix) === 0) {
        const insuranceCompanyName = message.substr(incompleteInfoPrefix.length);

        return (<>
            {isLoading && <SmallSpinner />}
            {isSuccess && <Alert variant="warning" heading={'Непълна информация от ' + insuranceCompanyName}>
                <ul>
                    <li>Вариант 1: ползвайте портала на {insuranceCompanyName}</li>
                    <li>Вариант 2: обадете се в Централния офис на {user.insuranceBroker?.shortName || 'брокера'}</li>
                </ul>
            </Alert>}
        </>);
    }

    return (<Alert variant="danger" heading="Грешка при изпълнението на заявката">
        {message}
    </Alert>);
};

export default ErrorWidget;
