import {Table} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {uabCardTypes} from '../../utils.js';
import UabCardInfoButton from './NavigationButtons/UabCardInfoButton.jsx';
import BsoStatusWidget from "./BsoStatusWidget.jsx";

const UabCardsListWidget = ({
    uabCards,
    actionButton,
}) => {
    return (<div className="table-responsive">
        <Table striped className="mb-0">
            <thead>
                <tr>
                    {actionButton && <th width={72} />}
                    <th>Номер</th>
                    <th>Тип</th>
                    <th>Статус</th>
                    <th>Консултант</th>
                </tr>
            </thead>
            <tbody>
                {uabCards.map(uabCard => <tr key={uabCard.id}>
                    {actionButton && <td>{actionButton(uabCard)}</td>}
                    <td><UabCardInfoButton uabCard={uabCard} /></td>
                    <td>{uabCardTypes[uabCard.type].name}</td>
                    <td><BsoStatusWidget {...uabCard} /></td>
                    <td>{uabCard.user?.shortName}</td>
                </tr>)}
            </tbody>
        </Table>
    </div>);
};

UabCardsListWidget.propTypes = {
    uabCards: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
        user: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }),
        insuranceBroker: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }),
    }).isRequired),
    actionButton: PropTypes.func,
};

export default UabCardsListWidget;
