import React, {useCallback, useState} from 'react';
import LoginView from './views/LoginView';
import ManagerView from './views/ManagerView';
import {Navigate, Route, Routes} from 'react-router-dom';
import Dashboard from './manager/pages/Dashboard.jsx';
import MtplApplication from './manager/pages/MtplNew/MtplApplication.jsx';
import MtplOffer from './manager/pages/MtplNew/MtplOffer.jsx';
import MtplIssue from './manager/pages/MtplNew/MtplIssue.jsx';
import CascoOffer from './manager/pages/CascoOffer.jsx';
import PropertyOffer from './manager/pages/PropertyOffer.jsx';
import TravelOffer from './manager/pages/TravelOffer.jsx';
import CancelTravelOffer from './manager/pages/CancelTravelOffer.jsx';
import InstallmentPaymentFind from './manager/pages/InstallmentPaymentFind.jsx';
import InstallmentPayment from './manager/pages/InstallmentPayment.jsx';
import UabCardIssue from './manager/pages/UabCardIssue.jsx';
import ValuationCertificateIssue from './manager/pages/ValuationCertificateIssue.jsx';
import IssueAnnex from './manager/pages/IssueAnnex.jsx';
import PolicyCancellation from './manager/pages/PolicyCancellation.jsx';
import InsuranceCompanyImport from './manager/pages/InsuranceCompanyImport.jsx';
import CreatePolicy from './manager/pages/CreatePolicy.jsx';
import Policy from './manager/pages/Policy.jsx';
import Client from './manager/pages/Client.jsx';
import Vehicle from './manager/pages/Vehicle.jsx';
import Sticker from './manager/pages/Sticker.jsx';
import GreenCard from './manager/pages/GreenCard.jsx';
import ValuationCertificate from './manager/pages/ValuationCertificate.jsx';
import UabCard from './manager/pages/UabCard.jsx';
import User from './manager/pages/User.jsx';
import Clients from './manager/pages/Clients.jsx';
import Receive from './manager/pages/Receive.jsx';
import Reports from './manager/pages/Reports.jsx';
import InsuranceCompany from './manager/pages/Reports/InsuranceCompany.jsx';
import AgentCommission from './manager/pages/Reports/AgentCommission.jsx';
import Bso from './manager/pages/Bso.jsx';
import Profile from './manager/pages/Profile.jsx';
import Users from './manager/pages/Users.jsx';
import Settings from './manager/pages/Settings.jsx';
import Timeline from './manager/pages/Timeline.jsx';
import Help from './manager/pages/Help.jsx';
import {useGetCurrentUserQuery} from './features/apiSlice.js';
import LoadingView from './views/LoadingView.jsx';
import BsoInventoryWidget from './manager/widgets/Bso/BsoInventoryWidget.jsx';
import BsoSearchWidget from './manager/widgets/Bso/BsoSearchWidget.jsx';
import BsoCreateWidget from './manager/widgets/Bso/BsoCreateWidget.jsx';
import StickerCreateWidget from './manager/widgets/Bso/StickerCreateWidget.jsx';
import GreenCardCreateWidget from './manager/widgets/Bso/GreenCardCreateWidget.jsx';
import ValuationCertificateCreateWidget from './manager/widgets/Bso/ValuationCertificateCreateWidget.jsx';
import UabCardCreateWidget from './manager/widgets/Bso/UabCardCreateWidget.jsx';
import BrokerSettingsWidget from './manager/widgets/Settings/BrokerSettingsWidget.jsx';
import BrokerCommissionSettingsWidget from './manager/widgets/Settings/BrokerCommissionSettingsWidget.jsx';
import InsuranceBrokerCommissionSettingWidget from './manager/widgets/InsuranceBrokerCommissionSettingWidget.jsx';
import Search from './manager/pages/Search';
import Sales from './manager/pages/Reports/Sales.jsx';
import {getToken} from './authStorage.js';
import BsoProtocolsListWidget from './manager/widgets/Bso/Protocol/BsoProtocolsListWidget.jsx';
import BsoProtocolWidget from './manager/widgets/Bso/Protocol/BsoProtocolWidget.jsx';
import BsoProtocolCreateWidget from './manager/widgets/Bso/Protocol/BsoProtocolCreateWidget.jsx';
import BsoProtocol from './manager/pages/BsoProtocol.jsx';

const App = () => {
    const [_, setDummy] = useState();
    const rerender = useCallback(() => setDummy(Math.random()), []);

    const token = getToken();

    const {
        isSuccess,
        isLoading,
        isError,
    } = useGetCurrentUserQuery(undefined, {
        skip: !token,
    });

    if (!token || isError) {
        return (<LoginView onSuccess={() => rerender()} />);
    }

    if (isLoading) {
        return (<LoadingView />);
    }

    return isSuccess && (<Routes>
        <Route path="/" element={<ManagerView />}>
            <Route index element={<Dashboard />} />

            <Route path="mtpl/new">
                <Route index element={<MtplApplication />} />
                <Route path=":action" element={<MtplApplication />} />
                <Route path="application/:applicationId" element={<MtplOffer />} />
                <Route path="offer/:offerId" element={<MtplIssue />} />
            </Route>

            <Route path="installment-payment">
                <Route index element={<InstallmentPaymentFind />} />
                <Route path=":action" element={<InstallmentPaymentFind />} />
                <Route path="policy/:policyId" element={<InstallmentPayment />} />
            </Route>

            <Route path="uab-card">
                <Route path="new" element={<UabCardIssue />} />
                <Route path=":uabCardId" element={<UabCard />} />
            </Route>

            <Route path="valuation-certificate">
                <Route path="new" element={<ValuationCertificateIssue />} />
                <Route path=":valuationCertificateId" element={<ValuationCertificate />} />
            </Route>

            <Route path="policy">
                <Route path="new" element={<CreatePolicy />} />
                <Route path=":policyId" element={<Policy />} />
            </Route>

            <Route path="bso-protocol">
                <Route path=":bsoProtocolId" element={<BsoProtocol />} />
            </Route>

            <Route path="import" element={<InsuranceCompanyImport />} />
            <Route path="receive" element={<Receive />} />

            <Route path="reports">
                <Route index element={<Reports />} />

                <Route path="sales">
                    <Route index element={<Sales />} />
                    <Route path=":action" element={<Sales />} />
                </Route>

                <Route path="insurance-company" element={<InsuranceCompany />} />
            </Route>

            <Route path="bso" element={<Bso />}>
                <Route index element={<Navigate replace to="inventory" />} />
                <Route path="inventory" element={<BsoInventoryWidget />} />

                <Route path="create" element={<BsoCreateWidget />}>
                    <Route path="sticker" element={<StickerCreateWidget />} />
                    <Route path="green-card" element={<GreenCardCreateWidget />} />
                    <Route path="valuation-certificate" element={<ValuationCertificateCreateWidget />} />
                    <Route path="uab-card" element={<UabCardCreateWidget />} />
                </Route>

                <Route path="protocols" element={<BsoProtocolsListWidget />} />

                <Route path="protocol" element={<BsoProtocolWidget />}>
                    <Route path="create" element={<BsoProtocolCreateWidget />} />
                </Route>

                <Route path="search" element={<BsoSearchWidget formName="bsoSearch" onSubmit={() => {}} />} />
                <Route path="update" element={'Промяна на БСО'} />
            </Route>

            <Route path="users">
                <Route index element={<Users />} />
                <Route path="tree" element={<Users treeData={true} />} />
            </Route>

            <Route path="settings" element={<Settings />}>
                <Route index element={<Navigate replace to="broker" />} />
                <Route path="broker" element={<BrokerSettingsWidget />} />

                <Route path="broker-commission">
                    <Route index element={<BrokerCommissionSettingsWidget />} />
                    <Route path=":id" element={<InsuranceBrokerCommissionSettingWidget />} />
                </Route>
            </Route>

            <Route path="help" element={<Help />} />

            <Route path="client/:clientId" element={<Client />} />
            <Route path="vehicle/:vehicleId" element={<Vehicle />} />
            <Route path="sticker/:stickerId" element={<Sticker />} />
            <Route path="green-card/:greenCardId" element={<GreenCard />} />
            <Route path="user/:userId" element={<User />} />

            <Route path="search" element={<Search />} />

            {/* incomplete */}
            <Route path="casco/offer" element={<CascoOffer />} />
            <Route path="property/offer" element={<PropertyOffer />} />
            <Route path="travel/offer" element={<TravelOffer />} />
            <Route path="cancel-travel/offer" element={<CancelTravelOffer />} />
            <Route path="annex/issue" element={<IssueAnnex />} />
            <Route path="policy/void" element={<PolicyCancellation />} />
            <Route path="clients" element={<Clients />} />
            <Route path="reports/agent/commission" element={<AgentCommission />} />
            <Route path="profile" element={<Profile />} />
            <Route path="timeline" element={<Timeline />} />
        </Route>
    </Routes>);
};

export default App;
