import React, {useMemo} from 'react';
import {Field, Form, Formik} from 'formik';
import {Button, Col, Container, FormControl, FormLabel, Row} from 'react-bootstrap';
import useFormPersistedValues from '../../../../hooks/useFormPersistedValues';
import AutoPersistToken from '../../../elements/Formik/AutoPersistToken';
import PropTypes from 'prop-types';
import {format} from 'date-fns';

const DateRangeSelectionWidget = ({
    onSubmit,
}) => {
    const formName = 'salesFormDateRange';
    const initialFormValues = useFormPersistedValues(formName);
    const todayFormatted = useMemo(() => format(new Date(), 'yyyy-MM-dd'), []);

    return (
        <Formik initialValues={initialFormValues || {
            startDateTime: todayFormatted,
            endDateTime: todayFormatted,
        }} onSubmit={onSubmit}>
            {({isSubmitting}) => (
                <Container as={Form} className="gx-0">
                    <Row className="row-cols-1 row-cols-xl-3 g-3 align-items-end">
                        <Col>
                            <FormLabel htmlFor="startDateTime">Начална дата</FormLabel>
                            <FormControl as={Field} type="date" name="startDateTime" id="startDateTime"
                                         max={todayFormatted} disabled={isSubmitting} />
                        </Col>
                        <Col>
                            <FormLabel htmlFor="endDateTime">Крайна дата</FormLabel>
                            <FormControl as={Field} type="date" name="endDateTime" id="endDateTime"
                                         max={todayFormatted} disabled={isSubmitting} />
                        </Col>
                        <Col>
                            <Button variant="success" type="submit" className="w-100" disabled={isSubmitting}>
                                Изготвяне
                            </Button>
                        </Col>
                    </Row>
                    <AutoPersistToken formName={formName}/>
                </Container>
            )}
        </Formik>
    );
};

DateRangeSelectionWidget.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default DateRangeSelectionWidget;
