import React from 'react';
import PremiumAmount from '../elements/Values/PremiumAmount.jsx';
import Percent from '../elements/Values/Percent.jsx';
import {sum} from '../../utils.js';

const AgentCommissionListWidget = ({
    voucher,
}) => {
    const voucherIssueUser = voucher.issueUser;
    const users = [...new Map((voucher.details || []).map(v => [v => v.id, v])).values()].map(detail =>
        (detail.userVoucherDetails || []).map(uvd => uvd.user)
    ).flat();

    return users.map(user => {
        const name = user.id === voucherIssueUser.id ? 'консултант' : user.shortName;
        const uvds = (voucher.details || []).map(detail =>
            (detail.userVoucherDetails || []).filter(uvd => uvd.user.id === user.id)
        ).flat();
        const commissionSum = sum(uvds.map(uvd => voucher.premiumAmount * uvd.commissionCoefficient));

        return (<div key={user.id}>
            Комисион {name}:{' '}
            <PremiumAmount amount={commissionSum} />
            {uvds.map(uvd => <React.Fragment key={uvd.id}>
                {' '}(<Percent coefficient={uvd.commissionCoefficient} />)
            </React.Fragment>)}
        </div>);
    });
};

export default AgentCommissionListWidget;
