import React from 'react';
import {FormLabel} from 'react-bootstrap';
import WeekCodeSelector from './WeekCodeSelector';

const WeekSelector = props => {
    return (<>
        <FormLabel htmlFor="weekCode">Седмица</FormLabel>
        <WeekCodeSelector {...props} />
    </>);
};

export default WeekSelector;
