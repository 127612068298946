import {Table} from 'react-bootstrap';
import PropTypes from 'prop-types';
import PolicyInfoButton from './NavigationButtons/PolicyInfoButton';
import VehicleInfoButton from './NavigationButtons/VehicleInfoButton';
import ClientInfoButton from './NavigationButtons/ClientInfoButton';
import {isPast} from 'date-fns';
import React, {useCallback} from 'react';
import DateShort from '../elements/DateTime/DateShort.jsx';
import {formatDayDiff} from '../../utils.js';

const PoliciesListWidget = ({
    policies,
    showClient,
    showVehicle,
}) => {
    const isExpired = useCallback(policy => policy.endDate && isPast(new Date(policy.endDate)), []);

    const nextPayment = useCallback(policy => policy.installments.filter(installment =>
        !installment.paidPremiumFromClient).shift(), []);

    return (<div className="table-responsive">
        <Table striped className="mb-0">
            <thead>
                <tr>
                    <th>Номер</th>
                    <th>Продукт</th>
                    <th>ЗК</th>
                    <th>Консултант</th>
                    {showClient && <th>Клиент</th>}
                    {showVehicle && <th>МПС</th>}
                    <th>Следващо плащане</th>
                    <th>Покритие</th>
                </tr>
            </thead>
            <tbody>
                {policies.map(policy => {
                    const dueDate = nextPayment(policy)?.dueDate;
                    const policyEndDate = policy?.endDate;
                    const nextPaymentDate = dueDate || policyEndDate;
                    const isRenew = !dueDate && policyEndDate;

                    return (<tr key={policy.id}
                            className={isExpired(policy) ? 'progress-bar-striped grid-row-secondary' : null}>
                        <td><PolicyInfoButton policy={policy} /></td>
                        <td>{policy.productName}</td>
                        <td>{policy.insuranceCompany?.shortName}</td>
                        <td>{policy.issueUser?.shortName}</td>
                        {showClient && <td><ClientInfoButton client={policy.policyHolder} textField="name" /></td>}
                        {showVehicle && <td><VehicleInfoButton vehicle={policy.insuredVehicle} textField="plateNumber"
                            /></td>}
                        <td>
                            {!nextPaymentDate && 'няма'}
                            <DateShort date={nextPaymentDate} />
                            <br/>
                            <small>{isRenew ? 'подновяване' : 'вноска'} {formatDayDiff(nextPaymentDate)}</small>
                        </td>
                        <td>
                            <DateShort date={policy.beginDate}/> - <DateShort date={policy.endDate}/>
                        </td>
                    </tr>);
                })}
            </tbody>
        </Table>
    </div>);
};

PoliciesListWidget.defaultProps = {
    showClient: true,
    showVehicle: true,
};

PoliciesListWidget.propTypes = {
    policies: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
        productName: PropTypes.string,
        issueUser: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }),
        insuredClients: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            pin: PropTypes.string.isRequired,
        }))),
        insuredVehicle: PropTypes.shape({
            id: PropTypes.string.isRequired,
            plateNumber: PropTypes.string.isRequired,
        }),
    }).isRequired),
    showClient: PropTypes.bool,
    showVehicle: PropTypes.bool,
};

export default PoliciesListWidget;
