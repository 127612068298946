import React from 'react';
import {Col, Container, FormControl, FormLabel, Row} from 'react-bootstrap';
import {Field, Form, Formik} from 'formik';
import useFormPersistedValues from '../../../hooks/useFormPersistedValues';
import AutoPersistToken from '../../elements/Formik/AutoPersistToken';
import InsuranceCompanySelector from '../../elements/Form/InsuranceCompanySelector';
import BsoSaveWidget from './BsoSaveWidget';
import ErrorWidget from '../ErrorWidget';
import {useCreateGreenCardsMutation} from '../../../features/apiSlice';
import Alert from '../../elements/ReactBootstrap/Alert';
import Step from "../../elements/BsStepper/Step.jsx";

const GreenCardCreateWidget = () => {
    const [create, {
        data: greenCards,
        error,
        isError,
    }] = useCreateGreenCardsMutation();

    const formName = 'greenCardCreateForm';
    const formInitialValues = useFormPersistedValues(formName);

    return (<Formik
        initialValues={formInitialValues || {
            insuranceCompany: '',
            series: '',
            startNumber: '',
            endNumber: '',
        }}
        onSubmit={values => {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm('Потвърдете регистрацията на зелени карти')) {
                return Promise.reject(new Error('Регистрацията беше отказана'));
            }

            return create(values);
        }}
    >
        {({isSubmitting}) => (<Container fluid as={Form} className="gx-0">
            <Row className="row-cols-1 g-3">
                <Col>
                    <Step number="2" label="Въведете информация"/>
                </Col>
                <Col lg={6}>
                    <InsuranceCompanySelector required={true} mtplOnly={true} />
                </Col>
                <div className="w-100 gy-0" />
                <Col lg={6}>
                    <FormLabel htmlFor="startNumber">Серия</FormLabel>
                    <FormControl as={Field} type="text" name="series"
                                 id="series" required pattern="[A-Z]{2}"
                                 disabled={isSubmitting} maxLength={2} />
                </Col>
                <div className="w-100 gy-0" />
                <Col lg={6}>
                    <FormLabel htmlFor="startNumber">Първи СЗК</FormLabel>
                    <FormControl as={Field} type="text" name="startNumber" autoComplete="off"
                                 id="startNumber" required pattern="\d{6}"
                                 disabled={isSubmitting} maxLength={6} inputMode="decimal"/>
                </Col>
                <div className="w-100 gy-0" />
                <Col lg={6}>
                    <FormLabel htmlFor="endNumber">Последен СЗК</FormLabel>
                    <FormControl as={Field} type="text" name="endNumber" autoComplete="off"
                                 id="endNumber" required pattern="\d{6}"
                                 disabled={isSubmitting} maxLength={6} inputMode="decimal"/>
                </Col>
                <BsoSaveWidget type="СЗК" />
                <div className="w-100 gy-0" />
                {greenCards && <Col lg={6}>
                    <Alert variant="success" heading={<>Успешно записахте {greenCards.length} зелени карти!</>} />
                </Col>}
                {isError && <Col lg={6}>
                    <ErrorWidget error={error} />
                </Col>}
            </Row>
            <AutoPersistToken formName={formName}/>
        </Container>)}
    </Formik>);
};

export default GreenCardCreateWidget;
