import React from 'react';
import PropTypes from 'prop-types';
import {useReportSalesRankingQuery} from '../../../../features/apiSlice.js';
import ErrorIcon from '../../../elements/Icons/ErrorIcon.jsx';
import {Table} from 'react-bootstrap';
import SmallSpinner from '../../../elements/Spinner/SmallSpinner.jsx';

const RankingTable = ({
    startDateTime,
    endDateTime,
}) => {
    const {
        data,
        error,
        isLoading,
        isSuccess,
        isFetching,
        isError,
    } = useReportSalesRankingQuery({
        startDateTime,
        endDateTime,
    });

    return (
        <>
            {isLoading && <div className="m-3"><SmallSpinner /></div>}
            {isSuccess && <div className={'table-responsive' + (isFetching ? ' opacity-50 pe-none user-select-none' : '')}>
                <Table striped className="mb-0">
                    <tbody>{data.map((user, index)  =>
                        <tr key={user.id}>
                            <td>
                                {index + 1}. {user.shortName} ({user.office.name})
                            </td>
                        </tr>
                    )}</tbody>
                </Table>
            </div>}
            {isError && <ErrorIcon error={error} />}
        </>
    );
};

RankingTable.propTypes = {
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
};

export default RankingTable;
