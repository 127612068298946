import {format} from "date-fns";

const DateTimeShort = ({
    date,
}) => {
    if (date === undefined) {
        return '';
    }

    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    return format(date, 'dd.MM.yyyy HH:mm:ss');
};

export default DateTimeShort;
