import {Button} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';

const ButtonLink = ({
    to,
    icon,
    children,

    ...props
}) => {
    return (
        <Button as={NavLink} variant="app" to={to} {...props}>
            {icon && <i className={icon}/>}
            {children}
        </Button>
    );
};

ButtonLink.propTypes = {
    to: PropTypes.string.isRequired,
    icon: PropTypes.string,
};

export default ButtonLink;
