import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import DailySalesSummaryWidget from './DailySalesSummaryWidget';
import WeeklySalesSummaryWidget from './WeeklySalesSummaryWidget';
import {subDays, subWeeks} from 'date-fns';
import RequireNonProdEnv from '../../../elements/AccessControl/RequireNonProdEnv';

const SalesSummaryWidget = () => {
    const today = new Date();
    const yesterday = subDays(today, 1);
    const lastWeek = subWeeks(today, 1);

    return (
        <Container className="gx-0">
            <Row className="row-cols-2 row-cols-md-4 text-center">
                <Col as="dl">
                    <dt>Днес</dt>
                    <dd><DailySalesSummaryWidget date={today} pollingInterval={60000} /></dd>
                </Col>
                <Col as="dl">
                    <dt>Вчера</dt>
                    <dd><DailySalesSummaryWidget date={yesterday} /></dd>
                </Col>
                <Col as="dl">
                    <dt>Тази седмица</dt>
                    <dd><WeeklySalesSummaryWidget date={today} pollingInterval={60000} /></dd>
                </Col>
                <Col as="dl">
                    <dt>Миналата седмица</dt>
                    <dd><WeeklySalesSummaryWidget date={lastWeek} /></dd>
                </Col>
            </Row>
            <Row className="row-cols-1 row-cols-sm-2 g-3">
                <Col>
                    <Link to="/reports/sales" className="btn btn-success text-decoration-none w-100">
                        Справка продажби
                    </Link>
                </Col>
                <RequireNonProdEnv>
                    <Col>
                        <Link to="/reports/agent/commission" className="btn btn-outline-secondary text-decoration-none w-100">
                            Справка комисион
                        </Link>
                    </Col>
                </RequireNonProdEnv>
            </Row>
        </Container>
    );
};

export default SalesSummaryWidget;
