import React from 'react';
import Card from '../elements/ReactBootstrap/Card';
import {Col, Row} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import ErrorWidget from '../widgets/ErrorWidget';
import {useGetValuationCertificateQuery} from '../../features/apiSlice';
import ValuationCertificateInfoWidget from '../widgets/ValuationCertificateInfoWidget';
import Content from '../partials/Content.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';

const ValuationCertificate = () => {
    const {valuationCertificateId} = useParams();

    const {
        data: valuationCertificate,
        error,
        isLoading,
        isSuccess,
        isError,
    } = useGetValuationCertificateQuery(valuationCertificateId);

    return (<Content title="Информация за УЗС">
        <Row className="row-cols-1">
            <Col>
                {isLoading && <Card>Зареждане на информация за УЗС... <SmallSpinner /></Card>}
                {isError && <ErrorWidget error={error} />}
                {isSuccess && <Card>
                    <ValuationCertificateInfoWidget valuationCertificate={valuationCertificate} />
                </Card>}
            </Col>
        </Row>
    </Content>);
};

export default ValuationCertificate;
