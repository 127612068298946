import React from 'react';

const FieldError = ({
    children,
}) => {
    return (
        <span className="invalid-feedback d-block">{children}</span>
    );
};

export default FieldError;
