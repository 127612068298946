import React from 'react';
import Card from '../elements/ReactBootstrap/Card';
import ProfileSettingsWidget from '../widgets/ProfileSettingsWidget';
import {Col, ListGroup, Row} from 'react-bootstrap';
import {useGetCurrentUserQuery} from '../../features/apiSlice';
import Content from '../partials/Content.jsx';
import UserAvatar from '../elements/UserAvatar';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';

const Profile = () => {
    const {
        data: user,
        isSuccess,
        isLoading,
    } = useGetCurrentUserQuery();

    return (<Content title="Управление на профила">
        <Row>
            <Col sm={3}>
                <Card outline>
                    {isLoading && <SmallSpinner />}
                    {isSuccess && <>
                    <div className="text-center">
                        <UserAvatar user={user} size={88} fluid className="profile-user-img" />
                    </div>
                    <h3 className="profile-username text-center">{user.fullName}</h3>
                    <p className="text-muted text-center">Консултант продажби, ниво 4</p>
                    <ListGroup as="ul" className="list-group-unbordered">
                        <ListGroup.Item as="li">
                            <b>Email</b>
                            <span className="float-end">{user.email}</span>
                        </ListGroup.Item>
                        <ListGroup.Item as="li">
                            <b>Офис</b>
                            <span className="float-end">{user.office.name}</span>
                        </ListGroup.Item>
                        <ListGroup.Item as="li">
                            <b>Брокер</b>
                            <span className="float-end">{user.insuranceBroker.name}</span>
                        </ListGroup.Item>
                    </ListGroup>
                    </>}
                </Card>
            </Col>
            <Col md>
                <Card outline>
                    <ProfileSettingsWidget formName="profileSettings" onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(false);
                    }} />
                </Card>
            </Col>
        </Row>
    </Content>);
};

export default Profile;
