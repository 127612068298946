import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../elements/ReactBootstrap/Alert';
import PremiumAmount from "../elements/Values/PremiumAmount";

const SuccessfulPaymentWidget = ({
    amount,
}) => {
    return (<Alert variant="success" heading={<>Плащането за <PremiumAmount amount={amount} /> е успешно!</>} />);
};

SuccessfulPaymentWidget.propTypes = {
    amount: PropTypes.number.isRequired,
};

export default SuccessfulPaymentWidget;
