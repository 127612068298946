import React, {useState} from 'react';
import Card from '../elements/ReactBootstrap/Card';
import PolicySearchWidget from '../widgets/PolicySearchWidget';
import {Button, Col, Row} from 'react-bootstrap';
import Content from '../partials/Content.jsx';

const IssueAnnex = () => {
    const [policyNumber, setPolicyNumber] = useState('aaa');

    return (<Content title="Издаване на анекс към полица">
        <Row>
            <Col lg={6}>
                <Card outline>
                    <PolicySearchWidget formName="policySearchAnnex" findButtonLabel="Покажи възможни анекси"
                        onSubmit={({policyNumber}, {setSubmitting}) => {
                            setPolicyNumber(policyNumber);
                            setSubmitting(false);
                        }} />
                </Card>
                {policyNumber.length > 0 &&
                <Card>
                    <Button variant="app">
                        <i className="fas fa-file text-warning"/>{' '}
                        Анекс за ново СРМПС
                    </Button>
                    {policyNumber.match(/^BG\/\d{2}\/1\d{11}$/) && <>
                        <Button variant="app">
                            <i className="fas fa-sticky-note"/>{' '}
                            Анекс за стикер
                        </Button>
                        <Button variant="app">
                            <i className="fas fa-money-check text-success"/>{' '}
                            Анекс за СЗК
                        </Button>
                    </>}
                </Card>
                }
            </Col>
            {/*policyNumber.length > 0 &&
            <div className="col-lg-6">
                <PolicyInfoWidget/>
            </div>*/}
        </Row>
    </Content>);
};

export default IssueAnnex;
