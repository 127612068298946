import React from 'react';
import Alert from '../../elements/ReactBootstrap/Alert';

const NoGreenCardWarning = () => {
    return (
        <Alert variant="warning" heading="Не е издадена Зелена карта">
            Избраната зелена карта вече е използвана, но <strong>вноската е платена успешно</strong>! Ако клиентът желае
            СЗК, моля издайте го като анекс през портала на застрахователната компания на нова бланка, и не забравяйте
            да върнете използваната в централния офис, при отчитане на плащанията за седмицата!
        </Alert>
    );
};

export default NoGreenCardWarning;
