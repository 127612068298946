import React from 'react';
import Content from '../partials/Content.jsx';

const CancelTravelOffer = () => {
    return (<Content title="Оферта за нова полица Отмяна на пътуване">
        <div>Очаквайте скоро!</div>
    </Content>);
};

export default CancelTravelOffer;
