import React from 'react';
import PremiumButton from './PremiumButton';
import {offerSelector, offersProgressSelector, useCreateOffersQuery} from '../../../features/apiSlice';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {installmentColors} from '../../../config';
import ErrorIcon from '../../elements/Icons/ErrorIcon';
import useCurrentUserHasRole from '../../../hooks/useCurrentUserHasRole.js';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';

const MtplPremium = ({
    insuranceCompany,
    installmentsCount,
}) => {
    const {
        applicationId,
    } = useParams();

    const {
        error,
        isError,
        isLoading,
    } = useCreateOffersQuery({
        application: applicationId,
        insuranceCompany: insuranceCompany.id,
        installmentsCount,
    }, {
        skip: applicationId === undefined,
    });

    const offer = useSelector(offerSelector(applicationId, insuranceCompany.id, installmentsCount));
    const progress = useSelector(offersProgressSelector(applicationId));
    const allowOverride = useCurrentUserHasRole('ROLE_QUICK_OFFER');
    const disabled = progress < 1 && !allowOverride;

    if (!offer) {
        if (isLoading) {
            return <SmallSpinner />;
        }

        if (isError) {
            return <ErrorIcon error={error} />;
        }

        return null;
    }

    return <PremiumButton variant={'outline-' + installmentColors[installmentsCount]} offer={offer}
        disabled={disabled} />;
};

export default MtplPremium;
