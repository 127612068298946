import React, {useState} from 'react';
import Card from '../elements/ReactBootstrap/Card';
import {Col, Row} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import ErrorWidget from '../widgets/ErrorWidget';
import {useGetGreenCardQuery} from '../../features/apiSlice';
import GreenCardInfoWidget from '../widgets/GreenCardInfoWidget';
import VouchersListWidget from '../widgets/VouchersListWidget';
import InstallmentsListWidget from '../widgets/InstallmentsListWidget';
import Content from '../partials/Content.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';

const GreenCard = () => {
    const {greenCardId} = useParams();
    const [printing, setPrinting] = useState(false);

    const {
        data: greenCard,
        error,
        isLoading,
        isSuccess,
        isError,
    } = useGetGreenCardQuery(greenCardId);

    return (<Content title="Информация за СЗК">
        <Row className="row-cols-1">
            <Col>
                {isLoading && <Card>Зареждане на информация за СЗК... <SmallSpinner /></Card>}
                {isError && <ErrorWidget error={error} />}
                {isSuccess && <Card>
                    <GreenCardInfoWidget greenCard={greenCard} printing={printing} setPrinting={setPrinting} />
                </Card>}
            </Col>
            {isSuccess && greenCard.installments && greenCard.installments.length > 0 && <Col>
                <Card noPadding header="Издадена по следните вноски">
                    <InstallmentsListWidget product={{
                        hasMtplCover: true,
                        hasPassengersAccidentCover: false,
                    }} installments={greenCard.installments} readOnly detailed />
                </Card>
            </Col>}
            {isSuccess && greenCard.voucher && <Col>
                <Card noPadding header="Издадена квитанция с тази зелена карта">
                    <VouchersListWidget policy={greenCard.installments?.[0]?.policy} vouchers={[greenCard.voucher]}
                        printing={printing} setPrinting={setPrinting} />
                </Card>
            </Col>}
        </Row>
    </Content>);
};

export default GreenCard;
