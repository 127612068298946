import Alert from '../../elements/ReactBootstrap/Alert';
import {Col, Row} from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import StickerInfoButton from "../NavigationButtons/StickerInfoButton";
import GreenCardInfoButton from "../NavigationButtons/GreenCardInfoButton";

const SelectedBso = ({
    voucher,
}) => {
    if (!voucher.sticker && !voucher.greenCard) {
        return null;
    }

    return (
        <Alert variant="secondary" heading="Избрани БСО">
            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-1 row-cols-xl-2">
                {voucher.sticker && <Col>Стикер: <StickerInfoButton sticker={voucher.sticker} /></Col>}
                {voucher.greenCard && <Col>Зелена карта: <GreenCardInfoButton greenCard={voucher.greenCard} /></Col>}
            </Row>
        </Alert>
    );
};

SelectedBso.propTypes = {
    voucher: PropTypes.shape({
        sticker: PropTypes.shape({
            number: PropTypes.number.isRequired,
        }),
        greenCard: PropTypes.shape({
            series: PropTypes.string.isRequired,
            number: PropTypes.number.isRequired,
        }),
    }).isRequired,
};

export default SelectedBso;
