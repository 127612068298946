import React from 'react';
import PropTypes from 'prop-types';
import NavigationButton from './NavigationButton';

const ApplicationInfoButton = ({
    application,
    textField,
}) => {
    if (!application) {
        return null;
    }

    const text = textField && !!application[textField] ? application[textField] : application.plateNumber;

    return (<NavigationButton link={'/' + application.product.insuranceType + '/new/application/' + application.id} copyText={text}>
        {text}
    </NavigationButton>);
};

ApplicationInfoButton.propTypes = {
    application: PropTypes.shape({
        id: PropTypes.string.isRequired,
        product: PropTypes.shape({
            insuranceType: PropTypes.string.isRequired,
        }).isRequired,
        plateNumber: PropTypes.string.isRequired,
    }),
    textField: PropTypes.string,
};

export default ApplicationInfoButton;
