import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import {kwToHp} from '../../utils.js';
import TextWithCopyButton from './TextWithCopyButton.jsx';

const VehicleInfoWidget = ({
    vehicle,
}) => {
    return (<>
        <Row as="dl" className="mb-0">
            {vehicle.plateNumber && <>
                <Col sm={4} as="dt">ДКН (A)</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={vehicle.plateNumber} />
                </Col>
            </>}
            {vehicle.vin && <>
                <Col sm={4} as="dt">VIN/шаси (E)</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={vehicle.vin} />
                </Col>
            </>}
            {vehicle.vehicleClass && <>
                <Col sm={4} as="dt">Категория МПС (J)</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={vehicle.vehicleClass} />
                </Col>
            </>}
            {(vehicle.brandName || vehicle.modelName) && <>
                <Col sm={4} as="dt">Марка и модел (D.1)</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={vehicle.brandName} />{' '}
                    <TextWithCopyButton text={vehicle.modelName} />
                </Col>
            </>}
            {!!vehicle.maxWeightF1 && <>
                <Col sm={4} as="dt">Максимално натоварване (F1)</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={vehicle.maxWeightF1.toString(10)} /> кг.
                </Col>
            </>}
            {!!vehicle.maxWeightF3 && <>
                <Col sm={4} as="dt">Максимално натоварване (F3)</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={vehicle.maxWeightF3.toString(10)} /> кг.
                </Col>
            </>}
            {!!vehicle.vehicleWeightG && <>
                <Col sm={4} as="dt">Маса на МПС (G)</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={vehicle.vehicleWeightG.toString(10)} /> кг.
                </Col>
            </>}
            {!!vehicle.engineCapacity && <>
                <Col sm={4} as="dt">Обем на двигателя (P.1)</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={vehicle.engineCapacity.toString(10)} /> см<sup>3</sup>
                </Col>
            </>}
            {!!vehicle.maxPowerKw && <>
                <Col sm={4} as="dt">Мощност на двигателя (P.2)</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={vehicle.maxPowerKw.toString(10)} /> kW ={' '}
                    <TextWithCopyButton text={Math.round(kwToHp(vehicle.maxPowerKw)).toString(10)} /> к.с.
                </Col>
            </>}
            {(!!vehicle.seatsCount || vehicle.seatsCountText) && <>
                <Col sm={4} as="dt">Брой места (S.1)</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={vehicle.seatsCountText || vehicle.seatsCount.toString(10)} />
                </Col>
            </>}
            {vehicle.registrationCertificateNumber && <>
                <Col sm={4} as="dt">Номер на СРМПС</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={vehicle.registrationCertificateNumber} />
                </Col>
            </>}
        </Row>
    </>);
};

VehicleInfoWidget.propTypes = {
    vehicle: PropTypes.shape({
        vin: PropTypes.string.isRequired,
        plateNumber: PropTypes.string,
        vehicleClass: PropTypes.string,
        brandName: PropTypes.string,
        modelName: PropTypes.string,
        maxWeightF1: PropTypes.number,
        maxWeightF3: PropTypes.number,
        vehicleWeightG: PropTypes.number,
        engineCapacity: PropTypes.number,
        maxPowerKw: PropTypes.number,
        seatsCount: PropTypes.number,
        seatsCountText: PropTypes.string,
        registrationCertificateNumber: PropTypes.string,
    }).isRequired,
};

export default VehicleInfoWidget;
