import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Button, Table} from 'react-bootstrap';
import {useListInsuranceCompaniesQuery} from '../../features/apiSlice';
import Percent from '../elements/Values/Percent';
import {Link} from 'react-router-dom';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';

const InsuranceBrokerCommissionSettingsInfoWidget = ({
    commissionSettings,
}) => {
    const {
        data: insuranceCompanies,
        isSuccess,
        isLoading,
    } = useListInsuranceCompaniesQuery();

    const products = useMemo(() => commissionSettings
            .map(commissionSetting => commissionSetting.product)
            .filter((v, i, a) => a.map(product => product.id).indexOf(v.id) === i)
            .sort((a, b) => a.id.localeCompare(b.id)),
        [commissionSettings],
    );

    return (<>
        <div>
            Комисионите са в проценти, както следва:
            <ul>
                <li>наша</li>
                <li>чужда</li>
                <li>наша, платена другаде</li>
            </ul>
        </div>
        {isLoading && <SmallSpinner />}
        {isSuccess && <div className="table-responsive">
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Продукт</th>
                        {insuranceCompanies.map(insuranceCompany =>
                            <th key={insuranceCompany.id}>{insuranceCompany.shortName}</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {products.map(product => {
                        return (<tr key={product.id}>
                            <th title={product.name}>{product?.shortName || product.name}</th>
                            {insuranceCompanies.map(insuranceCompany => {
                                const currentCommissionSettings = commissionSettings.filter(commissionSetting =>
                                    commissionSetting.insuranceCompany.id === insuranceCompany.id &&
                                    commissionSetting.product.id === product.id
                                );

                                return (<td key={insuranceCompany.id}>
                                    {currentCommissionSettings.map(commissionSetting => <Button as={Link}
                                        key={commissionSetting.id} variant="outline-success" className="text-success"
                                        to={'/settings/broker-commission/' + commissionSetting.id}>
                                        <Percent coefficient={
                                            commissionSetting.policyCoefficient +
                                            commissionSetting.voucherCoefficient
                                        } showPercentSign={false} />/
                                        <Percent coefficient={commissionSetting.voucherCoefficient}
                                            showPercentSign={false} />/
                                        <Percent coefficient={commissionSetting.independentPolicyCoefficient ?
                                            commissionSetting.policyCoefficient : 0.0
                                        } showPercentSign={false} />
                                    </Button>)}
                                </td>);
                            })}
                        </tr>);
                    })}
                </tbody>
            </Table>
        </div>}
    </>);
};

InsuranceBrokerCommissionSettingsInfoWidget.propTypes = {
    commissionSettings: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        insuranceCompany: PropTypes.shape({
            id: PropTypes.string.isRequired,
            shortName: PropTypes.string.isRequired,
        }).isRequired,
        product: PropTypes.shape({
            insuranceType: PropTypes.string.isRequired,
        }).isRequired,
        policyCoefficient: PropTypes.number.isRequired,
        voucherCoefficient: PropTypes.number.isRequired,
        independentPolicyCoefficient: PropTypes.bool.isRequired,
    }).isRequired),
};

export default InsuranceBrokerCommissionSettingsInfoWidget;
