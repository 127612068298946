import PropTypes from 'prop-types';
import useCurrentUserHasRole from '../../../hooks/useCurrentUserHasRole';

const RequireRole = ({
    children,

    name,
}) => {
    const hasRole = useCurrentUserHasRole(name);
    return hasRole ? children : null;
};

RequireRole.propTypes = {
    name: PropTypes.string.isRequired,
};

export default RequireRole;
