import {useReportSalesSummaryQuery} from '../../../../features/apiSlice';
import SalesReportValue from '../../../elements/Values/SalesReportValue';
import React from 'react';
import PropTypes from 'prop-types';
import {formatWeekCode} from '../../../../utils';
import ErrorIcon from '../../../elements/Icons/ErrorIcon';
import SmallSpinner from '../../../elements/Spinner/SmallSpinner.jsx';

const WeeklySalesSummaryWidget = ({
    date,
    pollingInterval,
}) => {
    const {
        data,
        error,
        isLoading,
        isSuccess,
        isError,
    } = useReportSalesSummaryQuery({
        weekCode: formatWeekCode(date),
    }, {
        pollingInterval,
    });

    return (<>
        {isLoading && <SmallSpinner />}
        {isSuccess && <SalesReportValue {...data} />}
        {isError && <ErrorIcon error={error} />}
    </>);
};

WeeklySalesSummaryWidget.defaultProps = {
    pollingInterval: 0,
};

WeeklySalesSummaryWidget.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    pollingInterval: PropTypes.number,
};

export default WeeklySalesSummaryWidget;
