import React from 'react';
import PropTypes from 'prop-types';
import SelectField from './SelectField';
import {Form, InputGroup} from 'react-bootstrap';

const IconSelectField = ({
    iconWidth,
    icon,
    label,

    ...props
}) => {
    const styles = {
        container: styles => {
            if (iconWidth) {
                styles.width = 'calc(100% - ' + iconWidth + 'px)';
            }

            return styles;
        },
        control: styles => {
            if (icon) {
                styles.borderRadius = '0 4px 4px 0';
            }

            return styles;
        },
    };

    return <>
        {label && <Form.Label>{label}</Form.Label>}
        <InputGroup>
            {icon && <InputGroup.Text>
                <i className={'fas fa-' + icon} />
            </InputGroup.Text>}
            <SelectField styles={styles} {...props} />
        </InputGroup>
    </>;
};

IconSelectField.propTypes = {
    label: PropTypes.node,
    icon: PropTypes.string,
    iconWidth: PropTypes.number,
};

export default IconSelectField;
