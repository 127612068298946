import React, {useCallback, useState} from 'react';
import {Table} from 'react-bootstrap';
import PremiumAmount from '../../elements/Values/PremiumAmount';
import {format} from 'date-fns';
import DateShort from '../../elements/DateTime/DateShort';
import PremiumAmountSum from '../../elements/Values/PremiumAmountSum';
import PropTypes from 'prop-types';
import Card from '../../elements/ReactBootstrap/Card';
import PolicyPrintButton from '../../elements/PrintButtons/PolicyPrintButton';
import VoucherPrintButton from '../../elements/PrintButtons/VoucherPrintButton';
import GreenCardPrintButton from '../../elements/PrintButtons/GreenCardPrintButton';
import InfoIcon from '../../elements/Icons/InfoIcon';
import DetailsIcon from '../../elements/Icons/DetailsIcon';
import PolicyInfoButton from '../NavigationButtons/PolicyInfoButton';
import StickerInfoButton from '../NavigationButtons/StickerInfoButton';
import GreenCardInfoButton from '../NavigationButtons/GreenCardInfoButton';
import PaymentsCount from '../../elements/Values/PaymentsCount';
import RequireRole from '../../elements/AccessControl/RequireRole';
import {paymentMethodsShort, sum} from '../../../utils';
import {useGetCurrentUserQuery, useSendVoucherEmailMutation} from '../../../features/apiSlice';
import VoucherEmailButton from '../../elements/Buttons/VoucherEmailButton.jsx';
import useBrokerVoucherCommission from '../../../hooks/useBrokerVoucherCommission.js';
import Percent from '../../elements/Values/Percent.jsx';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';

const ReportTableWidget = ({
    data,
    group,
    headerText,
    isLoading,
    actionButton,
    showTable,
    detailed,
}) => {
    const [printing, setPrinting] = useState(false);
    const [sending, setSending] = useState(false);

    const getFirstInstallment = useCallback(voucher => voucher.installments.concat().shift(), []);
    const getPolicy = useCallback(voucher => getFirstInstallment(voucher).policy, [getFirstInstallment]);
    const getInstallmentNumbers = useCallback(
        voucher => voucher.installments.map(installment => installment.number).join(','),
        []
    );
    const getCommissionUser = useCallback(
        voucher => voucher.commissionUser ? <>
                {' '}<InfoIcon
                    onClick={() => alert('Комисион се изплаща на консултант:\n' + voucher.commissionUser.shortName)} />
            </> : null,
        []
    );

    const {
        data: currentUser,
    } = useGetCurrentUserQuery();

    const { getBrokerVoucherCommissionCoefficient, getBrokerVoucherCommissionAmount } = useBrokerVoucherCommission(currentUser);

    const [send] = useSendVoucherEmailMutation();

    if (data.length === 0) {
        return <Card>
            Няма данни за избрания период. {isLoading && <SmallSpinner />}
        </Card>;
    }

    const showUser = group !== 'user';
    const showOffice = ['office', 'user'].indexOf(group) === -1;
    const showInsuranceCompany = group !== 'insuranceCompany';

    let tableData = {
        categories: {},
        headerFunction: () => headerText,
        rows: {},
    };

    if (group === 'user') {
        tableData.headerFunction = key => {
            const category = tableData.categories[key];

            if (!category) {
                return '[не е избран консултант]';
            }

            return tableData.categories[key].shortName + (tableData.categories[key].office ?
                (' (офис ' + tableData.categories[key].office.name + ')') :
                ''
            );
        };

        data.forEach(row => {
            const user = row.issueUser;
            const key = user?.id || '';

            tableData.categories[key] = user;
            tableData.rows[key] = [
                ...(tableData.rows[key] || []),
                row,
            ];
        });
    }
    else {
        if (group === 'office') {
            tableData.headerFunction = key => {
                const category = tableData.categories[key];

                if (!category) {
                    return '[не е избран офис]';
                }

                return 'Офис ' + category.name;
            };

            data.forEach(row => {
                const office = row.issueOffice;
                const key = office?.id || '';

                tableData.categories[key] = office;
                tableData.rows[key] = [
                    ...(tableData.rows[key] || []),
                    row,
                ];
            });
        }
        else {
            if (group === 'insuranceCompany') {
                tableData.headerFunction = key => tableData.categories[key].shortName;

                data.forEach(row => {
                    const insuranceCompany = getPolicy(row).insuranceCompany;
                    const key = insuranceCompany.id;

                    tableData.categories[key] = insuranceCompany;
                    tableData.rows[key] = [
                        ...(tableData.rows[key] || []),
                        row,
                    ];
                });
            }
            else {
                tableData.categories = {
                    _default: {
                        name: 'Резултати от търсенето',
                    },
                };
                tableData.rows = {
                    _default: data,
                };
            }
        }
    }

    return Object.entries(tableData.rows).sort(([key1], [key2]) => {
        const label1 = tableData.headerFunction(key1).toLowerCase();
        const label2 = tableData.headerFunction(key2).toLowerCase();
        return label1.localeCompare(label2);
    }).map(([key, data]) => {
        const nonVoidedVouchers = data.filter(voucher => !voucher.void);
        const commissionAmountSum = sum(nonVoidedVouchers.map(voucher =>
            getBrokerVoucherCommissionCoefficient(voucher, getPolicy(voucher))
        ));

        return (<Card outline key={key} className="p-0" header={<>
            {tableData.headerFunction(key)} - {' '}
            <PaymentsCount count={nonVoidedVouchers.length} /> - {' '}
            <PremiumAmountSum arr={nonVoidedVouchers} field="totalAmount" />
            <RequireRole name="ROLE_SHOW_COMMISSION">
                {' '}с комисион <PremiumAmount amount={commissionAmountSum} />
            </RequireRole>
            {isLoading && <SmallSpinner />}
        </>}>
            {showTable && <div className="table-responsive">
                <Table striped className="table-sm mb-0">
                    <thead>
                        <tr>
                            {actionButton && <th />}
                            <th>Седм.</th>
                            {showUser && <th>Консултант</th>}
                            {showOffice && <th>Офис</th>}
                            {showInsuranceCompany && <th>ЗК</th>}
                            <th>Продукт</th>
                            <th>Вноска</th>
                            <th>Полица</th>
                            <th>Стикер</th>
                            <th>СЗК</th>
                            {detailed && <>
                                <th className="text-end">Премия</th>
                                <th className="text-end">Данък</th>
                                <th className="text-end">ГФ</th>
                                <th className="text-end">Стикер</th>
                            </>}
                            <th className="text-end">Общо</th>
                            {detailed && <>
                                <RequireRole name="ROLE_SHOW_COMMISSION">
                                    <th className="text-end">Ком.{'\u00A0'}%</th>
                                    <th className="text-end">Ком.{'\u00A0'}лв.</th>
                                </RequireRole>
                                <th>Падеж</th>
                            </>}
                            <th>Платено</th>
                            <th>Начин</th>
                            <th>Отчетено</th>
                            <th>Полица</th>
                            <th>Сметка</th>
                            <th>СЗК</th>
                            <th>Мейл</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(row => <tr key={row.id}
                                                    className={row.void ? 'progress-bar-striped grid-row-danger' : null}>
                            {actionButton && <td>{actionButton(row)}</td>}
                            <td>{format(new Date(row.issueDate), 'II')}</td>
                            {showUser && <td>{row.issueUser?.shortName || ''}{getCommissionUser(row)}</td>}
                            {showOffice && <td>{row.issueOffice?.name || ''}</td>}
                            {showInsuranceCompany && <td>{getPolicy(row).insuranceCompany.shortName}</td>}
                            <td>{getPolicy(row).productName}{' '}<DetailsIcon details={row.details} /></td>
                            <td>{getInstallmentNumbers(row)}/{getPolicy(row).installmentsCount}</td>
                            <td><PolicyInfoButton policy={getPolicy(row)} /></td>
                            <td>{row.sticker && <StickerInfoButton sticker= {row.sticker} />}</td>
                            <td>{row.greenCard &&
                                <GreenCardInfoButton greenCard={row.greenCard} />
                            }</td>
                            {detailed && <>
                                <td className="text-end"><PremiumAmount amount={row.premiumAmount || 0} /></td>
                                <td className="text-end"><PremiumAmount amount={row.taxAmount || 0} /></td>
                                <td className="text-end"><PremiumAmount amount={row.guaranteeFundAmount || 0} /></td>
                                <td className="text-end"><PremiumAmount amount={row.stickerAmount || 0} /></td>
                            </>}
                            <td className="text-end"><PremiumAmount amount={row.totalAmount || 0} /></td>
                            {detailed && <>
                                <RequireRole name="ROLE_SHOW_COMMISSION">
                                    <td className="text-end">
                                        <Percent coefficient={getBrokerVoucherCommissionCoefficient(row, getPolicy(row))} />
                                    </td>
                                    <td className="text-end">
                                        <PremiumAmount amount={getBrokerVoucherCommissionAmount(row, getPolicy(row))} />
                                    </td>
                                </RequireRole>
                                <td><DateShort date={getFirstInstallment(row).dueDate} /></td>
                            </>}
                            <td><DateShort date={row.issueDate} html /></td>
                            <td>{paymentMethodsShort[row.paymentMethod]}</td>
                            <td style={{whiteSpace: 'nowrap'}}>
                                <DateShort date={row.storeDate} html />{' '}
                                {(row.storageUser || row.storageOffice) ? <InfoIcon
                                    onClick={() => alert([row.storageUser?.shortName, row.storageOffice?.name]
                                    .join(' - '))} /> : 'Не'}
                            </td>
                            <td>{getFirstInstallment(row).number === 1 && <PolicyPrintButton policy={getPolicy(row)}
                                label="" printing={false} disabled={printing} setDisabled={setPrinting} />}</td>
                            <td><VoucherPrintButton voucher={row} label="" printing={false} disabled={printing}
                                setDisabled={setPrinting} /></td>
                            <td>{row.greenCard && <GreenCardPrintButton greenCard={row.greenCard} label=""
                                printing={false} disabled={printing} setDisabled={setPrinting} />}</td>
                            <td><VoucherEmailButton onClick={() => send(row.id)} disabled={sending}
                                setDisabled={setSending} label="" /></td>
                        </tr>)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={
                                (actionButton ? 1 : 0) +
                                6 +
                                (showUser ? 1 : 0) +
                                (showOffice ? 1 : 0) +
                                (showInsuranceCompany ? 1 : 0)
                            } className="text-end">Обща сума:</th>
                            {(() => {
                                return (<>
                                    {detailed && <>
                                        <th className="text-end"><PremiumAmountSum arr={nonVoidedVouchers}
                                                                                   field="premiumAmount" /></th>
                                        <th className="text-end"><PremiumAmountSum arr={nonVoidedVouchers}
                                                                                   field="taxAmount" /></th>
                                        <th className="text-end"><PremiumAmountSum arr={nonVoidedVouchers}
                                                                                   field="guaranteeFundAmount" /></th>
                                        <th className="text-end"><PremiumAmountSum arr={nonVoidedVouchers}
                                                                                   field="stickerAmount" /></th>
                                    </>}
                                    <th className="text-end"><PremiumAmountSum arr={nonVoidedVouchers}
                                                                               field="totalAmount" /></th>
                                    {detailed && <>
                                        <RequireRole name="ROLE_SHOW_COMMISSION">
                                            <th />
                                            <th className="text-end"><PremiumAmount amount={commissionAmountSum} /></th>
                                        </RequireRole>
                                    </>}
                                </>);
                            })()}
                            <th colSpan={
                                (detailed ? 1 : 0) +
                                7
                            } />
                        </tr>
                    </tfoot>
                </Table>
            </div>}
        </Card>);
    });
};

ReportTableWidget.defaultProps = {
    group: 'none',
    headerText: 'Резултати от търсенето',
    showTable: true,
    detailed: false,
    isLoading: false,
};

ReportTableWidget.propTypes = {
    data: PropTypes.array.isRequired,
    group: PropTypes.string,
    headerText: PropTypes.string,
    showTable: PropTypes.bool,
    detailed: PropTypes.bool,
    isLoading: PropTypes.bool,
    actionButton: PropTypes.func,
};

export default ReportTableWidget;
