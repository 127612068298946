import {Button} from "react-bootstrap";
import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";

const VoucherEmailButton = ({
    label,
    onClick,
    disabled,
    setDisabled,
    small,
}) => {
    const [buttonSending, setButtonSending] = useState(false);

    const handleClick = useCallback(async () => {
        setButtonSending(true);
        setDisabled(true);
        await onClick();
        setButtonSending(false);
        setDisabled(false);
    }, [onClick, setDisabled]);

    return (<Button variant="outline-success" className={'w-100' + (small ? ' p-1' : '')} onClick={handleClick}
        disabled={disabled}>
        <i className={buttonSending ? 'fas fa-sync-alt fa-spin' : 'far fa-envelope'} />{' '}{label}
    </Button>);
};

VoucherEmailButton.defaultProps = {
    label: 'Изпрати на моя e-mail',
    small: false,
};

VoucherEmailButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    setDisabled: PropTypes.func.isRequired,
    small: PropTypes.bool,
};

export default VoucherEmailButton;
