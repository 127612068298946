import React from 'react';
import PropTypes from 'prop-types';
import PremiumAmount from '../elements/Values/PremiumAmount';
import {Button} from 'react-bootstrap';

const ChangeWidget = ({
    payAmount,
    totalAmount,
}) => {
    payAmount = parseFloat(payAmount);

    if (isNaN(payAmount)) {
        payAmount = 0;
    }

    if (payAmount <= totalAmount) {
        return null;
    }

    const change = payAmount - totalAmount;

    return (<Button variant="secondary" disabled={true} className="w-100">
        <i className={'fas fa-info flex-shrink-0 me-2'} />
        <strong>Ресто: <PremiumAmount amount={change} /></strong>
    </Button>);
};

ChangeWidget.propTypes = {
    payAmount: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
};

export default ChangeWidget;
