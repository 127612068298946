import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Card from '../../elements/ReactBootstrap/Card';
import WeekAndInsuranceCompanySelectionWidget
    from '../../widgets/Report/Selection/WeekAndInsuranceCompanySelectionWidget';
import {getInsuranceCompanyReportUrl} from '../../../url';
import Content from '../../partials/Content.jsx';

const InsuranceCompany = () => {
    return (<Content title="Отчет застрахователни компании">
        <Row>
            <Col>
                <Card outline>
                    <WeekAndInsuranceCompanySelectionWidget
                        onSubmit={values => window.open(getInsuranceCompanyReportUrl(
                            values.insuranceCompany,
                            values.weekCode,
                            true,
                        ))} />
                </Card>
            </Col>
        </Row>
    </Content>);
};

export default InsuranceCompany;
