import {sum} from '../utils.js';

const useBrokerVoucherCommission = currentUser => {
    const currentInsuranceBrokerId = currentUser && currentUser.insuranceBroker.id;

    const getBrokerVoucherCommissionFlags = (voucher, policy) => {
        const hasPolicyCommission = currentInsuranceBrokerId &&
            policy.issueInsuranceBroker?.id === currentInsuranceBrokerId;
        const hasVoucherCommission = currentInsuranceBrokerId &&
            voucher.issueInsuranceBroker?.id === currentInsuranceBrokerId;

        return {
            hasPolicyCommission,
            hasVoucherCommission,
        };
    };

    const getBrokerVoucherCommissionCoefficient = (voucher, policy) => {
        const details = voucher.details || [];

        const {
            hasPolicyCommission,
            hasVoucherCommission,
        } = getBrokerVoucherCommissionFlags(voucher, policy);

        return details.length === 1 ? (detail => (
                hasPolicyCommission ? (detail.policyCommissionCoefficient || 0.0) : 0.0
        ) + (
            hasVoucherCommission ? (detail.voucherCommissionCoefficient || 0.0) : 0.0
        ))(details[0]) : null;
    };

    const getBrokerVoucherCommissionAmount = (voucher, policy) => {
        const details = voucher.details || [];

        const {
            hasPolicyCommission,
            hasVoucherCommission,
        } = getBrokerVoucherCommissionFlags(voucher, policy);

        return sum(details.map(detail => (
            hasPolicyCommission ? (detail.policyCommissionAmount || 0.0) : 0.0
        ) + (
            hasVoucherCommission ? (detail.voucherCommissionAmount || 0.0) : 0.0
        )));
    };

    return {
        getBrokerVoucherCommissionCoefficient,
        getBrokerVoucherCommissionAmount,
    };
};

export default useBrokerVoucherCommission;
