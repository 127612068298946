import React from 'react';
import PropTypes from 'prop-types';
import NavigationButton from './NavigationButton';

const BsoProtocolInfoButton = ({
    bsoProtocol,
    small,
}) => {
    const text = bsoProtocol.number.toString(10);

    return (<NavigationButton link={'/bso-protocol/' + bsoProtocol.id} small={small} copyText={text}>
        {text}
    </NavigationButton>);
};

BsoProtocolInfoButton.defaultProps = {
    small: false,
};

BsoProtocolInfoButton.propTypes = {
    bsoProtocol: PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.number.isRequired,
    }).isRequired,
    small: PropTypes.bool,
};

export default BsoProtocolInfoButton;
