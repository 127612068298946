import React from 'react';
import {Card, Col, Nav, Row, Tab} from 'react-bootstrap';
import NavItem from '../elements/NavItem';
import {Outlet} from 'react-router-dom';
import RequireRole from '../elements/AccessControl/RequireRole';
import Content from '../partials/Content.jsx';

const Settings = () => {
    return (<Content title="Настройка на софтуера">
        <Row>
            <Col>
                <Card className="card-success card-tabs">
                    <Card.Header className="p-0 pt-1">
                        <Nav as="ul" className="nav-tabs">
                            <NavItem to="broker">Брокер</NavItem>
                            <RequireRole name="ROLE_SHOW_COMMISSION">
                                <NavItem to="broker-commission">Комисион брокер</NavItem>
                            </RequireRole>
                        </Nav>
                    </Card.Header>
                    <Card.Body>
                        <Tab.Content>
                            <Tab.Pane active>
                                <Outlet />
                            </Tab.Pane>
                        </Tab.Content>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Content>);
};

export default Settings;
