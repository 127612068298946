import React from 'react';
import {Table} from 'react-bootstrap';
import DateShort from '../elements/DateTime/DateShort';
import PremiumAmount from '../elements/Values/PremiumAmount';
import PropTypes from 'prop-types';

const UabCardVouchersListWidget = ({
    vouchers,
}) => {
    return (<div className="table-responsive">
        <Table striped className="mb-0">
            <thead>
                <tr>
                    <th>Дата</th>
                    <th>Покритие</th>
                    <th>Сума</th>
                    <th>Консултант</th>
                </tr>
            </thead>
            <tbody>
                {vouchers.map(voucher => <React.Fragment key={voucher.id}>
                    <tr>
                        <td><DateShort date={voucher.issueDate} html /></td>
                        <td>
                            <DateShort date={voucher.beginDate} /> - <DateShort date={voucher.endDate} />
                        </td>
                        <td style={{whiteSpace: 'nowrap'}}>
                            <PremiumAmount amount={voucher.totalAmount} />
                        </td>
                        <td>
                            {voucher.issueUser.shortName} (офис {voucher.issueOffice.name})
                        </td>
                    </tr>
                </React.Fragment>)}
            </tbody>
        </Table>
    </div>);
};

UabCardVouchersListWidget.propTypes = {
    vouchers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        issueDate: PropTypes.string.isRequired,
        totalAmount: PropTypes.number.isRequired,
    })).isRequired,
};

export default UabCardVouchersListWidget;
