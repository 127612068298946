import React, {useState} from 'react';
import {Button, Col, ListGroup, Row} from 'react-bootstrap';
import {useGetVersionQuery, useListInsuranceCompaniesQuery} from '../../../features/apiSlice';
import {installmentCounts} from '../../../config';
import InsuranceCompanyMtplOffers from './InsuranceCompanyMtplOffers';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';
import {getInstallmentsCountLabel} from "../../../utils.js";

const MtplOffersTableWidget = () => {
    const [installmentsTab, setInstallmentsTab] = useState(1);

    const {
        data: version,
        isSuccess: versionSuccess,
    } = useGetVersionQuery();

    const {
        data: insuranceCompanies,
        isSuccess,
        isLoading,
    } = useListInsuranceCompaniesQuery();

    const mtplOfferUnsupportedNumbers = versionSuccess && !version.insuranceCompanyCommunicationIsProd ?
        // test
        [] :
        // prod
        [1];

    return (
        <>
            <ListGroup horizontal className="text-center d-md-none d-lg-flex d-xl-none" role="tablist">
                {installmentCounts.map(installmentsCount =>
                    <ListGroup.Item as={Button} key={installmentsCount} action className={'rounded-bottom-0' +
                        (installmentsTab === installmentsCount ? ' active' : '') + (installmentsCount === 2 ?
                        ' rounded-top-0' : (installmentsCount === 4 ? ' rounded-top-start-0' : ''))}
                        onClick={() => setInstallmentsTab(installmentsCount)}>
                        {getInstallmentsCountLabel(installmentsCount)}
                    </ListGroup.Item>
                )}
            </ListGroup>
            <ListGroup className={'show-' + installmentsTab} id="insurance-companies">
                <ListGroup.Item className="d-none d-md-block d-lg-none d-xl-block">
                    <Row className="font-weight-bold">
                        <Col>
                            <span>Застрахователна компания</span>
                        </Col>
                        {installmentCounts.map(installmentsCount =>
                            <Col key={installmentsCount} className="text-end">
                                <span>{getInstallmentsCountLabel(installmentsCount)}</span>
                            </Col>
                        )}
                    </Row>
                </ListGroup.Item>
                {isLoading && <ListGroup.Item><SmallSpinner /></ListGroup.Item>}
                {isSuccess && insuranceCompanies.filter(ic => ic.number).filter(insuranceCompany =>
                    !mtplOfferUnsupportedNumbers.includes(insuranceCompany.number)
                ).map(insuranceCompany =>
                    <InsuranceCompanyMtplOffers key={insuranceCompany.id} insuranceCompany={insuranceCompany} />
                )}
            </ListGroup>
        </>
    );
};

export default MtplOffersTableWidget;
