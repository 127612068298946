import {Table} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ClientInfoButton from './NavigationButtons/ClientInfoButton.jsx';
import React from 'react';

const ClientsListWidget = ({
    clients,
}) => {
    return (<div className="table-responsive">
        <Table striped className="mb-0">
            <thead>
                <tr>
                    <th>ЕГН/ЕИК</th>
                    <th>Име</th>
                </tr>
            </thead>
            <tbody>
                {clients.map(client => <tr key={client.id}>
                    <td><ClientInfoButton client={client} /></td>
                    <td><ClientInfoButton client={client} textField="name" /></td>
                </tr>)}
            </tbody>
        </Table>
    </div>);
};

ClientsListWidget.propTypes = {
    clients: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.number,
    }).isRequired),
};

export default ClientsListWidget;
