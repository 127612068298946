import React from 'react';
import Content from '../partials/Content.jsx';

const ValuationCertificateIssue = () => {
    return (<Content title="Издаване на УЗС">
        <div>Очаквайте скоро!</div>
    </Content>);
};

export default ValuationCertificateIssue;
