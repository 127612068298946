import React from 'react';
import NavBar from '../manager/partials/NavBar';
import MainSidebar from '../manager/partials/MainSidebar';
import MainFooter from '../manager/partials/MainFooter';
import {Outlet} from 'react-router-dom';

const ManagerView = () => {
    return (<div className="wrapper">
        <NavBar />
        <MainSidebar />
        <div className="content-wrapper d-flex flex-column">
            <Outlet />
        </div>
        <MainFooter />
    </div>);
};

export default ManagerView;
