import React from 'react';
import PropTypes from 'prop-types';
import {formatGreenCardNumber} from "../../../utils";

const GreenCardNumber = ({
    series,
    number,
}) => (<>
    {series}
    <span className="me-1"/>
    {formatGreenCardNumber(number)}
</>);

GreenCardNumber.propTypes = {
    series: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
};

export default GreenCardNumber;
