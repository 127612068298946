import {Field, useFormikContext} from 'formik';
import SelectField from '../ReactSelect/SelectField';
import {createFilter} from 'react-select';
import React, {useMemo} from 'react';
import {uabCardTypes} from '../../../utils.js';
import PropTypes from 'prop-types';

const UabCardTypeSelector = ({
    required,

    ...props
}) => {
    const uabCardTypeOptions = useMemo(
        () => Object.entries(uabCardTypes).map(([uabCardTypeId, uabCardType]) => ({
            value: uabCardTypeId,
            label: uabCardType.name,
        })), [uabCardTypes]);

    const { isSubmitting } = useFormikContext();

    return (<Field component={SelectField} name="uabCardType" options={uabCardTypeOptions}
        disabled={isSubmitting} isClearable={true} required={required}
        placeholder={required ? 'Изберете тип' : 'Всички типове'}
        filterOption={createFilter({
            stringify: option => option.label,
        })} {...props} />);
};

UabCardTypeSelector.defaultProps = {
    required: false,
};

UabCardTypeSelector.propTypes = {
    required: PropTypes.bool,
};

export default UabCardTypeSelector;
