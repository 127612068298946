import {format} from 'date-fns';
import {bg} from 'date-fns/locale';

const DateLong = ({
    date,
}) => {
    if (date === undefined) {
        return '';
    }

    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    return format(date, 'dd MMMM yyyy', {
        locale: bg,
    });
};

export default DateLong;
