import {useGetVersionQuery} from '../../../features/apiSlice';

const RequireInsuranceCompanyNonProd = ({
    children,
}) => {
    const {
        data: version,
        isSuccess,
    } = useGetVersionQuery();

    return isSuccess && !version.insuranceCompanyCommunicationIsProd ? children : null;
};

export default RequireInsuranceCompanyNonProd;
