import {useCurrentUserHasRoleQuery} from '../features/apiSlice';

const useCurrentUserHasRole = name => {
    const {
        data: hasRole,
        isSuccess,
    } = useCurrentUserHasRoleQuery(name, {
        skip: !name,
    });

    return isSuccess && hasRole;
};

export default useCurrentUserHasRole;
