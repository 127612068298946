import PropTypes from "prop-types";

const PaymentsCount = ({
    count,
}) => count + ' ' + (count === 1 ? 'плащане' : 'плащания');

PaymentsCount.propTypes = {
    count: PropTypes.number.isRequired,
};

export default PaymentsCount;
