import React, {useMemo} from 'react';
import {getErrorMessage} from '../../../utils';

const ErrorIcon = ({
    error,
}) => {
    const errorMessage = useMemo(() => getErrorMessage(error), [error]);

    return (
        <i className="fas fa-exclamation-circle text-danger" title={errorMessage} onClick={() => alert(errorMessage)} />
    );
};

export default ErrorIcon;
