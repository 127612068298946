import {Col, Row} from "react-bootstrap";
import React, {useState} from "react";
import Card from "../../elements/ReactBootstrap/Card.jsx";
import PropTypes from "prop-types";
import VouchersListWidget from "../VouchersListWidget.jsx";

const VoucherSearchResult = ({
    results,
}) => {
    const [printing, setPrinting] = useState(false);

    const length = results.length;

    if (length < 1) {
        return null;
    }

    return (<Row>
        <Col>
            <Card noPadding header={'Квитанции (' + length + ')'}>
                <VouchersListWidget vouchers={results} policy={results?.[0]?.installments?.[0]?.policy}
                    printing={printing} setPrinting={setPrinting} />
            </Card>
        </Col>
    </Row>);
};

VoucherSearchResult.propTypes = {
    results: PropTypes.array,
};

export default VoucherSearchResult;
