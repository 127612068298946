import React, {useEffect} from 'react';
import {Card, Table} from 'react-bootstrap';
// import jQuery from 'jquery';

// TODO: replace jQuery and DataTables with something else

const ClientResultWidget = () => {
    useEffect(() => {
        // jQuery('#clientResult').DataTable({
        //     'responsive': true, 'lengthChange': false, 'autoWidth': false,
        //     "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
        // }).buttons().container().appendTo('#clientResult_wrapper .col-md-6:eq(0)');
    }, []);

    return (
        <Card>
            <Card.Header>
                <Card.Title as="h3">Резултат</Card.Title>
            </Card.Header>
            <Card.Body>
                <Table striped id="clientResult">
                    <thead>
                    <tr>
                        <th>Име/Фирма</th>
                        <th>Презиме</th>
                        <th>Фамилия</th>
                        <th>ЕГН/ЕИК</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Александър</td>
                        <td>Слагян</td>
                        <td>Йовевски</td>
                        <td>0000000001</td>
                    </tr>
                    <tr>
                        <td>Белослава</td>
                        <td>Чавдарова</td>
                        <td>Чирпъкова</td>
                        <td>0000000002</td>
                    </tr>
                    <tr>
                        <td>Белослава</td>
                        <td>Владимирова</td>
                        <td>Димитрова</td>
                        <td>0000000003</td>
                    </tr>
                    <tr>
                        <td>Блага</td>
                        <td>Цветомирова</td>
                        <td>Стоянова</td>
                        <td>0000000004</td>
                    </tr>
                    <tr>
                        <td>Божидара</td>
                        <td>Анатолиева</td>
                        <td>Костадинова</td>
                        <td>0000000005</td>
                    </tr>
                    <tr>
                        <td>Боян</td>
                        <td>Крумов</td>
                        <td>Крумов</td>
                        <td>0000000006</td>
                    </tr>
                    <tr>
                        <td>Боян</td>
                        <td>Пламенов</td>
                        <td>Великов</td>
                        <td>0000000007</td>
                    </tr>
                    <tr>
                        <td>Виктория</td>
                        <td>Антонова</td>
                        <td>Дойнова</td>
                        <td>0000000008</td>
                    </tr>
                    <tr>
                        <td>Виктория</td>
                        <td>Боянова</td>
                        <td>Анастасова</td>
                        <td>0000000009</td>
                    </tr>
                    <tr>
                        <td>Даниел</td>
                        <td>Пламенов</td>
                        <td>Дамянов</td>
                        <td>0000000010</td>
                    </tr>
                    <tr>
                        <td>Дея</td>
                        <td>Живомирова</td>
                        <td>Тошкова</td>
                        <td>0000000011</td>
                    </tr>
                    <tr>
                        <td>Елиа</td>
                        <td>Стоянова</td>
                        <td>Гроздева</td>
                        <td>0000000012</td>
                    </tr>
                    <tr>
                        <td>Иван</td>
                        <td>Йорданов</td>
                        <td>Илиев</td>
                        <td>0000000013</td>
                    </tr>
                    <tr>
                        <td>Илия</td>
                        <td>Георгиев</td>
                        <td>Минков</td>
                        <td>0000000014</td>
                    </tr>
                    <tr>
                        <td>Калоян</td>
                        <td>Димитров</td>
                        <td>Добрев</td>
                        <td>0000000015</td>
                    </tr>
                    <tr>
                        <td>Катерина</td>
                        <td>Христова</td>
                        <td>Тодорова</td>
                        <td>0000000016</td>
                    </tr>
                    <tr>
                        <td>Катрин</td>
                        <td>Костадинова</td>
                        <td>Костадинова</td>
                        <td>0000000017</td>
                    </tr>
                    <tr>
                        <td>Кристиан</td>
                        <td>Иво</td>
                        <td>Стоев</td>
                        <td>0000000018</td>
                    </tr>
                    <tr>
                        <td>Кристиян</td>
                        <td>Атанасов</td>
                        <td>Никифоров</td>
                        <td>0000000019</td>
                    </tr>
                    <tr>
                        <td>Кристиян</td>
                        <td>Боянов</td>
                        <td>Минев</td>
                        <td>0000000020</td>
                    </tr>
                    <tr>
                        <td>Кубрат</td>
                        <td>Момчилов</td>
                        <td>Данов</td>
                        <td>0000000021</td>
                    </tr>
                    <tr>
                        <td>Мерт</td>
                        <td>Илхан</td>
                        <td>Мехмедов</td>
                        <td>0000000022</td>
                    </tr>
                    <tr>
                        <td>Мелани</td>
                        <td>Руменова</td>
                        <td>Иванова</td>
                        <td>0000000023</td>
                    </tr>
                    <tr>
                        <td>Михаил</td>
                        <td>Ивайлов</td>
                        <td>Иванов</td>
                        <td>0000000024</td>
                    </tr>
                    <tr>
                        <td>Николай</td>
                        <td>Владимиров</td>
                        <td>Василев</td>
                        <td>0000000025</td>
                    </tr>
                    <tr>
                        <td>Петър</td>
                        <td>Митков</td>
                        <td>Панайотов</td>
                        <td>0000000026</td>
                    </tr>
                    <tr>
                        <td>София</td>
                        <td>Вилиян</td>
                        <td>Кръстева</td>
                        <td>0000000027</td>
                    </tr>
                    <tr>
                        <td>Филип</td>
                        <td>Галинов</td>
                        <td>Георгиев</td>
                        <td>0000000028</td>
                    </tr>
                    <tr>
                        <td>Цветелина</td>
                        <td>Василева</td>
                        <td>Новкова</td>
                        <td>0000000029</td>
                    </tr>
                    <tr>
                        <td>Янислав</td>
                        <td>Николаев</td>
                        <td>Добрев</td>
                        <td>0000000030</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th>Име/Фирма</th>
                        <th>Презиме</th>
                        <th>Фамилия</th>
                        <th>ЕГН/ЕИК</th>
                    </tr>
                    </tfoot>
                </Table>
            </Card.Body>
        </Card>
    );
};

export default ClientResultWidget;
