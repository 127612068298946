import React from 'react';
import Content from '../partials/Content.jsx';

const CascoOffer = () => {
    return (<Content title="Оферта за нова полица Каско">
        <div>Очаквайте скоро!</div>
    </Content>);
};

export default CascoOffer;
