import React from 'react';
import {Button, Col, Container, Dropdown, Nav, Navbar, Row} from 'react-bootstrap';
import {Link, NavLink} from 'react-router-dom';
import {useGetCurrentUserQuery} from '../../features/apiSlice';
import ErrorIcon from '../elements/Icons/ErrorIcon';
import DevWarning from './DevWarning';
import {logout} from '../../utils';
import RequireInsuranceCompanyNonProd from '../elements/AccessControl/RequireInsuranceCompanyNonProd';
import RequireRole from '../elements/AccessControl/RequireRole';
import DebugMenu from './DebugMenu';
import {setForm} from '../../features/formSlice';
import {useDispatch} from 'react-redux';
import UserAvatar from '../elements/UserAvatar.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';

const NavBar = () => {
    const {
        data: user,
        error: userError,
        isSuccess: userSuccess,
        isLoading: userLoading,
        isError: userIsError,
    } = useGetCurrentUserQuery(undefined, {
        pollingInterval: 60000,
    });

    const dispatch = useDispatch();

    return (
        <Navbar className="main-header">
            <Container fluid>
                <Nav as="ul" className="flex-grow-1">
                    <Nav.Item as="li">
                        <Nav.Link as={Button} variant={false} data-lte-toggle="sidebar-full">
                            <i className="fas fa-bars"/>
                        </Nav.Link>
                    </Nav.Item>
                    <RequireInsuranceCompanyNonProd>
                        <DevWarning />
                    </RequireInsuranceCompanyNonProd>
                    {user.insuranceBroker.confirmedReportWeek && <Button as={Link} variant="success"
                        to="/reports/sales/generate" className="w-100 mx-2" onClick={() => {
                            dispatch(setForm({
                                formName: 'salesFormWeek',
                                values: {
                                    weekCode: user.insuranceBroker.confirmedReportWeek || '',
                                },
                            }));
                        }}>
                        Справка продажби за седмица {' '}
                        {user.insuranceBroker.confirmedReportWeek.match(/\d{4}-W(\d+)/)[1] || ''}{' '}
                        е потвърдена от управителя
                    </Button>}
                    {/*
                    <Nav.Item as="li" className="d-none d-md-block">
                        <Nav.Link href="/">Home</Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="d-none d-md-block">
                        <Nav.Link href="/">Support</Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="d-none d-md-block">
                        <Nav.Link href="/">Test2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="d-none d-md-block">
                        <Nav.Link href="/">Test3</Nav.Link>
                    </Nav.Item>
                    */}
                </Nav>
                <Nav as="ul" className="ms-auto">
                    <Nav.Item as="li">
                        <Nav.Link as={Button} variant={false} data-lte-toggle="fullscreen">
                            <i className="fas fa-expand-arrows-alt"/>
                        </Nav.Link>
                    </Nav.Item>
                    <Dropdown as="li" className="nav-item user-menu">
                        <Dropdown.Toggle as={Nav.Link}>
                            {userLoading && <SmallSpinner />}
                            {userIsError && <ErrorIcon error={userError} />}
                            {userSuccess && <>
                                <UserAvatar user={user} size={34} className="user-image shadow" />
                                <span className="d-none d-md-inline">{user.shortName}</span>
                            </>}
                        </Dropdown.Toggle>
                        <Dropdown.Menu as="ul" className="dropdown-menu-lg dropdown-menu-end">
                            <li className="user-header bg-success">
                                {userLoading && <SmallSpinner />}
                                {userIsError && <ErrorIcon error={userError} />}
                                {userSuccess && <>
                                    <UserAvatar user={user} size={84} className="shadow" />
                                    <p>
                                        {user.fullName}
                                        <small>Офис {user.office.name}</small>
                                        <small>{user.level.name}</small>
                                    </p>
                                </>}
                            </li>
                            <li className="user-body">
                                <Row>
                                    <Col xs={4} className="text-center">
                                        <NavLink to="/reports/sales">Продажби</NavLink>
                                    </Col>
                                    <Col xs={4} className="text-center">
                                        <NavLink to="/bso">БСО</NavLink>
                                    </Col>
                                </Row>
                            </li>
                            <li className="user-footer">
                                <Col xs={{span: 4, offset: 8}} className="text-center">
                                    <Button variant="default" onClick={() => logout()}>Изход</Button>
                                </Col>
                            </li>
                            <RequireRole name="ROLE_SUPER_ADMIN">
                                <DebugMenu />
                            </RequireRole>
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>
            </Container>
        </Navbar>
    );
};

export default NavBar;
