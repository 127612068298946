import React from 'react';
import PropTypes from 'prop-types';
import NavigationButton from './NavigationButton';

const PolicyInfoButton = ({
    policy,
    small,
}) => {
    return (<NavigationButton link={'/policy/' + policy.id} small={small} copyText={policy.number}>
        {policy.number}
    </NavigationButton>);
};

PolicyInfoButton.defaultProps = {
    small: false,
};

PolicyInfoButton.propTypes = {
    policy: PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
    }).isRequired,
    small: PropTypes.bool,
};

export default PolicyInfoButton;
