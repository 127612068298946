import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import CopyButton from './CopyButton';
import GreenCardNumber from '../elements/Values/GreenCardNumber';
import PolicyInfoButton from './NavigationButtons/PolicyInfoButton';
import GreenCardPrintButton from '../elements/PrintButtons/GreenCardPrintButton';
import {formatGreenCardNumber} from '../../utils.js';
import BsoStatusWidget from './BsoStatusWidget.jsx';
import TextWithCopyButton from './TextWithCopyButton.jsx';

const GreenCardInfoWidget = ({
    greenCard,
    printing,
    setPrinting,
}) => {
    return (<>
        <Row as="dl">
            <Col sm={4} as="dt">Номер на бланката</Col>
            <Col sm={8} as="dd">
                <GreenCardNumber {...greenCard} />
                <CopyButton text={greenCard.series + formatGreenCardNumber(greenCard.number)} />
            </Col>
            {greenCard.policyNumber && <>
                <Col sm={4} as="dt">Номер на СЗК полицата</Col>
                <Col sm={8} as="dd">
                    <TextWithCopyButton text={greenCard.policyNumber} />
                </Col>
            </>}
            <Col sm={4} as="dt">Статус</Col>
            <Col sm={8} as="dd">
                <BsoStatusWidget type="greenCard" updateRole="ROLE_UPDATE_GREEN_CARD" {...greenCard} />
            </Col>
            {greenCard.insuranceCompany && <>
                <Col sm={4} as="dt">Застрахователна компания</Col>
                <Col sm={8} as="dd">{greenCard.insuranceCompany.shortName}</Col>
            </>}
            {greenCard.insuranceBroker && <>
                <Col sm={4} as="dt">Брокер</Col>
                <Col sm={8} as="dd">{greenCard.insuranceBroker.shortName}</Col>
            </>}
            {greenCard.office && <>
                <Col sm={4} as="dt">Офис</Col>
                <Col sm={8} as="dd">{greenCard.office.name}</Col>
            </>}
            {greenCard.user && <>
                <Col sm={4} as="dt">Отговорно лице</Col>
                <Col sm={8} as="dd">{greenCard.user.shortName}</Col>
            </>}
            {greenCard.installments && greenCard.installments.length > 0 && <>
                <Col sm={4} as="dt">Използвана по полица</Col>
                <Col sm={8} as="dd">
                    <PolicyInfoButton policy={greenCard.installments[0].policy} />
                </Col>
            </>}
        </Row>
        {greenCard.expended && <Row>
            <Col sm={6} xl={3}>
                <GreenCardPrintButton greenCard={greenCard} printing={false} disabled={printing}
                    setDisabled={setPrinting} />
            </Col>
        </Row>}
    </>);
};

GreenCardInfoWidget.propTypes = {
    greenCard: PropTypes.shape({
        series: PropTypes.string.isRequired,
        number: PropTypes.number.isRequired,
    }).isRequired,
};

export default GreenCardInfoWidget;
