import React from 'react';
import {Col, FormCheck} from 'react-bootstrap';
import {Field, useFormikContext} from 'formik';
import SelectField from '../../elements/ReactSelect/SelectField';
import {useGetCalculatorConfigQuery} from '../../../features/apiSlice';

const MtplOfferWidgetAdditionalInformation = () => {
    const {isSubmitting} = useFormikContext();

    const {
        data: calculatorConfig,
        isSuccess,
    } = useGetCalculatorConfigQuery();

    return (<>
        <Col>
            <Field component={SelectField} name="vehiclePurpose"
                   options={isSuccess ? Object.entries(calculatorConfig.vehiclePurposes).map(
                       ([code, description]) => ({
                           label: description,
                           value: code,
                       })
                   ) : []}
                   required disabled={isSubmitting} isClearable={true}
                   placeholder="Автоматичен избор на предназначение" />
        </Col>
        <Col>
            <FormCheck as={Field} name="rightSteering" id="rightSteering"
                        label="МПС е с десен волан" disabled={isSubmitting}>
            </FormCheck>
        </Col>
        <Col>
            <FormCheck as={Field} name="faultForAccident" id="faultForAccident"
                        label="Вина при ПТП в последните 3 години" disabled={isSubmitting}>
            </FormCheck>
        </Col>
    </>);
};

export default MtplOfferWidgetAdditionalInformation;
