import NavItem from "./NavItem";

const SidebarNavItem = ({
    children,
    ...props
}) => {
    return (<NavItem {...props}>
        <p>{children}</p>
    </NavItem>);
};

export default SidebarNavItem;
