import {forwardRef, lazy, Suspense, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {defaultComparator} from '@ag-grid-community/core/dist/esm/es6/utils/generic';
import localeText from '../../../elements/AgGrid/locale.bg.js';
import {agMultiFilter, agParseDate, agSort, getBsoProtocolStatus} from '../../../../utils.js';
import DateShort from '../../../elements/DateTime/DateShort.jsx';
import BsoProtocolInfoButton from '../../NavigationButtons/BsoProtocolInfoButton.jsx';
import SmallSpinner from '../../../elements/Spinner/SmallSpinner.jsx';

const AgGridEnterprise = lazy(() => import('../../../elements/AgGrid/AgGridEnterprise.js'));
const AgGridReact = lazy(() => import('../../../elements/AgGrid/AgGridReactWrapper'));

const BsoProtocolGridWidget = forwardRef(({
    rowData,
}, ref) => {
    const animateRows = true;

    const onGridReady = useCallback(event => agSort(event, [{
        colId: 'createdAt',
        sort: 'desc',
    }]), []);

    const multiFilter = agMultiFilter;
    const parseDate = useCallback((date, resetTime) => agParseDate(date, resetTime), []);

    const columnDefs = useMemo(() => [{
        field: 'number',
        filter: 'agNumberColumnFilter',
        headerName: '№',
        cellRenderer: params => params.data ?
            <BsoProtocolInfoButton bsoProtocol={params.data} small /> : params.value,
        width: 110,
    }, {
        field: 'createdAt',
        filter: 'agDateColumnFilter',
        headerName: 'Дата',
        valueGetter: params => parseDate(params.data?.createdAt, false),
        cellRenderer: params => <DateShort date={params.value} />,
        width: 150,
    }, {
        field: 'sendOffice.name',
        headerName: 'От офис',
        width: 150,
        ...multiFilter,
    }, {
        field: 'receiveOffice.name',
        headerName: 'За офис',
        width: 150,
        ...multiFilter,
    }, {
        field: 'createUser.shortName',
        headerName: 'Създаден от',
        width: 200,
        ...multiFilter,
    }, {
        colId: 'status',
        headerName: 'Статус',
        width: 150,
        valueGetter: params => params.data ? getBsoProtocolStatus(params.data) : null,
        ...multiFilter,
    }, {
        colId: 'stickersCount',
        filter: 'agNumberColumnFilter',
        headerName: 'Стикери',
        valueGetter: params => params.data?.stickers?.length,
        width: 110,
    }, {
        colId: 'greenCardsCount',
        filter: 'agNumberColumnFilter',
        headerName: 'СЗК',
        valueGetter: params => params.data?.greenCards?.length,
        width: 110,
    }, {
        colId: 'uabCardsCount',
        filter: 'agNumberColumnFilter',
        headerName: 'СБА',
        valueGetter: params => params.data?.uabCards?.length,
        width: 110,
    }], []);

    const defaultColDef = useMemo(() => ({
        comparator: (valueA, valueB) => defaultComparator(
            valueA?.toLowerCase ? valueA.toLowerCase() : valueA,
            valueB?.toLowerCase ? valueB.toLowerCase() : valueB,
        ),
        enableRowGroup: true,
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
        },
        floatingFilter: true,
        resizable: true,
        sortable: true,
    }), []);

    const rowClassRules = useMemo(() => ({
        // 'grid-row-danger': params => params.data && params.data.void,
        // 'progress-bar-striped': params => params.data && params.data.void,
    }), []);

    return (<Suspense fallback={<div className="p-3"><SmallSpinner /></div>}>
        <AgGridEnterprise>
            <AgGridReact ref={ref} className="ag-theme-alpine" {...{
                animateRows, columnDefs, defaultColDef, localeText, onGridReady, rowData,
                rowClassRules,
            }} />
        </AgGridEnterprise>
    </Suspense>);
});

BsoProtocolGridWidget.propTypes = {
    rowData: PropTypes.arrayOf(PropTypes.object.isRequired),
};

export default BsoProtocolGridWidget;
