import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'formik';
import {Form, InputGroup} from 'react-bootstrap';

const IconField = ({
    children,
    label,
    prependIcon,
    prependText,
    appendIcon,
    appendText,

    ...props
}) => {
    // TODO label htmlFor and input id
    return <>
        {label && <Form.Label>{label}</Form.Label>}
        <InputGroup>
            {(prependIcon || prependText) && <InputGroup.Text>
                {prependIcon && <i className={'fas fa-' + prependIcon}/>}
                {prependText}
            </InputGroup.Text>}
            {children || <Form.Control as={Field} {...props} />}
            {(appendIcon || appendText) && <InputGroup.Text>
                {appendIcon && <i className={'fas fa-' + appendIcon}/>}
                {appendText}
            </InputGroup.Text>}
        </InputGroup>
    </>;
};

IconField.propTypes = {
    label: PropTypes.node,
    prependIcon: PropTypes.string,
    prependText: PropTypes.node,
    appendIcon: PropTypes.string,
    appendText: PropTypes.node,
};

export default IconField;
