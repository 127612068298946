import React from 'react';
import PropTypes from 'prop-types';
import NavigationButton from './NavigationButton';

const ClientInfoButton = ({
    client,
    textField,
}) => {
    if (!client) {
        return null;
    }

    const text = textField && !!client[textField] ? client[textField] : client.pin;

    return (<NavigationButton link={'/client/' + client.id} copyText={text}>
        {text}
    </NavigationButton>);
};

ClientInfoButton.propTypes = {
    client: PropTypes.shape({
        id: PropTypes.string.isRequired,
        pin: PropTypes.string.isRequired,
    }),
    textField: PropTypes.string,
};

export default ClientInfoButton;
