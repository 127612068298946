import React from 'react';
import {useListCurrentInsuranceBrokerCommissionSettingsQuery} from '../../../features/apiSlice';
import ErrorWidget from '../ErrorWidget';
import InsuranceBrokerCommissionSettingsInfoWidget from '../InsuranceBrokerCommissionSettingsInfoWidget';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';

const BrokerSettingsWidget = () => {
    const {
        data: commissionSettings,
        error,
        isSuccess,
        isLoading,
        isError,
    } = useListCurrentInsuranceBrokerCommissionSettingsQuery();

    return (<>
        {isLoading && <>Зареждане на информация за комисион на брокера... <SmallSpinner /></>}
        {isError && <ErrorWidget error={error} />}
        {isSuccess && <InsuranceBrokerCommissionSettingsInfoWidget commissionSettings={commissionSettings} />}
    </>);
};

export default BrokerSettingsWidget;
