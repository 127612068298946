import {Col, Row} from "react-bootstrap";
import React from "react";
import Card from "../../elements/ReactBootstrap/Card.jsx";
import PropTypes from "prop-types";
import GreenCardsListWidget from "../GreenCardsListWidget.jsx";

const GreenCardSearchResult = ({
    results,
}) => {
    const length = results.length;

    if (length < 1) {
        return null;
    }

    return (<Row>
        <Col>
            <Card noPadding header={'СЗК (' + length + ')'}>
                <GreenCardsListWidget greenCards={results} />
            </Card>
        </Col>
    </Row>);
};

GreenCardSearchResult.propTypes = {
    results: PropTypes.array,
};

export default GreenCardSearchResult;
