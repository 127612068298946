import React from 'react';
import Card from '../elements/ReactBootstrap/Card';
import {Col, Row} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import ErrorWidget from '../widgets/ErrorWidget';
import {useGetUabCardQuery} from '../../features/apiSlice';
import UabCardInfoWidget from '../widgets/UabCardInfoWidget';
import Content from '../partials/Content.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';
import UabCardVouchersListWidget from '../widgets/UabCardVouchersListWidget.jsx';

const UabCard = () => {
    const {uabCardId} = useParams();

    const {
        data: uabCard,
        error,
        isLoading,
        isSuccess,
        isError,
    } = useGetUabCardQuery(uabCardId);

    return (<Content title="Информация за карта СБА">
        <Row className="row-cols-1">
            <Col>
                {isLoading && <Card>Зареждане на информация за картата... <SmallSpinner /></Card>}
                {isError && <ErrorWidget error={error} />}
                {isSuccess && <Card>
                    <UabCardInfoWidget uabCard={uabCard} />
                </Card>}
            </Col>
            <Col>
                {isSuccess && uabCard.vouchers.length > 0 && <>
                    <Card noPadding header="Издадени разписки">
                        <UabCardVouchersListWidget vouchers={uabCard.vouchers} />
                    </Card>
                </>}
            </Col>
        </Row>
    </Content>);
};

export default UabCard;
