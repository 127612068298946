import React from 'react';
import {Alert as BaseAlert} from 'react-bootstrap';
import PropTypes from 'prop-types';

const Alert = ({
    children,
    heading,
    icon,

    ...props
}) => {
    if (icon === undefined && props.variant) {
        icon = ({
            success: 'check',
            danger: 'ban',
            warning: 'exclamation-triangle',
            info: 'info',
            secondary: 'info',
        })[props.variant] || undefined;
    }

    return (<BaseAlert {...props}>
        {(heading || icon) && <BaseAlert.Heading className="d-flex align-items-center">
            {icon && <i className={'fas fa-' + icon + ' flex-shrink-0 me-2'} />}
            {heading}
        </BaseAlert.Heading>}
        {children}
    </BaseAlert>);
};

Alert.defaultProps = {
    heading: '',
    icon: undefined,
};

Alert.propTypes = {
    heading: PropTypes.node,
    icon: PropTypes.string,
};

export default Alert;
