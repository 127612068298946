import React from 'react';
import {useGetOfferQuery} from '../../../features/apiSlice';
import {useParams} from 'react-router-dom';
import ErrorIcon from '../../elements/Icons/ErrorIcon';
import {Col, Row} from 'react-bootstrap';
import PremiumAmountSum from '../../elements/Values/PremiumAmountSum';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';

const MtplOfferInfoWidget = () => {
    const {
        offerId,
    } = useParams();

    const {
        data: offer,
        error,
        isSuccess,
        isError,
        isLoading,
    } = useGetOfferQuery(offerId, {
        skip: offerId === undefined,
    });

    return (<>
        {isLoading && <SmallSpinner />}
        {isError && <ErrorIcon error={error} />}
        {isSuccess && <Row>
            {offer.insuranceCompany?.shortName && <>
                <Col sm={4} lg={12} xl={4} as="dt">Застрахователна компания</Col>
                <Col sm={8} lg={12} xl={8} as="dd">{offer.insuranceCompany?.shortName}</Col>
            </>}
            {offer.installmentsCount && <>
                <Col sm={4} lg={12} xl={4} as="dt">Брой вноски</Col>
                <Col sm={8} lg={12} xl={8} as="dd">{offer.installmentsCount}</Col>
            </>}
            {offer.application?.personalNumber && <>
                <Col sm={4} lg={12} xl={4} as="dt">Застраховано лице</Col>
                <Col sm={8} lg={12} xl={8} as="dd">
                    {offer.application?.personalNumber}
                </Col>
            </>}
            {offer.application?.plateNumber && <>
                <Col sm={4} lg={12} xl={4} as="dt">МПС</Col>
                <Col sm={8} lg={12} xl={8} as="dd">{offer.application.plateNumber}</Col>
            </>}
            {offer.installments && <>
                <Col sm={4} lg={12} xl={4} as="dt">Обща дължима премия</Col>
                <Col sm={8} lg={12} xl={8} as="dd">
                    <PremiumAmountSum arr={offer.installments} field="totalAmount" />
                </Col>
            </>}
        </Row>}
    </>);
};

export default MtplOfferInfoWidget;
