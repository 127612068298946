import React from 'react';
import {Link} from 'react-router-dom';
import {useGetCurrentUserQuery} from '../../features/apiSlice';
import ErrorIcon from '../elements/Icons/ErrorIcon';
import SearchFormWidget from '../widgets/SearchFormWidget';
import SidebarNavWidget from '../widgets/SidebarNavWidget';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';

const MainSidebar = () => {
    const {
        data: user,
        error: userError,
        isSuccess: userSuccess,
        isLoading: userLoading,
        isError: userIsError,
    } = useGetCurrentUserQuery();

    return (
        <aside className="main-sidebar sidebar-bg-dark sidebar-color-primary shadow">
            <div className="brand-container">
                {userLoading && <span className="brand-text fw-light">
                    <SmallSpinner />
                </span>}
                {userIsError && <span className="brand-text fw-light">
                    <ErrorIcon error={userError} />
                </span>}
                {userSuccess && <Link to="/" className="brand-link">
                    {user.insuranceBroker.logoUrl && <img src={user.insuranceBroker.logoUrl} alt="Broker Logo"
                         className="brand-image opacity-80 shadow" />}
                    <span className="brand-text fw-light">{user.insuranceBroker.shortName}</span>
                </Link>}
                <a href="/" className="pushmenu mx-1" data-lte-toggle="sidebar-full">
                    <i className="fas fa-angle-double-left" />
                </a>
            </div>
            <div className="sidebar sidebar-dark">
                <SearchFormWidget />
                <SidebarNavWidget />
            </div>
        </aside>
    );
};

export default MainSidebar;
