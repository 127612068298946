import React from 'react';
import PropTypes from 'prop-types';
import NavigationButton from './NavigationButton';

const UserInfoButton = ({
    user,
    textField,
}) => {
    if (!user) {
        return null;
    }

    const text = textField && !!user[textField] ? user[textField] : user.shortName;

    return (<NavigationButton link={'/user/' + user.id} copyText={text}>
        {text}
    </NavigationButton>);
};

UserInfoButton.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        shortName: PropTypes.string.isRequired,
    }),
    textField: PropTypes.string,
};

export default UserInfoButton;
