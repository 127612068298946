import React, {useCallback, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import MtplOfferInfoWidget from '../../widgets/Mtpl/MtplOfferInfoWidget';
import InstallmentsListWidget from '../../widgets/InstallmentsListWidget';
import Card from '../../elements/ReactBootstrap/Card';
import {useParams} from 'react-router-dom';
import {
    useCreatePolicyFromOfferMutation,
    useGetOfferQuery,
    useSendVoucherEmailMutation
} from '../../../features/apiSlice';
import ErrorWidget from '../../widgets/ErrorWidget';
import InstallmentPaymentWidget from '../../widgets/InstallmentPaymentWidget';
import SuccessfulIssueWidget from '../../widgets/SuccessfulIssueWidget';
import PolicyInfoWidget from '../../widgets/PolicyInfoWidget';
import Alert from '../../elements/ReactBootstrap/Alert';
import PolicyPrintButton from '../../elements/PrintButtons/PolicyPrintButton';
import VoucherPrintButton from '../../elements/PrintButtons/VoucherPrintButton';
import GreenCardPrintButton from '../../elements/PrintButtons/GreenCardPrintButton';
import {printGreenCard, printPolicy, printVoucher} from '../../../print';
import SelectedBso from '../../widgets/Mtpl/SelectedBso';
import PremiumAmount from '../../elements/Values/PremiumAmount';
import Content from '../../partials/Content.jsx';
import VoucherEmailButton from '../../elements/Buttons/VoucherEmailButton.jsx';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';
import SlowSpinner from '../../elements/Spinner/SlowSpinner.jsx';
import BankAccountInfo from '../../elements/BankAccountInfo.jsx';

const MtplIssue = () => {
    const [policyPrinting, setPolicyPrinting] = useState(false);
    const [voucherPrinting, setVoucherPrinting] = useState(false);
    const [greenCardPrinting, setGreenCardPrinting] = useState(false);

    const printing = policyPrinting || voucherPrinting || greenCardPrinting;

    const [sending, setSending] = useState(false);

    const [selectedInstallments, setSelectedInstallments] = useState([]);

    const {
        offerId,
    } = useParams();

    const {
        data: offer,
        error: findError,
        isSuccess: findSuccess,
        isError: findIsError,
        isLoading: findLoading,
    } = useGetOfferQuery(offerId, {
        skip: offerId === undefined,
    });

    const [issue, {
        data: policy,
        error: issueError,
        isSuccess: issueSuccess,
        isError: issueIsError,
        isLoading: issueLoading,
    }] = useCreatePolicyFromOfferMutation();

    const [send, {
        isSuccess: sendSuccess,
        isError: sendIsError,
        error: sendError,
    }] = useSendVoucherEmailMutation();

    const getFirstInstallment = useCallback(
        installments => installments.filter(installment => installment.number === 1).shift(),
        []
    );

    const getFirstGreenCard = useCallback(
        installment => installment && installment.greenCards.length > 0 &&
            installment.greenCards.concat().shift(),
        []
    );

    const getFirstVoucher = useCallback(installment => installment && installment.paidPremiumFromClient && (
        installment.vouchers.filter(voucher => voucher.sticker !== null).shift() ||
        installment.vouchers.concat().shift()
    ), []);

    const offerInstallments = findSuccess ? offer.installments : [];
    const policyInstallments = issueSuccess ? policy.installments : [];
    const installments = issueSuccess ? policyInstallments : offerInstallments;

    const offerFirstInstallment = getFirstInstallment(offerInstallments);
    const policyFirstInstallment = getFirstInstallment(policyInstallments);
    const policyFirstVoucher = getFirstVoucher(policyFirstInstallment);
    const policyFirstGreenCard = getFirstGreenCard(policyFirstInstallment);

    const payFirstInstallment = selectedInstallments.includes(offerFirstInstallment);

    return (<Content title="Издаване на нова полица ГО">
        <Row>
            <Col>
                {findIsError && <ErrorWidget error={findError} />}

                {findLoading && <Card>
                    <SmallSpinner />
                </Card>}

                {findSuccess && offer.installments.length > 0 && <Card noPadding>
                    <InstallmentsListWidget product={{
                        hasMtplCover: true,
                        hasPassengersAccidentCover: false,
                    }} installments={installments} disabled={issueLoading}
                        readOnly={issueSuccess} onSelectedInstallmentsChange={setSelectedInstallments}
                    />
                </Card>}

                {offerFirstInstallment && !issueSuccess &&
                <Card>
                    <InstallmentPaymentWidget totalAmount={offerFirstInstallment.totalAmount}
                        showBeginDate={true} bsoInsuranceCompanyId={offer.insuranceCompany.id}
                        onSubmit={async values => {
                        const msg = 'Издаване на полица и плащане на вноска на стойност ' +
                            PremiumAmount({
                                amount: offerFirstInstallment.totalAmount,
                                withCurrency: PremiumAmount.defaultProps.withCurrency, // manually calling function
                            }) + ' ?';

                        // TODO change with react-bootstrap modal
                        if (!window.confirm(msg)) {
                            throw new Error('Издаването беше отказано');
                        }

                        let policy;

                        policy = await issue({
                            offer: offer.id,
                            ...values,
                            payFirstInstallment,
                        }).unwrap();

                        setPolicyPrinting(true);
                        await printPolicy(policy.id);
                        setPolicyPrinting(false);

                        const firstInstallment = getFirstInstallment(policy.installments);

                        const firstVoucher = getFirstVoucher(firstInstallment);
                        if (firstVoucher) {
                            setVoucherPrinting(true);
                            await printVoucher(firstVoucher.id);
                            setVoucherPrinting(false);
                        }

                        const firstGreenCard = getFirstGreenCard(firstInstallment);
                        if (firstGreenCard) {
                            setGreenCardPrinting(true);
                            await printGreenCard(firstGreenCard.id);
                            setGreenCardPrinting(false);
                        }
                    }} />
                </Card>}

                {issueIsError && <ErrorWidget error={issueError} />}

                {issueLoading && <Alert variant="secondary" icon={null} heading={<>
                    <span className="me-2">
                        <SlowSpinner />
                    </span>
                    Моля, изчакайте...
                </>}>
                    Издаването на полица може да отнеме от 20 секунди до 2 минути.<br/>
                    Моля, не натискайте други бутони и не затваряйте тази страница, докато не приключи!
                </Alert>}

                {issueSuccess && <>
                    <SuccessfulIssueWidget policy={policy} />
                    <SelectedBso voucher={policyFirstVoucher} />
                    {policyFirstVoucher.paymentMethod === 'BANK_ACCOUNT_BROKER' && <Card
                        header="Данни за банков превод">
                        <BankAccountInfo totalAmount={policyFirstVoucher.totalAmount} reason={policy.number} />
                    </Card>}
                    <Container as={Card}>
                        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-1 row-cols-xl-2 g-3">
                            <Col>
                                <PolicyPrintButton policy={policy} printing={policyPrinting} disabled={printing}
                                                   setDisabled={setPolicyPrinting} />
                            </Col>
                            <Col>
                                {policyFirstVoucher && <VoucherPrintButton voucher={policyFirstVoucher}
                                   printing={voucherPrinting} disabled={printing} setDisabled={setVoucherPrinting} />}
                            </Col>
                            <Col>
                                {policyFirstGreenCard && <GreenCardPrintButton
                                    greenCard={policyFirstGreenCard} printing={greenCardPrinting}
                                    disabled={printing} setDisabled={setGreenCardPrinting} />}
                            </Col>
                            <Col>
                                <VoucherEmailButton onClick={() => send(policyFirstVoucher.id)} disabled={sending}
                                    setDisabled={setSending} />
                            </Col>
                        </Row>
                    </Container>
                    {sendSuccess && <Alert variant="success" heading="Документите бяха изпратени успешно" />}
                    {sendIsError && <ErrorWidget error={sendError} />}
                </>}
            </Col>
            {findSuccess && <Col>
                {issueSuccess ? <PolicyInfoWidget policy={policy} header="Информация за полицата" /> :
                <Card header="Информация за полицата">
                    <MtplOfferInfoWidget />
                </Card>}
            </Col>}
        </Row>
    </Content>);
};

export default MtplIssue;
