import React, {useMemo} from 'react';
import {Field, useFormikContext} from 'formik';
import SelectField from '../ReactSelect/SelectField.jsx';
import {createFilter} from 'react-select';
import {getPeriods} from '../../../utils.js';

const PeriodSelector = () => {
    const periods = useMemo(() =>
        Object.entries(getPeriods()).map(([_, period]) => period),
    []);
    const { isSubmitting } = useFormikContext();
    return (<>
        <Field component={SelectField} name="period" options={periods}
           disabled={isSubmitting} isClearable={true}
           placeholder='Изберете период'
           filterOption={createFilter({
               stringify: option => option.label,
           })} />
    </>);
};

export default PeriodSelector;
