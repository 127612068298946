import {formatUabCardNumber} from '../../../utils';
import PropTypes from 'prop-types';
import React from "react";

const UabCardNumber = ({
    number,
}) => {
    const numberFormatted = formatUabCardNumber(number);

    return (<>
        {numberFormatted.substring(0, 3)}
        <span className="me-1"/>
        {numberFormatted.substring(3, 6)}
    </>);
};

UabCardNumber.propTypes = {
    number: PropTypes.number.isRequired,
};

export default UabCardNumber;
