import React from 'react';
import PropTypes from 'prop-types';
import WrappedCopyButton from './WrappedCopyButton.jsx';
import {Button} from 'react-bootstrap';

const LinkCopyButton = ({
    children,

    href,
    copyText,
    small,
}) => {
    return (<WrappedCopyButton text={copyText} small={small}>
        <Button variant={'outline-secondary' + (small ? ' p-1' : '')} href={href}>{children}</Button>
    </WrappedCopyButton>);
};

LinkCopyButton.defaultProps = {
    small: false,
};

LinkCopyButton.propTypes = {
    href: PropTypes.string.isRequired,
    copyText: PropTypes.string,
    small: PropTypes.bool,
};

export default LinkCopyButton;
