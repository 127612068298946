import React, {useMemo} from 'react';
import {Form, Formik} from 'formik';
import {Col, Container, Row} from 'react-bootstrap';
import AutoPersistToken from '../../../elements/Formik/AutoPersistToken';
import useFormPersistedValues from '../../../../hooks/useFormPersistedValues';
import PropTypes from 'prop-types';
import {formatWeekCode} from '../../../../utils';
import WeekSelector from '../../../elements/Form/WeekSelector';
import SubmitButton from '../../../elements/Form/SubmitButton';
import InsuranceCompanySelector from '../../../elements/Form/InsuranceCompanySelector';
import {subWeeks} from 'date-fns';

const WeekAndInsuranceCompanySelectionWidget = ({
    onSubmit,
}) => {
    const formName = 'insuranceCompanyReport';
    const initialFormValues = useFormPersistedValues(formName);
    const currentWeekFormatted = useMemo(() => formatWeekCode(subWeeks(new Date(), 1)), []);

    return (
        <Formik initialValues={initialFormValues || {
            weekCode: currentWeekFormatted,
            insuranceCompany: '',
        }} onSubmit={onSubmit}>
            <Container as={Form} className="gx-0">
                <Row className="row-cols-1 row-cols-xl-3 g-3 align-items-end">
                    <Col>
                        <WeekSelector required={true} />
                    </Col>
                    <Col>
                        <InsuranceCompanySelector required={true} />
                    </Col>
                    <Col>
                        <SubmitButton>Изготвяне</SubmitButton>
                    </Col>
                </Row>
                <AutoPersistToken formName={formName}/>
            </Container>
        </Formik>
    );
};

WeekAndInsuranceCompanySelectionWidget.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default WeekAndInsuranceCompanySelectionWidget;
