import React from 'react';
import {useGetCurrentUserQuery} from '../../features/apiSlice';
import PropTypes from 'prop-types';
import Card from '../elements/ReactBootstrap/Card';
import RequireRole from '../elements/AccessControl/RequireRole';
import OfficeSelector from '../elements/Form/OfficeSelector';
import PendingPaymentsTable from './PendingPayments/PendingPaymentsTable.jsx';
import {Formik} from 'formik';

const PendingPaymentsWidget = ({
    pollingInterval,
}) => {
    const {
        data: user,
        isSuccess: userSuccess,
    } = useGetCurrentUserQuery();

    return (userSuccess && <Formik initialValues={{
        office: user.office.id,
    }} onSubmit={() => {}}>
        {({values}) => <Card outline noPadding header="Предстоящи плащания">
            <RequireRole name="ROLE_SHOW_BROKER">
                <div className="m-3">
                    <form>
                        <OfficeSelector isClearable defaultValueFilter={values => values.filter(office =>
                            office.value === user.office.id
                        )} />
                    </form>
                </div>
            </RequireRole>
            <PendingPaymentsTable office={values.office} pollingInterval={pollingInterval} />
        </Card>}
    </Formik>);
};

PendingPaymentsWidget.propTypes = {
    pollingInterval: PropTypes.number,
};

export default PendingPaymentsWidget;
