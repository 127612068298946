import React from 'react';
import PropTypes from 'prop-types';
import RequireRole from './RequireRole';

const RequireRoles = ({
    children,

    names,
}) => {
    const name = names.shift();

    return (<RequireRole name={name}>
        {names.length > 0 ? <RequireRoles names={names}>{children}</RequireRoles> : children}
    </RequireRole>);
};

RequireRoles.propTypes = {
    names: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default RequireRoles;
