import React from 'react';
import {Col, Container, FormControl, FormLabel, Row} from 'react-bootstrap';
import {Field, Form, Formik} from 'formik';
import SelectField from '../../elements/ReactSelect/SelectField';
import {createFilter} from 'react-select';
import useFormPersistedValues from '../../../hooks/useFormPersistedValues';
import AutoPersistToken from '../../elements/Formik/AutoPersistToken';
import BsoSaveWidget from './BsoSaveWidget';
import ErrorWidget from '../ErrorWidget';
import Alert from '../../elements/ReactBootstrap/Alert';
import {useCreateUabCardsMutation} from '../../../features/apiSlice';
import AutoPriceSelect from './AutoPriceSelect';
import Step from '../../elements/BsStepper/Step.jsx';

const UabCardCreateWidget = () => {
    const types = [{
        value: 'SILVER',
        label: 'сребърна',
    }, {
        value: 'SILVER_PLUS',
        label: 'сребърна плюс',
    }, {
        value: 'GOLD',
        label: 'златна',
    }];

    const [create, {
        data: uabCards,
        error,
        isError,
    }] = useCreateUabCardsMutation();

    const formName = 'uabCardCreateForm';
    const formInitialValues = useFormPersistedValues(formName);

    return (<Formik
        initialValues={formInitialValues || {
            type: '',
            totalAmount: '',
            startNumber: '',
            endNumber: '',
        }}
        onSubmit={values => {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm('Потвърдете регистрацията на картите СБА')) {
                return Promise.reject(new Error('Регистрацията беше отказана'));
            }

            return create(values);
        }}
    >
        {({isSubmitting}) => (<Container fluid as={Form} className="gx-0">
            <Row className="row-cols-1 g-3">
                <Col>
                    <Step number="2" label="Въведете информация"/>
                </Col>
                <Col lg={6}>
                    <Field component={SelectField} name="type"
                       options={types} required disabled={isSubmitting} isClearable={true}
                       placeholder="Изберете вид карта"
                       filterOption={createFilter({
                           stringify: option => option.label,
                       })}/>
                </Col>
                <div className="w-100 gy-0" />
                <Col lg={6}>
                    <FormLabel htmlFor="totalAmount">Стойност в лв.</FormLabel>
                    <FormControl as={Field} type="number" name="totalAmount"
                                 id="totalAmount" required
                                 disabled={isSubmitting} min={1} max={200} />
                </Col>
                <div className="w-100 gy-0" />
                <Col lg={6}>
                    <FormLabel htmlFor="startNumber">Първа карта</FormLabel>
                    <FormControl as={Field} type="text" name="startNumber" autoComplete="off"
                                 id="startNumber" required pattern="\d{6}"
                                 disabled={isSubmitting} maxLength={6} inputMode="decimal"/>
                </Col>
                <div className="w-100 gy-0" />
                <Col lg={6}>
                    <FormLabel htmlFor="endNumber">Последна карта</FormLabel>
                    <FormControl as={Field} type="text" name="endNumber" autoComplete="off"
                                 id="endNumber" required pattern="\d{6}"
                                 disabled={isSubmitting} maxLength={6} inputMode="decimal"/>
                </Col>
                <BsoSaveWidget type="Карти СБА" />
                <div className="w-100 gy-0" />
                {uabCards && <Col lg={6}>
                    <Alert variant="success" heading={<>Успешно записахте {uabCards.length} карти СБА!</>} />
                </Col>}
                {isError && <Col lg={6}>
                    <ErrorWidget error={error} />
                </Col>}
            </Row>
            <AutoPriceSelect />
            <AutoPersistToken formName={formName}/>
        </Container>)}
    </Formik>);
};

export default UabCardCreateWidget;
