import useCurrentUserHasRole from "./useCurrentUserHasRole.js";
import {paymentMethods} from "../utils.js";

const usePaymentMethods = () => {
    const paymentPosTerminal = useCurrentUserHasRole('ROLE_PAYMENT_POS_TERMINAL');
    const paymentBankAccountBroker = useCurrentUserHasRole('ROLE_PAYMENT_BANK_ACCOUNT_BROKER');
    const paymentBankAccountInsuranceCompany = useCurrentUserHasRole('ROLE_PAYMENT_BANK_ACCOUNT_INSURANCE_COMPANY');

    const getPaymentMethod = paymentMethod => ({
        value: paymentMethod,
        label: paymentMethods[paymentMethod],
    });

    const paymentMethodOptions = [
        getPaymentMethod('CASH'),
        ...(paymentPosTerminal ? [getPaymentMethod('POS_TERMINAL')] : []),
        ...(paymentBankAccountBroker ? [getPaymentMethod('BANK_ACCOUNT_BROKER')] : []),
        ...(paymentBankAccountInsuranceCompany ? [getPaymentMethod('BANK_ACCOUNT_INSURANCE_COMPANY')] : [])];

    const paymentMethodIcons = {
        CASH: {
            icon: 'money-bill-1',
            iconWidth: 43,
        },
        POS_TERMINAL: {
            icon: 'credit-card',
            iconWidth: 43,
        },
        BANK_ACCOUNT_BROKER: {
            icon: 'building-columns',
            iconWidth: 41,
        },
        BANK_ACCOUNT_INSURANCE_COMPANY: {
            icon: 'building-columns',
            iconWidth: 41,
        },
    };

    const hasNonCashPaymentMethods = paymentPosTerminal || paymentBankAccountBroker || paymentBankAccountInsuranceCompany;

    return {
        paymentMethodOptions,
        paymentMethodIcons,
        hasNonCashPaymentMethods,
    };
};

export default usePaymentMethods;
