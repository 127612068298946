import React, {Fragment, useMemo} from 'react';
import {format, parseISO, subDays} from 'date-fns';
import {useListVouchersQuery} from '../../features/apiSlice';
import ErrorWidget from '../widgets/ErrorWidget';
import {getGreenCardUrl, getPolicyUrl, getVoucherUrl} from '../../url';
import PremiumAmount from '../elements/Values/PremiumAmount';
import '../../timeline.scss';
import {Button, Col, Row} from 'react-bootstrap';
import Content from '../partials/Content.jsx';
import Card from '../elements/ReactBootstrap/Card.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';

const Timeline = () => {
    const sevenDaysAgo = useMemo(() => format(subDays(new Date(), 7), 'yyyy-MM-dd'), []);

    const {
        data: vouchers,
        error,
        isLoading,
        isSuccess,
        isError,
    } = useListVouchersQuery({
        startDateTime: sevenDaysAgo,
    });

    let lastDate = '';

    return (<Content>
        <Row>
            <Col md={12}>
                {isLoading && <Card><SmallSpinner /></Card>}
                {isError && <ErrorWidget error={error} />}
                {isSuccess && <div className="timeline">
                    {vouchers.filter(v => !!v.issueUser).reverse().map(voucher => {
                        const dateFormatted = format(parseISO(voucher.issueDate), 'dd.MM.yyyy');
                        const showDate = lastDate !== dateFormatted;
                        lastDate = dateFormatted;
                        const time = format(parseISO(voucher.issueDate), 'HH:mm');
                        const policy = voucher.installments[0].policy;
                        const isPolicy = voucher.installments[0].number === 1;

                        return (<Fragment key={voucher.id}>
                            {showDate && <div className="time-label">
                                <span className="bg-red">{dateFormatted}</span>
                            </div>}
                            <div>
                                <i className={'bg-yellow fas fa-' + ((
                                    policy.product.hasMtplCover || policy.product.hasPassengersAccidentCover || [
                                        'casco',
                                    ].indexOf(policy.product.insuranceType) > -1
                                ) ?
                                    'car-alt' : (
                                        policy.product.insuranceType.indexOf('property-') > -1 ? 'laptop-house' : 'file-alt'
                                    )
                                )} />
                                <div className="timeline-item">
                                    <span className="time">
                                        <i className="fas fa-clock" /> {time}
                                    </span>
                                    <h3 className="timeline-header">
                                        <Button variant="link" className="p-0 align-baseline">{voucher.issueUser.shortName}</Button>
                                        {isPolicy ? ' издаде полица ' :
                                            (' издаде сметка за ' + (voucher.installments.length > 1 ?
                                                    ('вноски ' + voucher.installments.map(i => i.number).join(', ') + ' ') :
                                                    ('вноска ' + voucher.installments[0].number + ' ')
                                                )
                                            )}
                                        на {policy.insuranceCompany.shortName + ' '}
                                        за{' '}
                                        <PremiumAmount amount={isPolicy ? policy.totalAmount : voucher.totalAmount} />
                                    </h3>
                                    <div className="timeline-body">
                                        {isPolicy && <iframe src={getPolicyUrl(policy.id)} title="policy" />}
                                        <iframe src={getVoucherUrl(voucher.id)} title="voucher" />
                                        {voucher.greenCard && <iframe src={getGreenCardUrl(voucher.greenCard.id)}
                                            title="greenCard"/>}
                                    </div>
                                    <div className="timeline-footer">
                                        <Button variant="primary" size="sm" className="me-2">
                                            <i className="fas fa-thumbs-up" /> Харесване
                                        </Button>
                                        <Button variant="danger" size="sm" className="me-2">
                                            <i className="fas fa-comment" /> Коментар
                                        </Button>
                                        <Button variant="success" size="sm">
                                            <i className="fas fa-share" /> Споделяне
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Fragment>);
                    })}
                </div>}
            </Col>
        </Row>
    </Content>);
};
export default Timeline;
