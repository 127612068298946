import React, {useMemo} from 'react';
import {Form, Formik} from 'formik';
import {Col, Container, Row} from 'react-bootstrap';
import AutoPersistToken from '../../elements/Formik/AutoPersistToken';
import PropTypes from 'prop-types';
import WeekSelector from '../../elements/Form/WeekSelector';
import SubmitButton from '../../elements/Form/SubmitButton';
import useFormPersistedValues from '../../../hooks/useFormPersistedValues';
import {formatWeekCode} from '../../../utils';
import OfficeSelector from '../../elements/Form/OfficeSelector';

const WeekAndOfficeSelectionWidget = ({
    onSubmit,
}) => {
    const formName = 'receiveFormFilter';
    const initialFormValues = useFormPersistedValues(formName);
    const currentWeekFormatted = useMemo(() => formatWeekCode(new Date()), []);

    return (
        <Formik initialValues={initialFormValues || {
            weekCode: currentWeekFormatted,
            office: null,
        }} onSubmit={onSubmit}>
            <Container as={Form} className="gx-0">
                <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 align-items-end">
                    <Col>
                        <WeekSelector required={true} />
                    </Col>
                    <Col>
                        <OfficeSelector isClearable={true} />
                    </Col>
                    <Col>
                        <SubmitButton>Покажи</SubmitButton>
                    </Col>
                </Row>
                <AutoPersistToken formName={formName}/>
            </Container>
        </Formik>
    );
};

WeekAndOfficeSelectionWidget.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default WeekAndOfficeSelectionWidget;
