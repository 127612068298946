import {
    useListApplicationsQuery,
    useListClientsQuery,
    useListGreenCardsQuery,
    useListPoliciesQuery,
    useListStickersQuery,
    useListUabCardsQuery,
    useListVehiclesQuery,
    useListVouchersQuery
} from '../features/apiSlice.js';
import {useMemo} from 'react';
import {filterObject} from '../utils.js';

const useSearchResults = query => {
    const queryIsNumeric = query.match(/^\d+$/);

    const {
        data: policiesList,
        error: policiesListError,
        isLoading: policiesListLoading,
        isFetching: policiesListFetching,
        isSuccess: policiesListSuccess,
    } = useListPoliciesQuery({
        query: query,
    });

    const {
        data: stickersList,
        error: stickersListError,
        isLoading: stickersListLoading,
        isFetching: stickersListFetching,
        isSuccess: stickersListSuccess,
    } = useListStickersQuery({
        query: query,
    }, {
        skip: !queryIsNumeric,
    });

    const {
        data: greenCardsList,
        error: greenCardsListError,
        isLoading: greenCardsListLoading,
        isFetching: greenCardsListFetching,
        isSuccess: greenCardsListSuccess,
    } = useListGreenCardsQuery({
        query: query,
    });

    const {
        data: uabCardsList,
        error: uabCardsListError,
        isLoading: uabCardsListLoading,
        isFetching: uabCardsListFetching,
        isSuccess: uabCardsListSuccess,
    } = useListUabCardsQuery({
        query: query,
    }, {
        skip: !queryIsNumeric,
    });

    const {
        data: vouchersList,
        error: vouchersListError,
        isLoading: vouchersListLoading,
        isFetching: vouchersListFetching,
        isSuccess: vouchersListSuccess,
    } = useListVouchersQuery({
        query: query,
    });

    const {
        data: clientsList,
        error: clientsListError,
        isLoading: clientsListLoading,
        isFetching: clientsListFetching,
        isSuccess: clientsListSuccess,
    } = useListClientsQuery({
        query: query,
    });

    const {
        data: vehiclesList,
        error: vehiclesListError,
        isLoading: vehiclesListLoading,
        isFetching: vehiclesListFetching,
        isSuccess: vehiclesListSuccess,
    } = useListVehiclesQuery({
        query: query,
    });

    const {
        data: applicationsList,
        error: applicationsListError,
        isLoading: applicationsListLoading,
        isFetching: applicationsListFetching,
        isSuccess: applicationsListSuccess,
    } = useListApplicationsQuery({
        query: query,
    });

    const results = useMemo(() => ({
        policies: policiesListSuccess ? policiesList : [],
        stickers: stickersListSuccess ? stickersList : [],
        greenCards: greenCardsListSuccess ? greenCardsList : [],
        uabCards: uabCardsListSuccess ? uabCardsList : [],
        vouchers: vouchersListSuccess ? vouchersList : [],
        clients: clientsListSuccess ? clientsList : [],
        vehicles: vehiclesListSuccess ? vehiclesList : [],
        applications: applicationsListSuccess ? applicationsList : [],
    }), [applicationsList, applicationsListSuccess, clientsList, clientsListSuccess, greenCardsList,
        greenCardsListSuccess, policiesList, policiesListSuccess, stickersList, stickersListSuccess, uabCardsList,
        uabCardsListSuccess, vehiclesList, vehiclesListSuccess, vouchersList, vouchersListSuccess]);

    const errors = filterObject({
        policies: policiesListError,
        stickers: stickersListError,
        greenCards: greenCardsListError,
        uabCards: uabCardsListError,
        vouchers: vouchersListError,
        clients: clientsListError,
        vehicles: vehiclesListError,
        applications: applicationsListError,
    }, ([_, error]) => !!error);

    const isLoading = policiesListLoading || stickersListLoading || greenCardsListLoading || uabCardsListLoading ||
        vouchersListLoading || clientsListLoading || vehiclesListLoading || applicationsListLoading;
    const isFetching = policiesListFetching || stickersListFetching || greenCardsListFetching || uabCardsListFetching
        || vouchersListFetching || clientsListFetching || vehiclesListFetching || applicationsListFetching;
    const inProgress = isLoading || isFetching;

    const allResultsCount = useMemo(() =>
        Object.entries(results).reduce((prev, [_, resultsOfType]) =>
            prev + resultsOfType.length, 0),[results]);

    const singleResult = useMemo(() => !inProgress && allResultsCount === 1 ? Object.fromEntries(
        Object.entries(results).filter(([_, resultsOfType]) => resultsOfType.length > 0)
    ) : null, [inProgress, allResultsCount, results]);

    const noResults = useMemo(() => !inProgress && allResultsCount === 0, [inProgress, allResultsCount]);

    return {
        results,
        errors,
        inProgress,
        singleResult,
        noResults,
    };
};

export default useSearchResults;
