import {useGetVersionQuery} from '../../../features/apiSlice';

const RequireNonProdEnv = ({
    children,
}) => {
    const {
        data: version,
        isSuccess,
    } = useGetVersionQuery(undefined, {
        skip: !import.meta.env.VITE_SHOW_UNFINISHED,
    });

    return import.meta.env.VITE_SHOW_UNFINISHED && isSuccess && version.env !== 'prod' ? children : null;
};

export default RequireNonProdEnv;
