import React from 'react';
import InsuranceCompanyBsoInventoryWidget from './InsuranceCompanyBsoInventoryWidget';
import {useGetCurrentUserQuery, useListInsuranceCompaniesQuery} from '../../../features/apiSlice';
import ErrorWidget from '../ErrorWidget';
import {Accordion} from 'react-bootstrap';
import UabCardsInventoryWidget from './UabCardsInventoryWidget';
import RequireRole from '../../elements/AccessControl/RequireRole';
import OfficeSelector from '../../elements/Form/OfficeSelector';
import {Formik} from 'formik';
import useCurrentUserHasRole from '../../../hooks/useCurrentUserHasRole';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';

const BsoInventoryWidget = () => {
    const {
        data: insuranceCompanies,
        error: insuranceCompaniesError,
        isSuccess: insuranceCompaniesIsSuccess,
        isError: insuranceCompaniesIsError,
        isLoading: insuranceCompaniesIsLoading,
    } = useListInsuranceCompaniesQuery();

    const hasShowBrokerRole = useCurrentUserHasRole('ROLE_SHOW_BROKER');

    const {
        data: user,
        isSuccess: userSuccess,
    } = useGetCurrentUserQuery(undefined, {
        skip: !hasShowBrokerRole,
    });

    return (<Formik initialValues={{
        office: null,
    }} onSubmit={() => {}}>
        {({values}) => <>
            {insuranceCompaniesIsLoading && <SmallSpinner />}
            {insuranceCompaniesIsError && <ErrorWidget error={insuranceCompaniesError} />}
            {insuranceCompaniesIsSuccess && <>
                {userSuccess && <RequireRole name="ROLE_SHOW_BROKER">
                    <form>
                        <OfficeSelector required defaultValueFilter={values => values.filter(office =>
                            office.value === user.office.id
                        )} />
                    </form>
                    <div className="w-100 mb-3" />
                </RequireRole>}
                <Accordion>
                    {insuranceCompanies.filter(ic => ic.number).map(insuranceCompany =>
                        <InsuranceCompanyBsoInventoryWidget key={insuranceCompany.id} insuranceCompany={insuranceCompany}
                            office={values.office} />)}
                    <UabCardsInventoryWidget office={values.office} />
                </Accordion>
            </>}
        </>}
    </Formik>);
};

export default BsoInventoryWidget;
