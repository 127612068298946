import {Col, Row} from 'react-bootstrap';
import React from 'react';
import Card from '../../elements/ReactBootstrap/Card.jsx';
import PropTypes from 'prop-types';
import UabCardsListWidget from '../UabCardsListWidget.jsx';

const UabCardSearchResult = ({
    results,
}) => {
    const length = results.length;

    if (length < 1) {
        return null;
    }

    return (<Row>
        <Col>
            <Card noPadding header={'Карти СБА (' + length + ')'}>
                <UabCardsListWidget uabCards={results} />
            </Card>
        </Col>
    </Row>);
};

UabCardSearchResult.propTypes = {
    results: PropTypes.array,
};

export default UabCardSearchResult;
