import React from 'react';
import {ToggleButton, ToggleButtonGroup} from 'react-bootstrap';
import FieldError from '../ReactSelect/FieldError.jsx';
import {ErrorMessage} from 'formik';

const ToggleButtonGroupField = ({
    field,
    form,
    disabled,
    options,
    buttonProps,
    ...props
}) => {
    return (<>
        <ToggleButtonGroup {...props} name={field.name} onChange={value => form.setFieldValue(field.name, value)}>
            {options.map(option => (<ToggleButton key={option.id} id={field.name + '-' + option.id}
                value={option.id} title={option.title} {...buttonProps} disabled={disabled}>
                <i className={option.iconClassName} />
            </ToggleButton>))}
        </ToggleButtonGroup>
        <ErrorMessage name={field.name} component={FieldError} />
    </>);
};

export default ToggleButtonGroupField;
