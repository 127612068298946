(() => {
    const delegate = (tagName, widgetName, handler) => {
        document.addEventListener('click', e => {
            let target = e.target;
            tagName = tagName.toUpperCase();

            while (target.tagName !== tagName) {
                target = target.parentElement;

                if (target === null) {
                    return;
                }
            }

            if (target.getAttribute('data-lte-toggle') !== widgetName) {
                return;
            }

            e.preventDefault();

            handler(e);
        });
    };

    // fullscreen
    delegate('button', 'fullscreen', () => {
        if (document.fullscreenElement) {
            // noinspection JSIgnoredPromiseFromCall
            document.exitFullscreen();
        }
        else {
            // noinspection JSIgnoredPromiseFromCall
            document.documentElement.requestFullscreen();
        }
    });

    // push menu
    const sidebarHandler = () => {
        const bodyClasses = document.body.classList;

        if (bodyClasses.contains('sidebar-close')) {
            bodyClasses.remove('sidebar-close');
            bodyClasses.add('sidebar-open');
        }
        else {
            bodyClasses.remove('sidebar-open');
            bodyClasses.add('sidebar-close');
        }
    };

    delegate('button', 'sidebar-full', sidebarHandler);
    delegate('a', 'sidebar-full', sidebarHandler);
})();
