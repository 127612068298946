import {Accordion, Col, Container, Row} from 'react-bootstrap';
import ErrorIcon from '../../elements/Icons/ErrorIcon';
import {uabCardTypes} from '../../../utils';
import UabCardInfoButton from '../NavigationButtons/UabCardInfoButton';
import React, {Fragment, useMemo} from 'react';
import {useGetUabCardInventoryQuery} from '../../../features/apiSlice';
import PropTypes from 'prop-types';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';

const UabCardsInventoryWidget = ({
    office,
}) => {
    const {
        data: uabCards,
        error,
        isSuccess,
        isError,
        isLoading,
        isFetching,
    } = useGetUabCardInventoryQuery([office]);

    const uabCardsLoading = isLoading || isFetching;
    const uabCardsSuccess = isSuccess && !isFetching;
    const uabCardTypeKeys = useMemo(() => Object.keys(uabCardTypes), []);
    const uabCardsGrouped = useMemo(() => uabCardsSuccess && Object.fromEntries(uabCardTypeKeys.map(type =>
        [type, uabCards.filter(uabCard => uabCard.type === type)]
    )), [uabCardsSuccess, uabCardTypeKeys, uabCards]);

    return (<Accordion.Item eventKey="uab-cards">
        <Accordion.Header>
            <Container fluid className="gx-0">
                <Row>
                    <Col>
                        <strong>Карти СБА</strong>
                    </Col>
                    <Col>
                        {uabCardsLoading && <SmallSpinner />}
                        {isError && <ErrorIcon error={error} />}
                        {uabCardsSuccess && Object.entries(uabCardsGrouped).map(([type, cards], idx) => <Fragment key={type}>
                            {idx > 0 && ' / '}
                            <span key={type}>{uabCardTypes[type].name}: {cards.length}</span>
                        </Fragment>)}
                    </Col>
                </Row>
            </Container>
        </Accordion.Header>
        <Accordion.Body>
            {uabCardsLoading && <SmallSpinner />}
            {uabCardsSuccess && uabCards.length === 0 && <div>Няма налични карти СБА</div>}
            {uabCardsSuccess && uabCards.length > 0 && Object.entries(uabCardsGrouped)
                .map(([type, uabCards], idx) =>
                    <div key={type} className={idx > 0 ? 'mt-3' : ''}>
                        {uabCardTypes[type].name}: {uabCards.map(uabCard =>
                            <span className="me-2 mb-3" key={uabCard.id}>
                                <UabCardInfoButton key={uabCard.id} uabCard={uabCard} />
                            </span>
                        )}
                    </div>)
            }
        </Accordion.Body>
    </Accordion.Item>);
};

UabCardsInventoryWidget.propTypes = {
    office: PropTypes.string,
};

export default UabCardsInventoryWidget;
